class Brokerage {
  id: string;

  date: Date;

  description: string;

  grossBrokerage: number;

  netPremium: number;

  valueIssqn: number;

  insurerId: string;

  createdAt: Date;

  updatedAt: Date;
}

export default Brokerage;
