import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../styles/colors';

const useCirgsReportFormStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    width: '100%',
  },
  subsectionHeader: {
    color: colors.primary,
    marginRight: theme.spacing(5),
  },
  valueCalcOperation: {
    color: colors.primary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export default useCirgsReportFormStyles;
