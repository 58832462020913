import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CirgsReport from '../../../entities/CIRGS/CirgsReport';
import CirgsReportAutoAdjustmentAttributes from '../../../entities/CIRGS/CirgsReportAutoAdjustmentAttributes';
import { ReportTypes } from '../../../enum/CirgsReportEnums';
import useCirgsFormsStyles from '../styles/CirgsFormsStyles';
import CirgsAutoReajustmentReportDetailsSection from './CirgsAutoReajustmentReportDetailsSection';
import CirgsD8ReportDetailsSection from './CirgsD8ReportDetailsSection';
import useCirgsReportDetailsStyles from './CirgsReportDetailsStyles';

interface PropTypes {
  report?: CirgsReport
}

const CirgsReportDetailsSection = (props: PropTypes) => {
  const { report } = props;
  const formsStyles = useCirgsFormsStyles();
  const styles = useCirgsReportDetailsStyles();
  const [t] = useTranslation();

  const renderSpecificReportFields = () => {
    switch (report?.type) {
      case ReportTypes.D8:
        return <CirgsD8ReportDetailsSection report={report} />;
      case ReportTypes.AUTO_READJUSTMENT:
        return (
          <CirgsAutoReajustmentReportDetailsSection
            attributes={report.executionAttributes as CirgsReportAutoAdjustmentAttributes}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {
        report && (
          <section>
            <Typography
              variant="h5"
              component="h4"
              className={formsStyles.sectionTitle}
            >
              {t('cirgs.reports.details.title')}
            </Typography>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="caption"
                >
                  {`${t('cirgs.reports.fields.name')}:`}
                </Typography>
                <Typography
                  variant="body1"
                  className={styles.fieldText}
                >
                  {report?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="caption"
                >
                  {`${t('cirgs.reports.fields.type')}:`}
                </Typography>
                <Typography
                  variant="body1"
                  className={styles.fieldText}
                >
                  {t(`cirgs.reports.report_types.${report?.type.toLocaleLowerCase()}`)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="caption"
                >
                  {`${t('cirgs.reports.fields.status')}:`}
                </Typography>
                <Typography
                  variant="body1"
                  className={styles.fieldText}
                >
                  {t(`cirgs.reports.report_status.${report?.status.toLocaleLowerCase()}`)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="caption"
                >
                  {`${t('cirgs.reports.fields.created_at')}:`}
                </Typography>
                <Typography
                  variant="body1"
                  className={styles.fieldText}
                >
                  {t('datetime', { datetime: report?.createdAt })}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="caption"
                >
                  {`${t('cirgs.reports.fields.completed_at')}:`}
                </Typography>
                <Typography
                  variant="body1"
                  className={styles.fieldText}
                >
                  {t('datetime', { datetime: report?.completedAt })}
                </Typography>
              </Grid>
              { renderSpecificReportFields() }
            </Grid>
          </section>
        )
      }
    </>
  );
};

export default CirgsReportDetailsSection;
