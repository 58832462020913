import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsInsurance from '../../../entities/CIRGS/CirgsInsurance';
import CirgsInsuranceService from '../../../services/cirgs/CirgsInsuranceService';
import useCirgsFormsStyles from '../styles/CirgsFormsStyles';
import CirgsInsuranceTableRow from './CirgsInsuranceTableRow';
import useCirgsInsuranceTableStyles from './CirgsIsuraceTableStyles';

interface PropTypes {
  policyHolderId?: string
  titleLabel: string,
  mainPartnerId?: string,
}

const CirgsInsuranceTable = (props: PropTypes) => {
  const { policyHolderId, titleLabel, mainPartnerId } = props;
  const [t] = useTranslation();
  const [insurances, setInsurances] = useState<CirgsInsurance[]>([]);
  const [selectedMap, setSelectedMap] = useState<Record<string, boolean>>({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [editMap, setEditMap] = useState<Record<string, boolean>>({});
  const [showNewLine, setShowNewLine] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const formsStyles = useCirgsFormsStyles();
  const styles = useCirgsInsuranceTableStyles();

  const getInsurances = useCallback(() => {
    if (policyHolderId) {
      setLoading(true);
      CirgsInsuranceService.getByHolderId(policyHolderId)
        .then((data) => setInsurances(data))
        .finally(() => setLoading(false));
    }
  }, [policyHolderId]);

  useEffect(() => {
    getInsurances();
  }, [getInsurances]);

  const handleSelect = (index: string, checked: boolean) => {
    if (checked) {
      setSelectedCount(selectedCount + 1);
    } else {
      setSelectedCount(selectedCount - 1);
    }
    setSelectedMap({ ...selectedMap, [index]: checked });
  };

  const handleEditClick = () => {
    setEditMap({ ...selectedMap });
    setSelectedCount(0);
    setSelectedMap({});
  };

  const handleAddClick = () => {
    setShowNewLine(true);
  };

  const handleCancel = (id: string, isNew: boolean) => {
    setEditMap({ ...selectedMap, [id]: false });
    setShowNewLine(showNewLine && !isNew);
  };

  const handleRowSave = (insurance: CirgsInsurance, isNew: boolean) => {
    if (!policyHolderId) return;
    setLoading(true);
    let promise: Promise<void>;
    if (isNew) {
      promise = CirgsInsuranceService.create(policyHolderId, insurance);
    } else {
      promise = CirgsInsuranceService.update(policyHolderId, insurance);
    }

    promise
      .then(() => {
        getInsurances();
        handleCancel(insurance.id, isNew);
      })
      .finally(() => setLoading(false));
  };

  const handleCopyFromMainPartner = () => {
    setLoading(true);
    if (mainPartnerId && policyHolderId) {
      CirgsInsuranceService.copyFromCustomerToPartner(mainPartnerId, policyHolderId)
        .then(() => getInsurances())
        .finally(() => setLoading(true));
    }
  };

  const handleDelete = () => {
    if (!policyHolderId || selectedCount <= 0) return;
    setLoading(true);
    const toDelete = Object.entries(selectedMap).filter(([, selected]) => selected).map(([id]) => id);
    Promise.all(toDelete.map((insuranceId) => (
      CirgsInsuranceService.deleteInsurance(policyHolderId, insuranceId)
    )))
      .then(() => getInsurances())
      .finally(() => setLoading(false));
  };

  const headCells = [
    { id: 'policyName', numeric: false, label: 'cirgs.customers.details.insurance.policy_type' },
    { id: 'amount', numeric: false, label: 'cirgs.customers.details.insurance.amount' },
    { id: 'payAmount', numeric: false, label: 'cirgs.customers.details.insurance.pay_amount' },
    { id: 'dateJoined', numeric: false, label: 'cirgs.customers.details.insurance.date_joined' },
    { id: 'validityStartDate', numeric: false, label: 'cirgs.customers.details.insurance.validity_start_date' },
  ];

  const renderLoadingTable = () => (
    <TableRow>
      { [...Array(6)].map((i) => (
        <TableCell align="center" key={i}>
          <Skeleton animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  );

  const renderEmptyTableMessage = () => (
    <TableRow>
      <TableCell colSpan={6} align="center">
        {t('cirgs.customers.details.insurance.table_no_data')}
      </TableCell>
    </TableRow>
  );

  const renderTableRows = () => {
    const rows = insurances.map((insurance) => (
      <CirgsInsuranceTableRow
        key={insurance.id}
        insurance={insurance}
        onSelect={handleSelect}
        onSubmit={handleRowSave}
        onCancel={handleCancel}
        selected={selectedMap[insurance.id]}
        editMode={editMap[insurance.id]}
      />
    ));

    if (showNewLine) {
      rows.push(
        <CirgsInsuranceTableRow
          insurance={CirgsInsurance.getDefault()}
          onSelect={handleSelect}
          onSubmit={handleRowSave}
          onCancel={handleCancel}
          editMode
          isNew
        />,
      );
    }
    return rows;
  };

  const renderTableBody = () => {
    if (isLoading) {
      return renderLoadingTable();
    } if (insurances.length === 0 && !showNewLine) {
      return renderEmptyTableMessage();
    }

    return renderTableRows();
  };

  return (
    <div className={formsStyles.formSection}>
      <Grid container direction="row" justifyContent="space-between" className={styles.tableTitleDiv}>
        <Typography
          variant="h5"
          component="h2"
          className={formsStyles.sectionTitle}
        >
          {t(titleLabel)}
        </Typography>
      </Grid>

      <Paper>
        <Toolbar>
          <div className={styles.toolbarGrow} />
          { mainPartnerId && (
            <Tooltip title={t('cirgs.customers.details.insurance.copy')!}>
              <IconButton
                aria-label="sync"
                color="secondary"
                onClick={handleCopyFromMainPartner}
              >
                <FontAwesomeIcon icon={faCopy} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={t('cirgs.customers.details.insurance.new_policy')!}>
            <IconButton
              aria-label="new"
              className={styles.toolbarIcon}
              onClick={handleAddClick}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>

          {
            selectedCount !== 0 && (
              <>
                <Tooltip title={t('cirgs.customers.details.insurance.edit')!}>
                  <IconButton
                    aria-label="edit"
                    className={styles.toolbarIcon}
                    onClick={handleEditClick}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('cirgs.customers.details.insurance.delete')!}>
                  <IconButton
                    aria-label="delete"
                    className={styles.toolbarIconRed}
                    onClick={handleDelete}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
        </Toolbar>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {headCells.map((cell) => (
                  <TableCell
                    key={cell.id}
                    align={cell.numeric ? 'right' : 'left'}
                    padding="normal"
                    component="th"
                  >
                    <strong>{t(cell.label)}</strong>
                  </TableCell>
                ))}
                <TableCell padding="checkbox" />
              </TableRow>
            </TableHead>
            <TableBody>
              { renderTableBody() }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CirgsInsuranceTable;
