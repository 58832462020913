import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import InsurerForm from '../../../components/Insurers/InsurerForm/InsurerForm';
import Insurer from '../../../entities/Insurers/Insurer';
import InsurerService from '../../../services/insurers/InsurerService';
import { useNavigate } from 'react-router-dom';

const NewInsurer = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showValidationError, setValidationError] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const navigate = useNavigate();

  const getErrorMessageLabel = () => {
    if (showSubmitError) {
      return 'insurers.errors.submit';
    }
    return 'insurers.errors.validation';
  };

  const handleSubmit = (insurer: Insurer | null) => {
    setLoading(true);
    if (insurer) {
      InsurerService.create(insurer)
        .then((newInsurer) => {
          navigate(`/insurers/${newInsurer.id}`);
        })
        .catch(() => {
          setShowSubmitError(true);
        })
        .finally(() => setLoading(false));
    } else {
      setValidationError(true);
      setLoading(false);
    }
  };

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('insurers.title'), t('insurers.breadcrumb.new')]} />

      { (showValidationError || showSubmitError) && (
        <Alert variant="filled" severity="error">
          {t(getErrorMessageLabel())}
        </Alert>
      )}

      <InsurerForm
        titleLabel="insurers.form.title_new"
        onSubmit={handleSubmit}
      />
    </PageContainer>
  );
};

export default NewInsurer;
