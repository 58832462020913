 
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageBreadcrumb from '../../components/PageBreadcrumb';
import PageContainer from '../../components/PageContainer';
import useUsersStyles from './UsersStyles';
import UserDetailsForm from '../../components/Users/UserDetailsForm';
import User from '../../entities/User/User';
import UsersService from '../../services/user/UsersService';
import UserPermissionsForm from '../../components/Users/UserPermissionsForm';

type ParamTypes = {
  userId: string
}

const UserDetails = () => {
  const { userId } = useParams<ParamTypes>();
  const [t] = useTranslation();
  const styles = useUsersStyles();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [, setSubmitFailed] = useState(false);
  const navigate = useNavigate();

  const loadUser = () => {
    if (!userId) return;
    setLoading(true);
    UsersService.getById(userId)
      .then((userResponse: User) => {
        setUser(userResponse);
      })
      .catch(() => navigate('/404'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadUser();
  }, [userId]);

  const onSave = (userData: User | null) => {
    if (!userData) return;
    setLoading(true);
    UsersService.create(userData)
      .then((userResponse: User) => {
        if (userResponse.id) {
          navigate(`/users/${userResponse.id}`);
        }
        setSubmitFailed(false);
      })
      .catch(() => setSubmitFailed(true))
      .finally(() => setLoading(false));
  };

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('users.title')]} />
      <div id="users-new" className={styles.usersContainer}>
        <UserDetailsForm userData={user} onSubmit={onSave} />

        <UserPermissionsForm userId={userId} />
      </div>
    </PageContainer>
  );
};

export default UserDetails;
