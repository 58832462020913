import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs } from '@material-ui/core';
import { faCogs, faCoins, faTable } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const BrokerageTabs = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const routeMatchTab = (tab: RegExp): boolean => tab.test(location.pathname);

  return (
    <Tabs
      value={location.pathname}
      onChange={(_, newValue) => {
        navigate(newValue);
      }}
      indicatorColor="secondary"
      textColor="primary"
      aria-label="scrollable auto tabs example"
    >
      <Tab
        icon={<FontAwesomeIcon icon={faCoins} />}
        label={t('brokerages.tabs.brokerages')}
        value="/brokerages"
        selected={routeMatchTab(/$\/brokerages/)}
      />

      <Tab
        icon={<FontAwesomeIcon icon={faTable} />}
        label={t('brokerages.tabs.reports')}
        value="/brokerages/reports"
        selected={routeMatchTab(/$\/cirgs\/customers\/.*/)}
      />

      <Tab
        icon={<FontAwesomeIcon icon={faCogs} />}
        label={t('brokerages.tabs.settings')}
        value="/brokerages/settings"
        selected={routeMatchTab(/$\/brokerages\/settings.*/)}
      />
    </Tabs>
  );
};

export default BrokerageTabs;
