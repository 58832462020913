import { instanceToPlain, plainToInstance } from 'class-transformer';
import InsurersApiRequestService from '../ApiRequestService/InsurersApiRequestService';
import BrokerageSettings from '../../entities/Brokerage/BrokerageSettings';

const getAll = async (): Promise<BrokerageSettings> => {
  const url = '/settings';

  const response = await InsurersApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(plainToInstance(BrokerageSettings, responseBody));
};

const getOne = async (key: string): Promise<BrokerageSettings> => {
  const url = `/settings/${key}`;

  const response = await InsurersApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(plainToInstance(BrokerageSettings, responseBody));
};

const upsert = async (settings: BrokerageSettings): Promise<void> => {
  const url = '/settings';

  const response = await InsurersApiRequestService.request('POST', url, instanceToPlain(settings));
  await response.json();
  return Promise.resolve();
};

export default { getAll, getOne, upsert };
