import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import CirgsReportCustomersList from '../../../components/Cirgs/CirgsReportCustomersList';
import CirgsReportDetailsSection from '../../../components/Cirgs/CirgsReportDetailsSection';
import CirgsFileCard from '../../../components/Cirgs/CirgsFileCard';
import CirgsReportIcon from '../../../components/Cirgs/CirgsReportIcon';
import CirgsReport from '../../../entities/CIRGS/CirgsReport';
import CirgsReportService from '../../../services/cirgs/CirgsReportService';
import useCirgsReportDetailsStyles from './CirgsReportDetailsStyles';
import { ReportTypes } from '../../../enum/CirgsReportEnums';

interface PropTypes {
  setLoading: (value: boolean) => void,
}

type ParamTypes = {
  reportId: string
}

const CirgsReportDetails = (props: PropTypes) => {
  const { setLoading } = props;
  const [t] = useTranslation();
  const { reportId } = useParams<ParamTypes>();
  const styles = useCirgsReportDetailsStyles();
  const [report, setReport] = useState<CirgsReport|undefined>(undefined);

  const loadReport = useCallback(() => {
    if (reportId) {
      setLoading(true);
      CirgsReportService.getById(reportId)
        .then((response: CirgsReport) => {
          setReport(response);
        })
        .finally(() => setLoading(false));
    }
  }, [reportId, setLoading]);

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  const renderReportSpecificDetails = () => {
    switch (report?.type) {
      case ReportTypes.D8:
        return <CirgsReportCustomersList reportId={report.id!} />;
      default:
        return <></>;
    }
  };

  const renderTitle = () => {
    if (report) {
      return (
        <div className={styles.titleDiv}>
          <CirgsReportIcon status={report?.status} />
          <Typography
            variant="h5"
            component="h3"
            className={styles.reportTitleText}
          >
            {report?.name}
          </Typography>
        </div>
      );
    }
    return (
      <>
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="text" width="80%" />
      </>
    );
  };

  return (
    <div id="cirgs-report-details" className={styles.cirgsReportDetailsContainer}>
      <section className={styles.reportTitle}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          { renderTitle() }
          <IconButton
            color="secondary"
            onClick={loadReport}
          >
            <FontAwesomeIcon icon={faSync} />
          </IconButton>
        </Grid>
      </section>

      <CirgsReportDetailsSection report={report} />

      <section className={clsx({ [styles.hideSection]: !report?.files?.length })}>
        <Typography
          variant="h5"
          component="h4"
          className={styles.reportTitleText}
        >
          {t('cirgs.reports.details.files')}
        </Typography>
        <Grid container>
          {
          (report?.files || []).map((file) => (
            <Grid xs={12} sm={6} md={4} key={file.id}>
              <CirgsFileCard reportFile={file} downloadEnabled />
            </Grid>
          ))
        }
        </Grid>
      </section>

      { renderReportSpecificDetails() }
    </div>
  );
};

export default CirgsReportDetails;
