import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@material-ui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import BrokerageForm from '../../../components/Brokerages/BrokerageForm/BrokerageForm';
import Brokerage from '../../../entities/Brokerage/Brokerage';
import BrokerageService from '../../../services/brokerage/BrokerageService';

type ParamTypes = {
  id: string;
}

const BrokerageDetails = () => {
  const [t] = useTranslation();
  const { id } = useParams<ParamTypes>();
  const [loading, setLoading] = useState(false);
  const [showValidationError, setValidationError] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [currentBrokerage, setBrokerage] = useState<Brokerage>();
  const navigate = useNavigate();

  const loadBrokerage = useCallback(() => {
    setLoading(true);
    if (id) {
      BrokerageService.getById(id)
        .then((response: Brokerage) => {
          setBrokerage(response);
        })
        .finally(() => setLoading(false));
    }
  }, [id, setLoading]);

  useEffect(() => {
    loadBrokerage();
  }, [loadBrokerage]);

  const getErrorMessageLabel = () => {
    if (showSubmitError) {
      return 'brokerages.errors.submit';
    }
    return 'brokerages.errors.validation';
  };

  const handleSubmit = (brokerage: Brokerage | null) => {
    setLoading(true);
    if (brokerage) {
      BrokerageService.update(brokerage.id, brokerage)
        .then((updated) => {
          navigate(`/brokerages/${updated.id}`);
        })
        .catch(() => {
          setShowSubmitError(true);
        })
        .finally(() => setLoading(false));
    } else {
      setValidationError(true);
      setLoading(false);
    }
  };

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[
        t('brokerages.title'),
        t('brokerages.breadcrumb.details'),
      ]}
      />

      { (showValidationError || showSubmitError) && (
        <Alert variant="filled" severity="error">
          {t(getErrorMessageLabel())}
        </Alert>
      )}

      <BrokerageForm setLoading={setLoading} onSubmit={handleSubmit} brokerageData={currentBrokerage} />
    </PageContainer>
  );
};

export default BrokerageDetails;
