import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from '@material-ui/lab';
import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import BrokerageForm from '../../../components/Brokerages/BrokerageForm/BrokerageForm';
import Brokerage from '../../../entities/Brokerage/Brokerage';
import BrokerageService from '../../../services/brokerage/BrokerageService';
import { useNavigate } from 'react-router-dom';

const BrokeragesHome = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showValidationError, setValidationError] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const navigate = useNavigate();

  const getErrorMessageLabel = () => {
    if (showSubmitError) {
      return 'brokerages.errors.submit';
    }
    return 'brokerages.errors.validation';
  };

  const handleSubmit = (brokerage: Brokerage | null) => {
    setLoading(true);
    if (brokerage) {
      BrokerageService.create(brokerage)
        .then(() => {
          navigate('/brokerages');
        })
        .catch(() => {
          setShowSubmitError(true);
        })
        .finally(() => setLoading(false));
    } else {
      setValidationError(true);
      setLoading(false);
    }
  };

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('brokerages.title'), t('brokerages.breadcrumb.new')]} />

      { (showValidationError || showSubmitError) && (
        <Alert variant="filled" severity="error">
          {t(getErrorMessageLabel())}
        </Alert>
      )}

      <BrokerageForm setLoading={setLoading} onSubmit={handleSubmit} />
    </PageContainer>
  );
};

export default BrokeragesHome;
