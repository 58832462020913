import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsReportFileCardStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  paperDivLine: {
    '& > *': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  fileIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.light,
  },
  dateIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[700],
  },
  dateText: {
    color: theme.palette.grey[700],
  },
}));

export default useCirgsReportFileCardStyles;
