import React, { useState, useCallback, useEffect } from 'react';
import { Button, Grid, IconButton, Paper, ThemeProvider, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSync, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import useCirgsFormsStyles from '../styles/CirgsFormsStyles';
import CirgsCustomer from '../../../entities/CIRGS/CirgsCusrtomer';
import CirgsCustomerService from '../../../services/cirgs/CirgsCustomerService';
import CirgsCustomerAttachment from '../../../entities/CIRGS/CirgsCustomerAttachment';
import CirgsFileCard from '../CirgsFileCard';
import FileUploader from '../../FileUploader/FileUploader';
import useCirgsCustomerAttachmentsSectionStyles from './CirgsCustomerAttachmentsSectionStyles';
import ThemeService from '../../../services/theme/ThemeService';

interface PropTypes {
  customer?: CirgsCustomer,
  setLoading: (loading: boolean) => void,
}

const CirgsCustomerAttachmentsSection = (props: PropTypes) => {
  const { customer, setLoading } = props;
  const [attachments, setAttachments] = useState<CirgsCustomerAttachment[]>([]);
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<File[]|undefined>(undefined);
  const [t] = useTranslation();
  const formsStyles = useCirgsFormsStyles();
  const styles = useCirgsCustomerAttachmentsSectionStyles();

  const loadAttachments = useCallback(() => {
    if (customer) {
      setLoading(true);
      CirgsCustomerService.getAttachments(customer.id!)
        .then((response: CirgsCustomerAttachment[]) => {
          setAttachments(response);
        })
        .finally(() => setLoading(false));
    }
  }, [customer, setLoading]);

  useEffect(() => {
    loadAttachments();
  }, [loadAttachments]);

  const onFileDrop = (acceptedFiles: File[]) => {
    setUploadFiles([...(uploadFiles || []), ...acceptedFiles]);
  };

  const onCardAction = async (callback: () => Promise<void>) => {
    setLoading(true);
    callback()
      .then(loadAttachments)
      .finally(() => setLoading(false));
  };

  const submitUpload = () => {
    if (customer?.id && uploadFiles) {
      setLoading(true);
      setShowAdd(false);
      CirgsCustomerService.addAttachments(customer?.id, uploadFiles)
        .then(async () => loadAttachments())
        .finally(() => setLoading(false));
    }
  };

  const renderShowAdd = () => {
    if (!showAdd) return null;

    return (
      <Paper className={styles.uploadPaper}>
        <FileUploader noClick maxFiles={5} onDrop={onFileDrop} />
        <Grid container direction="row" justifyContent="flex-end">
          <ThemeProvider theme={ThemeService.errorDangerTheme()}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => setShowAdd(false)}
              className={styles.cancelUploadButton}
            >
              {t('cirgs.customers.details.attachments.cancel')}
            </Button>
          </ThemeProvider>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<FontAwesomeIcon icon={faUpload} />}
            onClick={submitUpload}
          >
            {t('cirgs.customers.details.attachments.upload')}
          </Button>
        </Grid>
      </Paper>
    );
  };

  return (
    <section className={formsStyles.formSection} id="customer-form-section">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h5"
          component="h2"
          className={formsStyles.sectionTitle}
        >
          {t('cirgs.customers.details.attachments.title')}
        </Typography>

        <Grid>
          <Tooltip title={t('cirgs.customers.details.attachments.refresh')!}>
            <IconButton
              aria-label="sync"
              color="secondary"
              onClick={loadAttachments}
            >
              <FontAwesomeIcon icon={faSync} />
            </IconButton>
          </Tooltip>

          <Button
            variant="outlined"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setShowAdd(!showAdd)}
          >
            {t('cirgs.customers.details.attachments.add')}
          </Button>
        </Grid>
      </Grid>
      { renderShowAdd() }
      <Grid container>
        {
          (attachments || []).map((file) => (
            <Grid xs={12} sm={6} md={4} key={file.id}>
              <CirgsFileCard reportFile={file} downloadEnabled deleteEnabled onCardAction={onCardAction} />
            </Grid>
          ))
        }
      </Grid>
    </section>
  );
};

export default CirgsCustomerAttachmentsSection;
