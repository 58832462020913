 
import { Grid, MenuItem, Paper, TableFooter, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import NumberFormat from 'react-number-format';
import BrokerageService from '../../../services/brokerage/BrokerageService';
import useFormsStyles from '../../../styles/forms/FormStyles';
import useTableStyles from '../../../styles/tables/TableStyles';
import BrokerageReport from '../../../entities/Brokerage/BrokerageReport';
import BrokerageReportTableRow from './BrokerageReportTableRow';

interface PropTypes {
  titleLabel: string;
  setLoading: (value: boolean) => void,
  isLoading: boolean,
}

const BrokeragesReportTable = (props: PropTypes) => {
  const { titleLabel, setLoading, isLoading } = props;
  const [t] = useTranslation();
  const [reportData, setReportData] = useState<BrokerageReport>();
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [month, setMonth] = useState<number>(undefined);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const formsStyles = useFormsStyles();
  const tableStyles = useTableStyles();

  const loadReportData = useCallback(() => {
    setLoading(true);
    BrokerageService.report(
      year,
      month,
    )
      .then((result) => {
        setReportData(result);
      })
      .finally(() => setLoading(false));
  }, [month, year]);

  const loadAvailableYears = useCallback(() => {
    setLoading(true);
    BrokerageService.availableYears()
      .then((data) => setAvailableYears(data))
      .finally(() => setLoading(false));
  }, [isLoading]);

  useEffect(() => {
    loadReportData();
    loadAvailableYears();
  }, []);

  useEffect(() => {
    loadReportData();
  }, [month, year]);

  const insurerHeadCell = [
    { name: 'insurer', numeric: false, label: t('brokerages.report.insurer') },
    { name: 'cnpj', numeric: false, label: t('brokerages.report.cnpj') },
  ];

  const monthlyHeadCells = [
    { name: 'netPremium', numeric: false, label: t('brokerages.report.monthly.net_premium') },
    { name: 'grossBrokerage', numeric: false, label: t('brokerages.report.monthly.gross_brokerage') },
    { name: 'issqn', numeric: false, label: t('brokerages.report.monthly.issqn') },
    { name: 'netBrokerage', numeric: false, label: t('brokerages.report.monthly.net_brokerage') },
  ];

  const yearlyHeadCells = [
    { name: 'netPremium', numeric: false, label: t('brokerages.report.yearly.net_premium') },
    { name: 'grossBrokerage', numeric: false, label: t('brokerages.report.yearly.gross_brokerage') },
    { name: 'issqn', numeric: false, label: t('brokerages.report.yearly.issqn') },
    { name: 'netBrokerage', numeric: false, label: t('brokerages.report.yearly.net_brokerage') },
  ];

  const headerCells = () => {
    if (month) {
      return [...insurerHeadCell, ...monthlyHeadCells];
    }
    return [...insurerHeadCell, ...yearlyHeadCells];
  };

  // const renderLoadingTable = () => (
  //   <TableRow>
  //     { [...Array(6)].map(() => (
  //       <TableCell align="center">
  //         <Skeleton animation="wave" />
  //       </TableCell>
  //     ))}
  //   </TableRow>
  // );

  // const renderEmptyTableMessage = () => (
  //   <TableRow>
  //     <TableCell colSpan={7} align="center">
  //       {t('brokerages.table.no_data')}
  //     </TableCell>
  //   </TableRow>
  // );

  // const renderTableRows = () => reportData.values.map((brokerage) => (
  //   <BrokerageTableRow
  //     line={brokerage}
  //   />
  // ));

  const renderTableFooter = () => {
    if (isLoading || !reportData?.totals) {
      return <></>;
    }

    return (
      <TableFooter>
        <BrokerageReportTableRow
          line={reportData.totals}
        />
      </TableFooter>
    );
  };

  // const renderTableBody = () => {
  //   if (isLoading) {
  //     return renderLoadingTable();
  //   } if ((reportData?.values ?? []).length === 0) {
  //     return renderEmptyTableMessage();
  //   }

  //   return renderTableRows();
  // };

  const renderDataTableRows = () => {
    if (!reportData) {
      return [];
    }
    let data = [];
    data = (reportData?.values ?? []).map((line) => ([
      line.insurerName ?? '',
      line.insurerCnpj
        ? (
          <NumberFormat
            value={line.insurerCnpj}
            isNumericString
            format="##.###.###/####-##"
            displayType="text"
          />
        )
        : '',
      t('currency', { amount: Number(line.netPremium) / 100 }),
      t('currency', { amount: Number(line.grossBrokerage) / 100 }),
      t('currency', { amount: Number(line.valueIssqn) / 100 }),
      t('currency', { amount: Number(line.netBrokerage) / 100 }),
    ]
    ));

    // data.push([
    //   '',
    //   '',
    //   t('currency', { amount: Number(reportData.totals.netPremium) / 100 }),
    //   t('currency', { amount: Number(reportData.totals.grossBrokerage) / 100 }),
    //   t('currency', { amount: Number(reportData.totals.valueIssqn) / 100 }),
    //   t('currency', { amount: Number(reportData.totals.netBrokerage) / 100 }),
    // ]);
    return data;
  };

  const toolbar = () => (
    <Grid container justifyContent="space-evenly">
      <Grid xs={4}>
        <TextField
          fullWidth
          select
          label={t('brokerages.form.filters.month')}
          value={month}
          onChange={(event) => {
            const selected = event.target.value;
            setMonth(selected ? Number(selected) : undefined);
          }}
        >
          { [NaN, ...Array.from({ length: 12 }, (_, i) => i + 1)].map((value) => (
            <MenuItem key={value} value={value}>
              {`${!Number.isNaN(value) ? value : t('months.all')} - ${t(`months.${value}`)}`}
            </MenuItem>
          )) }
        </TextField>
      </Grid>

      <Grid xs={3}>
        <TextField
          fullWidth
          select
          label={t('brokerages.form.filters.year')}
          value={year}
          onChange={(event) => {
            const selected = event.target.value;
            setYear(selected ? Number(selected) : undefined);
          }}
        >
          { [...availableYears].map((value) => (
            <MenuItem key={value} value={value}>
              {value ?? t('months.all')}
            </MenuItem>
          )) }
        </TextField>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" className={tableStyles.tableTitleDiv}>
        <Typography
          variant="h5"
          component="h2"
          className={formsStyles.sectionTitle}
        >
          {t(titleLabel)}
        </Typography>
      </Grid>

      <Paper>
        <MUIDataTable
          title={t(titleLabel)}
          data={renderDataTableRows()}
          columns={headerCells()}
          options={{
            pagination: false,
            selectableRows: 'none',
            viewColumns: false,
            filter: false,
            search: false,
            draggable: false,
            selectToolbarPlacement: 'above',
            serverSide: true,
            customToolbar: toolbar,
            customTableBodyFooterRender: renderTableFooter,
          }}
        />
      </Paper>
    </div>
  );
};

export default BrokeragesReportTable;
