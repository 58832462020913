class UserInfo {
  public sub: string;

  public name: string;

  public lastName: string;

  public email:string;

  constructor(sub: string, name: string, lastName: string, email: string) {
    this.sub = sub;
    this.name = name;
    this.lastName = lastName;
    this.email = email;
  }
}

export default UserInfo;
