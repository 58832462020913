import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsCustomerForm from '../../../components/Cirgs/CirgsCustomerForm';
import CirgsCustomer from '../../../entities/CIRGS/CirgsCusrtomer';
import CirgsCustomerService from '../../../services/cirgs/CirgsCustomerService';
import useCirgsNewCustomerStyles from './CirgsNewCustomerStyles';
import { useNavigate } from 'react-router-dom';

interface CustomerListProps {
  setLoading: (value: boolean) => void,
}

const CirgsNewCustomer = (props: CustomerListProps) => {
  const { setLoading } = props;
  const [t] = useTranslation();
  const styles = useCirgsNewCustomerStyles();
  const [showValidationError, setValidationError] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const navigate = useNavigate();

  const getErrorMessageLabel = () => {
    if (showSubmitError) {
      return 'cirgs.customers.details.errors.submit';
    }
    return 'cirgs.customers.details.errors.validation';
  };

  const handleSubmit = (customer: CirgsCustomer | null) => {
    setLoading(true);
    if (customer) {
      CirgsCustomerService.create(customer)
        .then((newCustomer) => {
          navigate(`/cirgs/customers/${newCustomer.id}`);
        })
        .catch(() => {
          setShowSubmitError(true);
        })
        .finally(() => setLoading(false));
    } else {
      setValidationError(true);
      setLoading(false);
    }
  };

  return (
    <div id="cirgs-new-customer" className={styles.cirgsNewCustomerContainer}>

      { (showValidationError || showSubmitError) && (
      <Alert variant="filled" severity="error">
        {t(getErrorMessageLabel())}
      </Alert>
      )}

      <CirgsCustomerForm
        titleLabel="cirgs.customers.details.customer.title"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CirgsNewCustomer;
