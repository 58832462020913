import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const useUsersStyles = makeStyles((theme: Theme) => createStyles({
  usersContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      width: '80%',
    },
  },
  usersSearch: {
    marginBottom: theme.spacing(4),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
  usersList: {
    width: '100%',
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    minHeight: '112px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  actionButtonsContainer: {
    marginTop: '1.2rem',
  },
  usersListPaper: {
    position: 'relative',
  },
  usersContentGrid: {
    width: '100%',
    '& > *': {
      width: '100%',
      marginBottom: '2vh',
    },
  },
  passwordRulesList: {
    listStyleType: 'none',
    padding: 0,
    '& > li': {
      paddingBottom: '5px',
      '& > span': {
        color: colors.white,
        marginLeft: '5px',
      },
    },
  },
  passwordRuleOk: {
    color: colors.success,
  },
  passwordRuleFail: {
    color: colors.red,
  },
  fieldsContainer: {
    '& > *': {
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
  },
  [theme.breakpoints.down('xs')]: {
    usersListContainer: {
      '& > *': {
        width: '100%',
      },
    },
    usersSearch: {
      marginBottom: theme.spacing(2),
    },
  },
  [theme.breakpoints.up('lg')]: {
    usersListContainer: {
      '& > *': {
        width: '70%',
      },
    },
  },
  [theme.breakpoints.down('xs')]: {
    fieldsContainer: {
      '& > *': {
        paddingRight: 0,
      },
    },
  },
}));

export default useUsersStyles;
