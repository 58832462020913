import { faFileInvoiceDollar, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from '../../components/PageBreadcrumb';
import PageContainer from '../../components/PageContainer';
import useCirgsHomeStyles from './CirgsHomeStyles';
import CirgsCustomersList from './CustomersList';
import CirgsNewCustomer from './CirgsNewCustomer';
import CirgsCustomerDetails from './CirgsCustomerDetails';
import CirgsReportsList from './CirgsReportsList';
import CirgsNewReport from './CirgsNewReport';
import CirgsReportDetails from './CirgsReportDetails';

const CirgsHome = () => {
  const [t] = useTranslation();
  const styles = useCirgsHomeStyles();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const routeMatchTab = (tab: RegExp): boolean => tab.test(location.pathname);

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('cirgs.title')]} />
      <Tabs
        value={location.pathname}
        onChange={(_, newValue) => {
          navigate(`/cirgs/${newValue}`);
        }}
        indicatorColor="secondary"
        textColor="primary"
        aria-label="scrollable auto tabs example"
        className={styles.tabs}
      >
        <Tab
          icon={<FontAwesomeIcon icon={faUsers} />}
          label={t('cirgs.tabs.customers')}
          value={`customers`}
          selected={routeMatchTab(/$\/cirgs\/customers\/.*/)}
        />

        <Tab
          icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
          label={t('cirgs.tabs.reports')}
          value={`reports`}
          selected={routeMatchTab(/$\/cirgs\/reports\/.*/)}
        />
      </Tabs>
      <Routes>
          <Route path='reports'>
            <Route path='new' element={<CirgsNewReport setLoading={setLoading} />} />
            <Route path=':reportId' element={<CirgsReportDetails setLoading={setLoading} />} />
            <Route index element={<CirgsReportsList setLoading={setLoading} />} />
          </Route>

          <Route path='customers'>
            <Route path='new' element={<CirgsNewCustomer setLoading={setLoading}/> } />
            <Route path=':customerId' element={<CirgsCustomerDetails setLoading={setLoading} />} />
            <Route index element={<CirgsCustomersList setLoading={setLoading} />} />
          </Route>

          <Route path='' element={<CirgsCustomersList setLoading={setLoading} />} />
      </Routes>
    </PageContainer>
  );
};

export default CirgsHome;
