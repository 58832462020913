import CreditCardDetailsInfo from '../../entities/CreditCards/CreditCardDetailsInfo';
import CreditCardPublicInfo from '../../entities/CreditCards/CreditCardPublicInfo';
import ApiRequestService from '../ApiRequestService/AdminApiRequestService';

const convertJsonToCCPublicInfo = (object: any) => new CreditCardPublicInfo(
  object.card_first_two,
  object.card_last_four,
  new Date(object.card_added_date),
);

const convertJsonToCCDetails = (object: any) => new CreditCardDetailsInfo(
  object.card_number,
  object.card_first_two,
  object.card_last_four,
  object.card_holder,
  object.card_expiration_month,
  object.card_expiration_year,
  object.card_billing_day,
  new Date(object.card_added_date),
);

const getCreditCardInfo = async (customerId: string, details: boolean)
: Promise<CreditCardPublicInfo | CreditCardDetailsInfo> => {
  const url = `/customers/${customerId}/credit_cards?details=${details}`;
  const response = await ApiRequestService.request('GET', url);
  if (response.ok) {
    const responseBody = await response.json() as any;
    if (details) {
      return convertJsonToCCDetails(responseBody);
    }
    return convertJsonToCCPublicInfo(responseBody);
  }
  return Promise.reject();
};

export default { getCreditCardInfo };
