import { Company } from '../../enum/Company';

class User {
  public id?: string;

  public firstName: string;

  public lastName: string;

  public email: string;

  public company: Company;

  constructor(id: string | undefined, firstName: string, lastName: string, email: string, company: Company) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.company = company;
  }

  public objectToBody() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      company: this.company,
    };
  }

  public static jsonToObject(object: any): User {
    return new User(
      object.id,
      object.firstName,
      object.lastName,
      object.email,
      Company[object.company as keyof typeof Company],
    );
  }
}

export default User;
