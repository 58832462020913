import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import { useFormFieldState } from 'form-field-state';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import User from '../../entities/User/User';
import { Company } from '../../enum/Company';
import useUsersStyles from '../../Pages/Users/UsersStyles';
import FieldValidators from '../../validators/FieldValidators';

interface PropTypes {
  userData?: User,
  onSubmit: (user: User | null) => void,
}

const UserDetailsForm = (props: PropTypes) => {
  const { userData, onSubmit } = props;
  const [t] = useTranslation();
  const styles = useUsersStyles();
  const [firstNameField, setFirstName] = useFormFieldState<string>(userData?.firstName || '',
    FieldValidators.validateEmptyField);
  const [lastNameField, setLastName] = useFormFieldState<string>(userData?.lastName || '',
    FieldValidators.validateEmptyField);
  const [emailField, setEmail] = useFormFieldState<string>(userData?.email || '', FieldValidators.validateEmail);
  const [companyField, setCompany] = useFormFieldState<Company>(userData?.company || '' as any, FieldValidators.validateCompany);

  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setEmail(userData.email);
      setCompany(userData.company);
    }
   
  }, [userData]);

  const validateBeforeSubmit = () : boolean => {
    const allFields = [firstNameField, lastNameField, emailField, companyField];
    let hasErrors = false;

    allFields.forEach((field) => {
      const error = field.validate();
      hasErrors = hasErrors || error.hasErrors;
      field.refresh();
    });
    return hasErrors;
  };

  const handleSubmit = (): void => {
    if (validateBeforeSubmit()) {
      onSubmit(null);
      return;
    }

    const changedUser = new User(userData?.id, firstNameField.value!, lastNameField.value!, emailField.value!,
      companyField.value! as Company);

    onSubmit(changedUser);
  };

  return (
    <section className={styles.usersContainer} id="user-form-section">
      <Grid container className={styles.fieldsContainer}>
        <Grid xs={12} md={6} item>
          <TextField
            fullWidth
            label={t('users.form.firstName')}
            value={firstNameField.value}
            onChange={(event) => setFirstName(event.target.value)}
            error={firstNameField.hasErrors}
            helperText={firstNameField.errorMessage}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            fullWidth
            label={t('users.form.lastName')}
            value={lastNameField.value}
            onChange={(event) => setLastName(event.target.value)}
            error={lastNameField.hasErrors}
            helperText={lastNameField.errorMessage}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            fullWidth
            label={t('users.form.email')}
            value={emailField.value}
            onChange={(event) => setEmail(event.target.value)}
            error={emailField.hasErrors}
            helperText={emailField.errorMessage}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            fullWidth
            select
            label={t('users.form.company')}
            value={companyField.value}
            onChange={(event) => setCompany(Company[event.target.value as keyof typeof Company])}
            error={companyField.hasErrors}
            helperText={companyField.errorMessage}
          >
            { Object.values(Company).map((value) => (
              <MenuItem key={value} value={value}>
                {t(`companies.${value.toLocaleLowerCase()}`)}
              </MenuItem>
            )) }
          </TextField>
        </Grid>
      </Grid>
      <Grid item lg={12} container direction="row" justifyContent="flex-end" className={styles.actionButtonsContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          {t('users.form.save')}
        </Button>
      </Grid>
    </section>
  );
};

export default UserDetailsForm;
