import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@material-ui/core';
import { plainToInstance } from 'class-transformer';
import { useFormFieldState } from 'form-field-state';
import { useTranslation } from 'react-i18next';
import Insurer from '../../../entities/Insurers/Insurer';
import FieldValidators from '../../../validators/FieldValidators';
import CNPJMaskedInput from '../../Form/CNPJMaskedInput';
import useFormsStyles from '../../../styles/forms/FormStyles';

interface PropTypes {
  titleLabel: string,
  onSubmit: (customer: Insurer | null) => void,
  insurerData?: Insurer,
}

const InsurerForm = (props: PropTypes) => {
  const { titleLabel, onSubmit, insurerData } = props;
  const [t] = useTranslation();
  const formsStyles = useFormsStyles();
  const [nameField, setName] = useFormFieldState<string>(insurerData?.name || '', FieldValidators.validateEmptyField);
  const [cnpjField, setCnpj] = useFormFieldState<string>(insurerData?.cnpj || '', FieldValidators.validateEmptyField);
  const [addressField, setAddress] = useFormFieldState<string>(insurerData?.address || '');
  const [isActive, setActive] = useState<boolean>(insurerData?.active ?? true);

  useEffect(() => {
    if (insurerData) {
      setName(insurerData.name);
      setCnpj(insurerData.cnpj);
      setAddress(insurerData.address);
      setActive(insurerData.active);
    }
   
  }, [insurerData]);

  const validateBeforeSubmit = () : boolean => {
    const allFields = [nameField, cnpjField, addressField];

    let hasErrors = false;
    allFields.forEach((field) => {
      const error = field.validate();
      hasErrors = hasErrors || error.hasErrors;
      field.refresh();
    });
    return hasErrors;
  };

  const handleSubmit = (): void => {
    if (validateBeforeSubmit()) {
      onSubmit(null);
      return;
    }

    const changedInsurer: Insurer = {
      ...insurerData,
      name: nameField.value,
      cnpj: cnpjField.value,
      address: addressField.value,
      active: isActive,
    };

    onSubmit(plainToInstance(Insurer, changedInsurer));
  };

  const renderSubmitButtons = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleSubmit()}
    >
      {t('cirgs.customers.details.customer.actions.save')}
    </Button>
  );

  return (
    <section className={formsStyles.formSection} id="insurer-form-section">
      <Grid container alignItems="center">
        <Grid xs={10}>
          <Typography
            variant="h5"
            component="h2"
            className={formsStyles.sectionTitle}
          >
            {t(titleLabel)}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <FormControlLabel
            control={(
              <Switch
                checked={isActive}
                onChange={() => setActive(!isActive)}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
        )}
            label={t('insurers.form.active')}
          />
        </Grid>
      </Grid>
      <Grid container className={formsStyles.fieldsContainer}>
        <Grid xs={12}>
          <TextField
            fullWidth
            label={t('insurers.form.name')}
            value={nameField.value}
            onChange={(event) => setName(event.target.value)}
            error={nameField.hasErrors}
            helperText={nameField.errorMessage}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            label={t('insurers.form.cnpj')}
            value={cnpjField.value}
            onChange={(event) => setCnpj(event.target.value)}
            error={cnpjField.hasErrors}
            helperText={cnpjField.errorMessage}
            InputProps={{
              inputComponent: CNPJMaskedInput,
            }}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            fullWidth
            label={t('insurers.form.address')}
            value={addressField.value}
            onChange={(event) => setAddress(event.target.value)}
            error={addressField.hasErrors}
            helperText={addressField.errorMessage}
          />
        </Grid>
        <Grid item lg={12} container direction="row" justifyContent="flex-end">
          { renderSubmitButtons() }
        </Grid>
      </Grid>
    </section>
  );
};

export default InsurerForm;
