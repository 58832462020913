import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const usePageContainerStyles = makeStyles((theme: Theme) => createStyles({
  mainContainer: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  [theme.breakpoints.down('sm')]: {
    mainContainer: {
      margin: theme.spacing(2),
    },
  },
}));

export default usePageContainerStyles;
