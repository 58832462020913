import { makeStyles } from '@material-ui/core/styles';

const useBackgroundImageStyles = makeStyles({
  standardStyles: {
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
    border: 'none',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
  },
});

export default useBackgroundImageStyles;
