import React from 'react';
import clsx from 'clsx';
import useBackgroundImageStyles from './BackgroundImageStyles';

interface PropTypes {
  className?: string,
  image?: string
}

const BackgroundImage = (props: PropTypes) => {
  const { className = '', image = undefined } = props;
  const styles = useBackgroundImageStyles();

  const style = () => {
    if (!image) return undefined;

    return { background: `url("${image}") center center fixed` };
  };

  return (
    <div
      data-testid="background-image"
      className={clsx(styles.standardStyles, className)}
      style={style()}
    />
  );
};
export default BackgroundImage;
