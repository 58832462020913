import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useInsurersHomeStyles = makeStyles((theme: Theme) => createStyles({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default useInsurersHomeStyles;
