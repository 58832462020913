import { AuthActionTypes, AuthAction, AuthState } from './types';

const initialAuthState = {
  status: AuthActionTypes.AUTHENTICATING,
  user: null,
};

const updateStateForFailures = (actionType: AuthActionTypes) => ({
  status: actionType,
  user: null,
});

const authReducer = (state: AuthState = initialAuthState,
  action: AuthAction) : AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGGED_IN:
    case AuthActionTypes.NEW_PASSWORD_REQUIRED:
      return {
        status: action.type,
        user: action.user || null,
      };
    case AuthActionTypes.SIGN_IN_FAILED:
    case AuthActionTypes.NONE:
    case AuthActionTypes.USER_CHALLENGE_FAILED:
    case AuthActionTypes.START_FORGOT_PASSWORD_FAILED:
    case AuthActionTypes.PASSWORD_RESET_FAILED:
      return updateStateForFailures(action.type);
    case AuthActionTypes.AUTHENTICATING:
    case AuthActionTypes.START_FORGOT_PASSWORD:
    case AuthActionTypes.PASSWORD_RESET_SUCCESSFUL:
      return {
        ...state,
        status: action.type,
      };
    default:
      return state;
  }
};

export default authReducer;
