import i18next from 'i18next';
import { FormFieldState, FormFieldError } from 'form-field-state';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Gender } from '../enum/Gender';
import { MaritalStatus } from '../enum/MaritalStatus';
import { CirgsAgency } from '../enum/CirgsAgency';
import { ReportTypes } from '../enum/CirgsReportEnums';
import { Company } from '../enum/Company';

const validateEmptyField = (field: FormFieldState<string>): FormFieldError => {
  let hasErrors = false;
  let message = null;

  if (!field.value || field.value === '') {
    hasErrors = true;
    message = i18next.t('validations.empty_field');
  }

  return {
    hasErrors,
    message,
  };
};

const validateEmail = (field: FormFieldState<string>) : FormFieldError => {
  let hasErrors = false;
  let message = null;
  const EMAIL_REGEX = /\S+@\S+\.\S+/;

  if ((!field.value || !EMAIL_REGEX.test(field.value)) && field.value !== ' ') {
    hasErrors = true;
    message = i18next.t('validations.invalid_email');
  }

  return {
    hasErrors, message,
  };
};

const cpfValidator = (field: FormFieldState<string>) : FormFieldError => {
  let hasErrors = false;
  let message = null;
  const { value } = field;

  if (!value || value.length !== 11) {
    hasErrors = true;
    message = i18next.t('validations.invalid_cpf');
  } else {
    const firstVerificationDigit = parseInt(value[9], 10);
    const secondVerificationDigit = parseInt(value[10], 10);
    let firstDigitValidation = 0;
    let secondDigitValidation = 0;

    for (let i = 0; i < 9; i += 1) {
      firstDigitValidation += parseInt(value[i], 10) * (10 - i);
      secondDigitValidation += parseInt(value[i], 10) * (11 - i);
    }
    secondDigitValidation += firstVerificationDigit * 2;

    let modFirst = ((firstDigitValidation * 10) % 11);
    modFirst = modFirst >= 10 ? 0 : modFirst;

    let modSecond = ((secondDigitValidation * 10) % 11);
    modSecond = modSecond >= 10 ? 0 : modSecond;

    const isFirstVerificationValid = modFirst === firstVerificationDigit;
    const isSecondVerificationValid = modSecond === secondVerificationDigit;

    hasErrors = !isFirstVerificationValid || !isSecondVerificationValid;
    message = hasErrors ? i18next.t('validations.invalid_cpf') : null;
  }

  return {
    hasErrors, message,
  };
};

const validateDateInPast = (field: FormFieldState<MaterialUiPickersDate>) : FormFieldError => {
  let hasErrors = false;
  let message = null;

  if (!field.value) {
    hasErrors = true;
    message = i18next.t('validations.invalid_date');
  } else {
    const inPast = field.value.startOf('day').isBefore(Date.now());
    hasErrors = !inPast;
    message = i18next.t('validations.invalid_date');
  }

  return {
    hasErrors, message,
  };
};

const validateGender = (field: FormFieldState<Gender|string>) : FormFieldError => {
  let hasErrors = false;
  let message = null;

  if (!field.value || !(field.value in Gender)) {
    hasErrors = true;
    message = i18next.t('validations.invalid_gender');
  }

  return {
    hasErrors, message,
  };
};

const validateMaritalStatus = (field: FormFieldState<MaritalStatus|string>) : FormFieldError => {
  let hasErrors = false;
  let message = null;

  if (!field.value || !(field.value in MaritalStatus)) {
    hasErrors = true;
    message = i18next.t('validations.invalid_marital_status');
  }

  return {
    hasErrors, message,
  };
};

const validateAgency = (field: FormFieldState<CirgsAgency|string>) : FormFieldError => {
  let hasErrors = false;
  let message = null;

  const validAgencies = Object.values(CirgsAgency) as string[];
  if (!field.value || !(validAgencies.includes(field.value!))) {
    hasErrors = true;
    message = i18next.t('validations.invalid_agency');
  }

  return {
    hasErrors, message,
  };
};

const greaterThanZero = (field: FormFieldState<string>) : FormFieldError => {
  let hasErrors = false;
  let message = null;

  if (!field.value || !parseInt(field.value.replace('.', ''), 10)) {
    hasErrors = true;
    message = i18next.t('validations.invalid_amount_value');
  }

  return {
    hasErrors, message,
  };
};

const validateReportType = (field: FormFieldState<ReportTypes|string>) : FormFieldError => {
  let hasErrors = false;
  let message = null;

  const validReport = Object.values(ReportTypes) as string[];
  if (!field.value || !(validReport.includes(field.value!))) {
    hasErrors = true;
    message = i18next.t('validations.invalid_report_type');
  }

  return {
    hasErrors, message,
  };
};

const validateCompany = (field: FormFieldState<Company>) : FormFieldError => {
  let hasErrors = false;
  let message = null;

  if (!field.value || !(field.value in Company)) {
    hasErrors = true;
    message = i18next.t('validations.invalid_company');
  }

  return {
    hasErrors, message,
  };
};

const validateEqualFields = (field: FormFieldState<string>) : FormFieldError => {
  let { hasErrors, message } = validateEmptyField(field);

  if (!hasErrors && field.dependentFields.password
     && (field.dependentFields.password?.value !== field.value)) {
    hasErrors = true;
    message = i18next.t('validations.different_passwords');
  }

  return {
    hasErrors, message,
  };
};

const validateNumber = (field: FormFieldState<any>) : FormFieldError => {
  let hasErrors = false;
  let message = '';

  const number = Number(field.value);

  if (!hasErrors && Number.isNaN(number)) {
    hasErrors = true;
    message = i18next.t('validations.invalid_number');
  }

  return {
    hasErrors, message,
  };
};

export default { validateEmptyField,
  validateEmail,
  cpfValidator,
  validateDateInPast,
  validateGender,
  validateMaritalStatus,
  greaterThanZero,
  validateAgency,
  validateReportType,
  validateCompany,
  validateEqualFields,
  validateNumber,
};
