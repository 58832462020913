import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomerList from '../../../components/Customer/CustomerList';
import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import useCreditCardsHomeStyles from './CreditCardsHomeStyles';

const CreditCardsHome = () => {
  const [t] = useTranslation();
  const styles = useCreditCardsHomeStyles();
  const navigate = useNavigate();

  return (
    <PageContainer>
      <PageBreadcrumb items={[t('credit_cards.home.title')]} />
      <div id="credit_cards-home" className={styles.ccHomeContainer}>
        <CustomerList onListItemClick={(customerId: string) => navigate(`/customers/${customerId}`)} />
      </div>
    </PageContainer>
  );
};

export default CreditCardsHome;
