import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ThemeService from '../../services/theme/ThemeService';
import useSideMenuStyles from './SideMenuStyles';
import { RootState } from '../../redux/reducers';
import { menus } from '../../entities/Menu';
import { useNavigate } from 'react-router-dom';

interface PropTypes{
  open?: boolean
}

const SideMenu = (props: PropTypes) => {
  const { open } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const styles = useSideMenuStyles(ThemeService.currentUserTheme());
  const currentUser = useSelector((state: RootState) => state.auth.user);

  return (
    <Drawer
      className={styles.drawer}
      variant="persistent"
      classes={{
        paper: styles.drawerPaper,
      }}
      open={open || false}
    >
      <Toolbar />
      <div className={styles.drawerContainer}>
        <List>
          <ListItem button onClick={() => navigate("/")}>
            <ListItemIcon>
              <FontAwesomeIcon size="lg" icon={faHome} className={styles.menuListIcon} />
            </ListItemIcon>
            <ListItemText primary={t('menu.home')} />
          </ListItem>
          {
            menus
              .filter((menu) => currentUser?.permissions[menu.resource])
              .map((menu) => (
                <ListItem button onClick={() => menu.onClick(navigate)} key={menu.resource}>
                  <ListItemIcon>
                    <FontAwesomeIcon size="lg" icon={menu.icon} className={styles.menuListIcon} />
                  </ListItemIcon>
                  <ListItemText primary={t(menu.text)} />
                </ListItem>
              ))
          }
        </List>
      </div>
    </Drawer>
  );
};

export default SideMenu;
