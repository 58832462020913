import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../styles/colors';

const useCreditCardStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    width: '387px',
    height: '246px',
    background: `linear-gradient(90deg, ${colors.lightGray}, ${colors.gray});`,
    transition: 'all 0.25s ease-out',
    boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.6)',
    transformStyle: 'preserve-3d',
    borderColor: colors.gray,
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(1)}px`,
  },
  hideCardRow: {
    display: 'none !important',
  },
  cardCompanyRow: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '50%',
  },
  ccFont: {
    fontFamily: 'OCR A Std',
  },
  cardNumberRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
    marginBottom: '4%',
  },
  cardHolderRow: {
    display: 'flex',
    alignItems: 'center',
  },
  cardValidRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  chip: {
    position: 'relative',
    marginTop: '45px',
    marginLeft: '25px',
    width: '50px',
    height: '40px',
    border: 'thin solid #777',
    borderRadius: '8px',
    background: 'linear-gradient(45deg, rgba(240,230,140, 1) 0%, rgb(212,175,55) 100%)',
  },
  chipHorizontalLine: {
    width: '100%',
    borderBottom: '1px solid #777',
    position: 'absolute',
    top: '20px',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      borderBottom: '1px solid #777',
      display: 'block',
    },
    '&:before': {
      top: '-12px',
    },
    '&:after': {
      top: '12px',
    },
  },
  chipVerticalLine: {
    position: 'absolute',
    height: '100%',
    width: '1px',
    top: '0',
    left: '15px',
    borderLeft: '1px solid #777',
    '&:before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: '1px',
      top: '0',
      left: '15px',
      display: 'block',
      borderLeft: '1px solid #777',
    },
  },
}));

export default useCreditCardStyles;
