import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, IconButton, InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faChevronRight, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Skeleton } from '@material-ui/lab';
import PageBreadcrumb from '../../components/PageBreadcrumb';
import PageContainer from '../../components/PageContainer';
import useUsersStyles from './UsersStyles';
import UsersService from '../../services/user/UsersService';
import User from '../../entities/User/User';
import { useNavigate } from 'react-router-dom';

const UsersList = () => {
  const [t] = useTranslation();
  const styles = useUsersStyles();
  const [searchUserValue, setSearchUserValue] = useState<string>('');
  const [usersList, setUsersList] = useState<User[]>([]);
  const [displayUsersList, setDisplayUsersList] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loadUsers = useCallback(() => {
    setLoading(true);
    UsersService.getAllUsers()
      .then((users) => {
        if (users) {
          setUsersList(users);
        }
      })
      .finally(() => setLoading(false));
  }, [setLoading]);

  useEffect(() => loadUsers(), [loadUsers]);

  useEffect(() => {
    let filteredUsers = usersList;
    if (searchUserValue) {
      filteredUsers = usersList.filter((user) => user.firstName.includes(searchUserValue)
      || user.email.includes(searchUserValue) || user.lastName.includes(searchUserValue));
    }
    setDisplayUsersList(filteredUsers);
  }, [usersList, searchUserValue]);

  const renderUsersListItems = () => {
    if (displayUsersList.length > 0) {
      return displayUsersList.map((user) => {
        const labelId = `checkbox-list-secondary-label-${user.id}`;
        return (
          <ListItem
            key={user.id}
            button
            onClick={() => navigate(`/users/${user.id}`)}
          >
            <ListItemText id={labelId}>
              <span>{`${user.firstName} ${user.lastName}`}</span>
            </ListItemText>

            <ListItemSecondaryAction
              onClick={() => navigate(`/users/${user.id}`)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </ListItemSecondaryAction>
          </ListItem>
        );
      });
    }

    return (
      <ListItem
        key="empty"
      >
        <ListItemText id="no-data">
          { loading ? (<Skeleton variant="text" />) : t('users.list.no_data') }
        </ListItemText>
      </ListItem>
    );
  };

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('users.title')]} />
      <div id="users-list" className={styles.usersContainer}>
        <TextField
          fullWidth
          className={styles.usersSearch}
          label={t('users.list.search')}
          variant="outlined"
          value={searchUserValue}
          onChange={(event) => setSearchUserValue(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <FontAwesomeIcon icon={faSearch} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div>
          <Fab
            variant="extended"
            color="secondary"
            className={styles.fab}
            onClick={() => navigate('/users/new')}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size="lg"
              className={styles.fabIcon}
            />
            {t('users.list.add')}
          </Fab>
        </div>

        <div>
          <Paper className={styles.usersListPaper}>
            <List className={styles.usersList}>
              { renderUsersListItems() }
            </List>
          </Paper>
        </div>
      </div>
    </PageContainer>
  );
};

export default UsersList;
