import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ClickAwayListener, Grow, ListItemIcon, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AuthStateUser } from '../../redux/Auth/types';
import ThemeService from '../../services/theme/ThemeService';
import useHeaderStyles from './HeaderStyles';
import AuthActions from '../../redux/Auth/actions';

interface PropTypes{
  user: AuthStateUser,
}

const UserAvatar = (props: PropTypes) => {
  const { user } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const avatarRef = useRef(null);
  const [menuOpen, setMenuState] = useState(false);
  const styles = useHeaderStyles(ThemeService.currentUserTheme());

  const handleCloseMenu = () => setMenuState(false);

  return (
    <>
      <Avatar
        className={styles.avatar}
        ref={avatarRef}
        onClick={() => setMenuState(true)}
      >
        { `${user.info?.name.charAt(0)}${user.info?.lastName.charAt(0)}` }
      </Avatar>
      <Popper
        open={menuOpen}
        anchorEl={avatarRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className={styles.avatarMenu}>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
                  <MenuItem onClick={() => dispatch(AuthActions.signOut())}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </ListItemIcon>
                    {t('header.menu.logout')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default UserAvatar;
