import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../../styles/colors';

const useAuditStyles = makeStyles((theme: Theme) => createStyles({
  eventPaper: {
    padding: theme.spacing(1),
  },
  eventEntry: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(1),
      color: colors.primary,
    },
    '&:last-child': {
      marginTop: theme.spacing(0.5),
    },
  },
  timelineDot: {
    '& > svg': {
      width: '19px !important',
      height: '19px',
    },
  },
  [theme.breakpoints.up('lg')]: {
    timelineDiv: {
      width: '60%',
    },
  },
  [theme.breakpoints.down('md')]: {
    timelineDiv: {
      width: '100%',
    },
  },
}));

export default useAuditStyles;
