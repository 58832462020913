import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import SignIn from '../../Pages/SignIn';
import Home from '../../Pages/Home';
import CreditCardsHome from '../../Pages/CreditCards/CreditCardsHome';
import NewCustomer from '../../Pages/Customer/NewCustomer';
import CustomerHome from '../../Pages/Customer/CustomerHome';
import CirgsHome from '../../Pages/Cirgs';
import UsersList from '../../Pages/Users/UsersList';
import UsersNew from '../../Pages/Users/UserNew';
import UserDetails from '../../Pages/Users/UserDetails';
import { Resources } from '../../enum/Resources';
import InsurersHome from '../../Pages/Insurers/InsurersHome';
import NewInsurer from '../../Pages/Insurers/NewInsurer/NewInsurer';
import InsurerDetails from '../../Pages/Insurers/InsurerDetails/InsurerDetails';
import BrokeragesHome from '../../Pages/Brokerages/BrokeragesHome';
import NewBrokerage from '../../Pages/Brokerages/NewBrokerage';
import BrokerageDetails from '../../Pages/Brokerages/BrokerageDetails';
import BrokeragesSettings from '../../Pages/Brokerages/BrokeragesSettings/BrokeragesSettings';
import BrokeragesReports from '../../Pages/Brokerages/BrokeragesReports/BrokeragesReports';


const AppRouter = () => (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route index element={<Home />} />
      </Route>

      <Route path="/creditcards" element={<PrivateRoute resource={Resources.CREDIT_CARDS} />}>
        <Route index element={<CreditCardsHome />} />
      </Route>

      <Route path="/customers" element={<PrivateRoute resource={Resources.CREDIT_CARDS} />}>
        <Route path='new' element={<NewCustomer />} />
        <Route path=':customerId' element={<CustomerHome />} />
      </Route>

      <Route path="/cirgs/*" element={<PrivateRoute resource={Resources.CIRGS} />}>
        <Route path="*" element={<CirgsHome />} />
      </Route>

      <Route path="/users" element={<PrivateRoute resource={Resources.USER_MANAGEMENT} />}>
        <Route index element={<UsersList />} />
        <Route path='new' element={<UsersNew />} />
        <Route path=':userId' element={<UserDetails />} />
      </Route>

      <Route path="/insurers" element={<PrivateRoute resource={Resources.INSURERS} />}>
        <Route index element={<InsurersHome />} />
        <Route path='new' element={<NewInsurer />} />
        <Route path=':id' element={<InsurerDetails />} />
      </Route>

      <Route path="/brokerages" element={<PrivateRoute resource={Resources.BROKERAGE} />}>
        <Route index element={<BrokeragesHome />} />
        <Route path='new' element={<NewBrokerage />} />
        <Route path='reports' element={<BrokeragesReports />} />
        <Route path='settings' element={<BrokeragesSettings />} />
        <Route path=':id' element={<BrokerageDetails />} />
      </Route>


      <Route path="/signin" element={<SignIn />} />
      <Route path="/notfound" element={<div data-testid="empty-notfound-page">NOT FOUND</div>} />

    </Routes>
);

export default AppRouter;
