import React from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';

interface DateMaskedExtraProps {
  locale?: string;
}

type DateMaskedInputProps = KeyboardDatePickerProps & DateMaskedExtraProps;

const DateMaskedInput = (props: DateMaskedInputProps) => {
  const { locale = 'pt-br', ...other } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
      <KeyboardDatePicker
        {...other}
        disableToolbar
        variant="inline"
        format="DD/MM/yyyy"
        views={['year', 'month', 'date']}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateMaskedInput;
