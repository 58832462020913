import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCirgsReportsListStyles from './CustomerListStyles';
import CirgsReportService from '../../../services/cirgs/CirgsReportService';
import CirgsReport from '../../../entities/CIRGS/CirgsReport';
import CirgsReportIcon from '../../../components/Cirgs/CirgsReportIcon';
import { useNavigate } from 'react-router-dom';

interface ReportsListProps {
  setLoading: (value: boolean) => void,
}

const CirgsReportsList = (props: ReportsListProps) => {
  const { setLoading } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [reportsList, setReportsList] = useState<CirgsReport[]>([]);
  const styles = useCirgsReportsListStyles();

  const searchCustomer = useCallback(() => {
    setLoading(true);
    CirgsReportService.search()
      .then((reports) => {
        if (reports) {
          setReportsList(reports);
        }
      })
      .finally(() => setLoading(false));
  }, [setLoading]);

  useEffect(() => {
    searchCustomer();
  }, [searchCustomer]);

  return (
    <div id="cirgs-reports-list" className={styles.customerListContainer}>
      <div>
        <Fab
          variant="extended"
          color="secondary"
          className={styles.fab}
          onClick={() => navigate('/cirgs/reports/new')}
        >
          <FontAwesomeIcon
            icon={faPlus}
            size="lg"
            className={styles.fabIcon}
          />
          {t('cirgs.reports.list.add')}
        </Fab>
      </div>

      <div>
        <Paper className={styles.customerListPaper}>
          <List className={styles.customerList}>
            { reportsList.map((report) => {
              const labelId = `checkbox-list-secondary-label-${report.id}`;
              return (
                <ListItem
                  key={report.id}
                  button
                  onClick={() => navigate(`/cirgs/reports/${report.id}`)}
                >
                  <ListItemAvatar>
                    <CirgsReportIcon status={report.status} />
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={report.name}
                    secondary={t('cirgs.reports.list.description', { date: report.completedAt ?? report.createdAt, type: report.type })}
                  />
                  <ListItemSecondaryAction>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </div>
    </div>
  );
};

export default CirgsReportsList;
