import React, { useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormFieldState } from 'form-field-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { Alert } from '@material-ui/lab';
import useUserStyles from '../../Pages/Users/UsersStyles';
import FieldValidators from '../../validators/FieldValidators';
import { RootState } from '../../redux/reducers';
import { AuthActionTypes } from '../../redux/Auth/types';

const LOWER_CASE_REGEX = /[a-z]+/;
const UPPER_CASE_REGEX = /[A-Z]+/;
const NUMBER_REGEX = /[0-9]+/;

interface PropTypes {
  onSubmit: (passwordValue: string) => void,
}

const UserSetNewPassword = (props: PropTypes) => {
  const { onSubmit } = props;
  const [t] = useTranslation();
  const userStyles = useUserStyles();
  const [passwordField, setPasswordField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const [passwordConfirmField, setPasswordConfirmField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const [passwordHasLowerCase, setHasLowerCase] = useState(false);
  const [passwordHasUpperCase, setHasUpperCase] = useState(false);
  const [passwordHasNumberCase, setHasNumberCase] = useState(false);
  const [passwordMinSize, setHasMinSize] = useState(false);
  const authStatus = useSelector((state: RootState) => state.auth.status);

  const validateBeforeSubmit = () => {
    const fields = [passwordField, passwordConfirmField];
    let hasErrors = false;
    fields.forEach((field) => {
      const error = field.validate();
      hasErrors = hasErrors || error.hasErrors;
      field.refresh();
    });

    if (passwordField.value !== passwordConfirmField.value!) {
      passwordConfirmField.hasErrors = true;
      passwordConfirmField.errorMessage = t('validations.different_passwords');
      return true;
    }

    return hasErrors;
  };

  const submitResetPassword = () => {
    const rules = [passwordHasLowerCase, passwordHasUpperCase, passwordHasNumberCase, passwordMinSize];
    const rulesOk = rules.reduce((prev, cur) => prev && cur, true);
    if (validateBeforeSubmit() && !rulesOk) {
      return;
    }

    onSubmit(passwordField.value!);
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setHasLowerCase(LOWER_CASE_REGEX.test(value));
    setHasUpperCase(UPPER_CASE_REGEX.test(value));
    setHasNumberCase(NUMBER_REGEX.test(value));
    setHasMinSize(value.length >= 8);
    setPasswordField(value);
  };

  const renderPasswordRules = () => {
    const rules = [
      { value: passwordMinSize, label: 'signin.form.password_rules.size' },
      { value: passwordHasLowerCase, label: 'signin.form.password_rules.lowercase' },
      { value: passwordHasUpperCase, label: 'signin.form.password_rules.uppercase' },
      { value: passwordHasNumberCase, label: 'signin.form.password_rules.number' },
    ];

    return (
      <Grid xs={12}>
        <ul className={userStyles.passwordRulesList}>
          {rules.map((rule) => (
            <li key={rule.value.toString()}>
              <FontAwesomeIcon
                icon={rule.value ? faCheck : faTimes}
                className={clsx({
                  [userStyles.passwordRuleOk]: rule.value,
                  [userStyles.passwordRuleFail]: !rule.value,
                })}
              />
              <span>{t(rule.label)}</span>
            </li>
          ))}
        </ul>
      </Grid>
    );
  };

  const renderErrorMessage = () => {
    const errorLabels = () => {
      switch (authStatus) {
        case AuthActionTypes.USER_CHALLENGE_FAILED:
          return 'signin.form.failed_change_password';
        case AuthActionTypes.PASSWORD_RESET_FAILED:
          return 'signin.form.failed_password_reset';
        default:
          return undefined;
      }
    };

    const label = errorLabels();

    return label && <Alert severity="error">{t(label)}</Alert>;
  };

  return (
    <Grid container className={userStyles.usersContentGrid}>
      <Grid xs={12}>
        <TextField
          fullWidth
          type="password"
          label={t('signin.form.password')}
          value={passwordField.value}
          onChange={onChangePassword}
          error={passwordField.hasErrors}
          helperText={passwordField.errorMessage}
        />
      </Grid>

      <Grid xs={12}>
        <TextField
          fullWidth
          type="password"
          label={t('signin.form.confirm_password')}
          value={passwordConfirmField.value}
          onChange={(event) => setPasswordConfirmField(event.target.value)}
          error={passwordConfirmField.hasErrors}
          helperText={passwordConfirmField.errorMessage}
        />
      </Grid>
      {renderPasswordRules()}

      { renderErrorMessage() }

      <Grid xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={submitResetPassword}
        >
          {t('signin.form.change_password')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserSetNewPassword;
