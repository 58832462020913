import { CircularProgress } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import ThemeService from '../../services/theme/ThemeService';
import usePageContainerStyles from './PageContainerStyles';

interface PageContainerProps {
  loading?: boolean,
  children: React.ReactNode
}

const PageContainer = (props: PropsWithChildren<PageContainerProps>) => {
  const { children, loading = false } = props;
  const styles = usePageContainerStyles(ThemeService.currentUserTheme);

  return (
    <>
      { loading
        && (
          <div className={styles.loadingContainer}>
            <CircularProgress size={60} />
          </div>
        )}
      <main className={styles.mainContainer}>
        {children}
      </main>
    </>
  );
};

export default PageContainer;
