import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useNewCustomerStyles = makeStyles((theme: Theme) => createStyles({
  newCustomerContainer: {
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      width: '80%',
      marginBottom: theme.spacing(2),
    },
  },
  newCustomerActionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  [theme.breakpoints.down('xs')]: {
    newCustomerContainer: {
      '& > *': {
        width: '100%',
      },
    },
  },
  [theme.breakpoints.up('lg')]: {
    newCustomerContainer: {
      '& > *': {
        width: '70%',
      },
    },
  },
}));

export default useNewCustomerStyles;
