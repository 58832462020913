import { faCheck, faExclamationTriangle, faFilter, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsReportCustomerInfo from '../../../entities/CIRGS/CirgsReportCustomerInfo';
import { ReportCustomerErrors, ReportCustomerStatus } from '../../../enum/CirgsReportEnums';
import CirgsReportService from '../../../services/cirgs/CirgsReportService';
import useCirgsFormsStyles from '../styles/CirgsFormsStyles';
import useCirgsReportCustomersListStyles from './CirgsReportCustomersListStyles';
import { useNavigate } from 'react-router-dom';

interface PropTypes {
  reportId: string
}

const CirgsReportCustomersList = (props: PropTypes) => {
  const { reportId } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [customers, setCustomers] = useState<CirgsReportCustomerInfo[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(new Set([ReportCustomerStatus.ERROR,
    ReportCustomerStatus.WARNING]));
  const [loading, setLoading] = useState(false);
  const formsStyles = useCirgsFormsStyles();
  const styles = useCirgsReportCustomersListStyles();

  const loadCustomers = useCallback(() => {
    if (reportId) {
      setLoading(true);
      CirgsReportService.findCustomers(reportId, Array.from(selectedStatus.keys()))
        .then((response) => setCustomers(response))
        .finally(() => setLoading(false));
    }
  }, [reportId, selectedStatus]);

  useEffect(() => loadCustomers(), [loadCustomers]);

  const getChipIcon = (type: string) => {
    switch (type) {
      case ReportCustomerStatus.OK:
        return faCheck;
      case ReportCustomerStatus.WARNING:
        return faExclamationTriangle;
      case ReportCustomerStatus.ERROR:
        return faTimes;
      default:
        return faQuestion;
    }
  };

  const getChipColor = (type: string) => {
    switch (type) {
      case ReportCustomerStatus.OK:
        return styles.chipOk;
      case ReportCustomerStatus.WARNING:
        return styles.chipWarning;
      case ReportCustomerStatus.ERROR:
        return styles.chipError;
      default:
        return '';
    }
  };

  const getStatusColor = (type: string) => {
    switch (type) {
      case ReportCustomerStatus.OK:
        return styles.customerIconOk;
      case ReportCustomerStatus.WARNING:
        return styles.customerIconWarning;
      case ReportCustomerStatus.ERROR:
        return styles.customerIconError;
      default:
        return '';
    }
  };

  const updateSelectedChips = (statusValue: ReportCustomerStatus) => {
    const newSet = new Set(selectedStatus);
    if (newSet.has(statusValue)) {
      newSet.delete(statusValue);
    } else {
      newSet.add(statusValue);
    }
    setSelectedStatus(newSet);
  };

  const renderErrorMessage = (customer: CirgsReportCustomerInfo) => {
    if (!customer.errorType) {
      return '';
    }

    const label = `cirgs.reports.details.errors.${customer.errorType?.toLocaleLowerCase()}`;
    let params;
    switch (customer.errorType) {
      case ReportCustomerErrors.AMOUNT_MISMATCH:
      case ReportCustomerErrors.AUTO_INCREASE:
      case ReportCustomerErrors.AUTO_INCREASE_DIFF:
        params = {
          current_value: t('currency', { amount: customer.errorCurrentValue ? customer.errorCurrentValue! / 100 : 0 }),
          expected_value: t('currency', { amount: customer.errorExpectedValue ? customer.errorExpectedValue / 100 : 0 }),
        };
        break;
      default:
        params = {};
    }

    return t(label, params);
  };

  const renderLoading = () => Array.from(Array(10)).map((i) => <Skeleton key={i} animation="wave" />);

  const renderList = () => (
    <Paper className={styles.paper}>
      <List>
        { customers.length === 0 && (
          <ListItem key="no-data">
            <ListItemText
              primary={t('cirgs.reports.details.no_data')}
            />
          </ListItem>
        ) }
        { customers.map((customer) => {
          const labelId = `checkbox-list-secondary-label-${customer.customerID}`;
          return (
            <ListItem
              key={customer.customerID}
              divider
              button
              onClick={() => navigate(`/cirgs/customers/${customer.customerID}`)}
            >
              <ListItemAvatar>
                <FontAwesomeIcon
                  icon={getChipIcon(customer.status)}
                  className={getStatusColor(customer.status)}
                  size="2x"
                />
              </ListItemAvatar>
              <ListItemText
                id={labelId}
                primary={customer.customerName}
                secondary={renderErrorMessage(customer) as any}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <section className={formsStyles.formSection} id="report-form-section">
      <Typography
        variant="h5"
        component="h4"
        className={formsStyles.sectionTitle}
      >
        {t('cirgs.reports.details.customer_details')}
      </Typography>

      <Grid container direction="row" alignItems="center">
        <FontAwesomeIcon icon={faFilter} size="lg" className={styles.filterIcon} />
        { Object.keys(ReportCustomerStatus).map((statusValue) => (
          <Chip
            key={statusValue}
            icon={(
              <FontAwesomeIcon
                icon={getChipIcon(statusValue)}
                className={clsx(styles.chipIcon, {
                  [styles.chipIconSelected]: selectedStatus.has(statusValue as ReportCustomerStatus),
                  [styles.chipIconUnselected]: !selectedStatus.has(statusValue as ReportCustomerStatus),
                })}
              />
            )}
            className={clsx(styles.chip, {
              [getChipColor(statusValue)]: selectedStatus.has(statusValue as ReportCustomerStatus),
            })}
            clickable
            label={t(`cirgs.reports.details.customer_status_filters.${statusValue.toLocaleLowerCase()}`)}
            onClick={() => updateSelectedChips(statusValue as ReportCustomerStatus)}
          />
        ))}
      </Grid>

      {
        loading
          ? renderLoading()
          : renderList()
      }
    </section>
  );
};

export default CirgsReportCustomersList;
