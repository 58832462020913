import { Dialog, DialogTitle, Grid, Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsReportAutoAdjustmentAttributes from '../../../entities/CIRGS/CirgsReportAutoAdjustmentAttributes';
import useCirgsReportDetailsStyles from './CirgsReportDetailsStyles';

interface PropTypes {
  attributes?: CirgsReportAutoAdjustmentAttributes
}

const CirgsAutoReajustmentReportDetailsSection = (props: PropTypes) => {
  const { attributes } = props;
  const currentYear = new Date().getFullYear();
  const styles = useCirgsReportDetailsStyles();
  const [t] = useTranslation();
  const [showYearValuesPopUp, setShowYearValuesPopUp] = useState(false);

  const headCells = [
    { id: 'year', numeric: false, label: 'year' },
    { id: 'total', numeric: false, label: 'total_readjustment_rate_premium' },
  ];

  const isYearBasedReadjustment = () => typeof attributes?.insurancePremiumRate !== 'number';

  const openYearValuesPopUp = () => {
    if (isYearBasedReadjustment() && !showYearValuesPopUp) {
      setShowYearValuesPopUp(true);
    }
  };

  const closeYearValuesPopUp = () => {
    if (showYearValuesPopUp) {
      setShowYearValuesPopUp(false);
    }
  };

  const renderYearValuesPopUp = () => (
    <Dialog onClose={closeYearValuesPopUp} aria-labelledby="simple-dialog-title" open={showYearValuesPopUp}>
      <DialogTitle>
        {`${t('cirgs.reports.auto_readjustment.readjustment')}`}
      </DialogTitle>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((cell) => (
              <TableCell
                key={cell.id}
                align="center"
                padding="normal"
                component="th"
              >
                <strong>{t(`cirgs.reports.auto_readjustment.age_based_table.columns.${cell.label}`)}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {Object.entries(attributes?.insurancePremiumRate as Record<string, number>).map(([year, value]) => (
          <TableRow key={year}>
            <TableCell
              key={`${year.toString()}-0`}
              align="center"
              padding="normal"
              component="th"
            >
              <strong>
                {t('cirgs.reports.auto_readjustment.age_based_table.rows.year', { year, age: currentYear - Number(year) })}
              </strong>
            </TableCell>
            <TableCell
              key={`${year.toString()}-value`}
              align="center"
              padding="normal"
              component="td"
            >
              { t('percent', { value }) }
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Dialog>
  );

  return (
    <>
      <Grid item xs={12} md={4}>
        <Typography
          variant="caption"
        >
          {`${t('cirgs.reports.auto_readjustment.policy_type')}:`}
        </Typography>
        <Typography
          variant="body1"
          className={styles.fieldText}
        >
          { attributes?.policyName ?? '' }
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography
          variant="caption"
        >
          {`${t('cirgs.reports.auto_readjustment.readjustment_rate_value')}:`}
        </Typography>
        <Typography
          variant="body1"
          className={styles.fieldText}
        >
          {
            attributes?.insuranceValueRate
              ? t('percent', { value: attributes?.insuranceValueRate })
              : ''
          }
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography
          variant="caption"
        >
          {`${t('cirgs.reports.auto_readjustment.readjustment_rate_premium')}:`}
        </Typography>
        <Typography
          variant="body1"
          className={clsx(styles.fieldText, { [styles.fieldTextLink]: isYearBasedReadjustment() })}
          onClick={openYearValuesPopUp}
        >
          {
            isYearBasedReadjustment()
              ? t('cirgs.reports.auto_readjustment.show_premium_table')
              : t('percent', { value: attributes?.insurancePremiumRate })
          }
        </Typography>
      </Grid>
      { isYearBasedReadjustment() && renderYearValuesPopUp() }
    </>
  );
};

export default CirgsAutoReajustmentReportDetailsSection;
