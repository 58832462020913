import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormFieldState } from 'form-field-state';
import Alert from '@material-ui/lab/Alert';
import useSignInStyles from './SignInStyles';
import FieldValidators from '../../validators/FieldValidators';
import AuthActions from '../../redux/Auth/actions';
import UserSetNewPassword from '../../components/Users/UserSetNewPassword';
import { RootState } from '../../redux/reducers';
import { AuthActionTypes } from '../../redux/Auth/types';

const SignInForgotPassword = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const signInStyles = useSignInStyles();
  const [step, setStep] = useState(0);
  const [emailField, setEmailField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const [codeField, setCodeField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const authStatus = useSelector((state: RootState) => state.auth.status);

  useEffect(() => {
    switch (authStatus) {
      case AuthActionTypes.START_FORGOT_PASSWORD:
        setStep(1);
        break;
      default:
        break;
    }
  }, [authStatus]);

  const handleForgotPassword = () => {
    if (emailField.validate().hasErrors) {
      return;
    }
    dispatch(AuthActions.forgotPassword(emailField.value!));
  };

  const handlePasswordChange = (newPassword: string) => {
    if (emailField.validate().hasErrors && codeField.validate().hasErrors) {
      return;
    }

    dispatch(AuthActions.changePasswordViaCode(emailField.value!, newPassword, codeField.value!));
  };

  const renderErrorMessage = () => {
    const errorLabels = () => {
      switch (authStatus) {
        case AuthActionTypes.START_FORGOT_PASSWORD_FAILED:
          return 'signin.form.failed_start_forgot_password';
        default:
          return undefined;
      }
    };

    const label = errorLabels();

    return label && <Alert severity="error">{t(label)}</Alert>;
  };

  const codeStep = () => (
    <>
      <Grid xs={12} className={signInStyles.changePasswordTitle}>
        <Typography variant="body1" component="p">
          {t('signin.form.code_sent_explain')}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <TextField
          fullWidth
          type="number"
          label={t('signin.form.reset_password_code')}
          value={codeField.value}
          onChange={(event) => setCodeField(event.target.value)}
          error={codeField.hasErrors}
          helperText={codeField.errorMessage}
        />
      </Grid>
      <Grid xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => setStep(2)}
        >
          {t('signin.form.submit_code')}
        </Button>
      </Grid>
    </>
  );

  const newPasswordStep = () => (
    <>
      <Grid xs={12} className={signInStyles.changePasswordTitle}>
        <Typography variant="h6" component="h2">
          {t('signin.form.forgot_password')}
        </Typography>
      </Grid>
      <UserSetNewPassword onSubmit={handlePasswordChange} />
    </>
  );

  const emailStep = () => (
    <>
      <Grid xs={12} className={signInStyles.changePasswordTitle}>
        <Typography variant="h6" component="h2">
          {t('signin.form.forgot_password')}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <TextField
          fullWidth
          type="email"
          label={t('signin.form.email')}
          value={emailField.value}
          onChange={(event) => setEmailField(event.target.value)}
          error={emailField.hasErrors}
          helperText={emailField.errorMessage}
        />
      </Grid>

      { renderErrorMessage() }

      <Grid xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={handleForgotPassword}
        >
          {t('signin.form.change_password')}
        </Button>
      </Grid>
    </>
  );

  const renderSteps = () => {
    switch (step) {
      case 0: return emailStep();
      case 1: return codeStep();
      case 2: return newPasswordStep();
      default: return emailStep();
    }
  };

  return (
    <Grid container className={signInStyles.signInContentGrid}>

      { renderSteps() }

    </Grid>
  );
};

export default SignInForgotPassword;
