import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsInsuranceTableStyles = makeStyles((theme: Theme) => createStyles({
  tableTitleDiv: {
    marginBottom: theme.spacing(2),
  },
  toolbarGrow: {
    flexGrow: 1,
  },
  toolbarIcon: {
    color: theme.palette.primary.main,
  },
  toolbarIconRed: {
    color: theme.palette.error.dark,
  },
}));

export default useCirgsInsuranceTableStyles;
