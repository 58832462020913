import User from '../../entities/User/User';
import AdminApiRequestService from '../ApiRequestService/AdminApiRequestService';

const getAllUsers = async (): Promise<User[]> => {
  const url = '/users';
  const response = await AdminApiRequestService.request('GET', url);
  const responseBody = await response.json() as any[];
  if (responseBody && Array.isArray(responseBody)) {
    return Promise.resolve(responseBody.map((item) => User.jsonToObject(item)));
  }

  return Promise.resolve([]);
};

const create = async (user: User): Promise<User> => {
  const url = '/users';
  const body = user.objectToBody();
  const response = await AdminApiRequestService.request('POST', url, body);
  const responseBody = await response.json();
  return Promise.resolve(User.jsonToObject(responseBody));
};

const getById = async (userId: string): Promise<User> => {
  const url = `/users/${userId}`;
  const response = await AdminApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(User.jsonToObject(responseBody));
};

export default {
  getAllUsers,
  create,
  getById,
};
