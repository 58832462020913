class Customer {
  name: string;

  id: string | null;

  constructor(name: string, id: string | null = null) {
    this.name = name;
    this.id = id;
  }
}

export default Customer;
