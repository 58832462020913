import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Fab, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerListStyles from './CustomerListStyles';
import CustomerService from '../../../services/customer/CustomerService';
import Customer from '../../../entities/Customer/Customer';
import { useNavigate } from 'react-router-dom';

interface CustomerListProps {
  onListItemClick: (customerId: string) => void,
}

const CustomerList = (props: CustomerListProps) => {
  const { onListItemClick } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [searchCustomerValue, setSearchCustomerValue] = useState<string | null>(null);
  const [customersList, setCustomersList] = useState<Customer[]>([]);
  const [displayCustomersList, setDisplayCustomersList] = useState<Customer[]>([]);
  const [loadingList, setLoadingList] = useState(false);
  const styles = useCustomerListStyles();

  useEffect(() => {
    setLoadingList(true);
    CustomerService.getAllCustomers()
      .then((customers) => {
        setCustomersList(customers.sort((a, b) => (a.name > b.name ? 1 : -1)));
      })
      .finally(() => setLoadingList(false));
  }, []);

  useEffect(() => {
    if (customersList.length > 0) {
      setLoadingList(true);
      setDisplayCustomersList(customersList.filter((v) => v.name.toUpperCase().includes(searchCustomerValue?.toUpperCase() || '')));
      setLoadingList(false);
    }
  }, [searchCustomerValue, customersList]);

  return (
    <div id="customer-list" className={styles.customerListContainer}>
      <TextField
        fullWidth
        className={styles.customerSearch}
        label={t('credit_cards.home.customer_search')}
        variant="outlined"
        value={searchCustomerValue}
        onChange={(event) => setSearchCustomerValue(event.target.value)}
      />

      <div>
        <Fab
          variant="extended"
          color="secondary"
          className={styles.fab}
          onClick={() => navigate('/customers/new')}
        >
          <FontAwesomeIcon
            icon={faPlus}
            size="lg"
            className={styles.fabIcon}
          />
          {t('credit_cards.home.add_customer')}
        </Fab>
      </div>

      <div>
        <Paper className={styles.customerListPaper}>

          { loadingList
            && (
            <div className={styles.loadingContainer}>
              <CircularProgress size={60} />
            </div>
            )}

          <List className={styles.customerList}>
            { displayCustomersList.map((customer) => {
              const labelId = `checkbox-list-secondary-label-${customer.id}`;
              return (
                <ListItem
                  key={customer.id}
                  button
                  onClick={() => onListItemClick(customer.id!)}
                >
                  <ListItemText id={labelId} primary={customer.name} />
                  <ListItemSecondaryAction>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </div>
    </div>
  );
};

export default CustomerList;
