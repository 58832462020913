import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsFormsStyles = makeStyles((theme: Theme) => createStyles({
  formSection: {
    marginBottom: theme.spacing(6),
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  fieldsContainer: {
    '& > *': {
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
  },
  [theme.breakpoints.down('xs')]: {
    fieldsContainer: {
      '& > *': {
        paddingRight: 0,
      },
    },
  },
}));

export default useCirgsFormsStyles;
