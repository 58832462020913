import { createTheme } from '@material-ui/core/styles';
import colors from './colors';

const defaultTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      default: colors.lightGray,
    },
  },
  overrides: {
    MuiTableCell: {
      footer: {
        color: colors.black,
        fontSize: '0.875rem',
        fontWeight: 'bold',
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#009688',
    },
    secondary: {
      main: colors.white,
    },
  },
});

const headerLightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.white,
      contrastText: colors.primary,
    },
  },
});

const contactFormTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colors.white,
    },
  },
});

const errorAndDangerTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.red,
      contrastText: colors.white,
    },
  },
});

const signInTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#CCC',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

export { defaultTheme, darkTheme, headerLightTheme, contactFormTheme, signInTheme, errorAndDangerTheme };
