 
import React from 'react';
import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Brokerage from '../../../entities/Brokerage/Brokerage';

interface PropTypes {
  brokerage: Brokerage,
  selected?: boolean
  onSelect: (id: string, checked: boolean) => void,
  showInsurerColumn: boolean,
  insurerName?: string
}

const BrokerageTableRow = (props: PropTypes) => {
  const { brokerage, onSelect, selected, showInsurerColumn, insurerName } = props;
  const [t] = useTranslation();

  const renderViewMode = () => (
    <>
      { showInsurerColumn
        && (
        <TableCell
          scope="cell"
        >
          {insurerName}
        </TableCell>
        )}
      <TableCell
        scope="cell"
      >
        {t('date', { date: new Date(brokerage.date) })}
      </TableCell>
      <TableCell
        scope="cell"
      >
        { t(`brokerages.types.${brokerage.description}`) }
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: brokerage.grossBrokerage / 100 })}
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: brokerage.valueIssqn / 100 })}
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: (brokerage.grossBrokerage - brokerage.valueIssqn) / 100 })}
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: brokerage.netPremium / 100 })}
      </TableCell>
    </>
  );

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={brokerage.id}
    >
      <TableCell padding="checkbox">
        <Checkbox
          onChange={(event) => onSelect(brokerage.id, event.target.checked)}
          checked={selected}
        />
      </TableCell>
      {renderViewMode()}
    </TableRow>
  );
};

export default BrokerageTableRow;
