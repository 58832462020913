import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faEye, faFile, faFileCode, faFileCsv, faFileExcel, faFileImage, faFilePdf, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, Paper, ThemeProvider, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsAbstractFile from '../../../entities/CIRGS/CirgsAbstractFile';
import CirgsCustomerAttachment from '../../../entities/CIRGS/CirgsCustomerAttachment';
import CirgsCustomerService from '../../../services/cirgs/CirgsCustomerService';
import CirgsFileService from '../../../services/cirgs/CirgsFileService';
import ThemeService from '../../../services/theme/ThemeService';
import useCirgsReportFileCardStyles from './CirgsFileCardStyles';

interface PropTypes {
  reportFile: CirgsAbstractFile,
  downloadEnabled?: boolean,
  previewEnabled?: boolean,
  deleteEnabled?: boolean,
  onCardAction?: (callback: () => Promise<void>) => void
}

const CirgsFileCard = (props: PropTypes) => {
  const {
    reportFile,
    downloadEnabled = false,
    previewEnabled = false,
    deleteEnabled = false,
    onCardAction = () => {},
  } = props;
  const [t] = useTranslation();
  const styles = useCirgsReportFileCardStyles();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const fileIconFromExtension = (fileType: string) => {
    switch (fileType) {
      case 'text/csv': return faFileCsv;
      case 'application/json': return faFileCode;
      case 'application/pdf': return faFilePdf;
      case 'application/excel': return faFileExcel;
      case fileType.match(/^image\/.*/)?.input:
        return faFileImage;
      default: return faFile;
    }
  };

  const downloadBlob = (blob: any, fileName: string) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  };

  const downloadFile = async () => {
    const data = await CirgsFileService.downloadFile(reportFile);
    downloadBlob(data.body, reportFile.fileName);
  };

  const deleteFile = async () => {
    onCardAction(async () => CirgsCustomerService.deleteAttachment(reportFile as CirgsCustomerAttachment));
    setDeleteDialogOpen(false);
  };

  return (
    <Paper className={styles.paper}>
      <Dialog
        maxWidth="xs"
        open={deleteDialogOpen}
      >
        <DialogTitle id="confirmation-dialog-delete">
          {t('cirgs.customers.details.attachments.delete_dialog.title')}
        </DialogTitle>
        <DialogActions>
          <Button color="primary" autoFocus onClick={() => setDeleteDialogOpen(false)}>
            {t('cirgs.customers.details.attachments.delete_dialog.cancel')}
          </Button>
          <ThemeProvider theme={ThemeService.errorDangerTheme}>
            <Button color="primary" onClick={deleteFile}>
              {t('cirgs.customers.details.attachments.delete_dialog.confirm')}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>

      <Grid container className={styles.paperDivLine}>
        <Grid container alignItems="center">
          <FontAwesomeIcon
            icon={fileIconFromExtension(reportFile.fileApplicationType)}
            size="3x"
            className={styles.fileIcon}
          />
          <Typography variant="body1">{reportFile.fileName}</Typography>
        </Grid>
        <Grid container alignItems="center">
          <FontAwesomeIcon
            icon={faCalendarAlt}
            className={styles.dateIcon}
          />
          <Typography
            variant="body2"
            className={styles.dateText}
          >
            {t('datetime', { datetime: reportFile.createdAt })}
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end">
          {
            deleteEnabled && (
            <ThemeProvider theme={ThemeService.errorDangerTheme}>
              <IconButton
                color="primary"
                onClick={() => setDeleteDialogOpen(true)}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                />
              </IconButton>
            </ThemeProvider>
            )
          }
          { previewEnabled && (
          <IconButton
            color="secondary"
          >
            <FontAwesomeIcon
              icon={faEye}
            />
          </IconButton>
          ) }

          {downloadEnabled && (
            <IconButton
              color="secondary"
              onClick={downloadFile}
            >
              <FontAwesomeIcon
                icon={faDownload}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CirgsFileCard;
