const isLocalhost = (): boolean => window.location.hostname === 'localhost';

const cognitoConfig = {
  Auth: {
    Cognito: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'sa-east-1:1653053b-94ef-4ba9-bb66-925e3867c9d3',

      // REQUIRED - Amazon Cognito Region
      region: 'sa-east-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'sa-east-1_pbFa5Qto6',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: '5tl4dqlmedf2q24jk1psvo6p3c',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
      loginWith: {
        oauth: {
          domain: 'bika.auth.sa-east-1.amazoncognito.com',
          redirectSignIn: isLocalhost() ? ['http://localhost:3000/'] : ['https://admin.bika.com.br/'],
          redirectSignOut: isLocalhost() ? ['http://localhost:3000/'] : ['https://admin.bika.com.br/'],
          scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile', 'phone'],
          responseType: "code" as "code" | "token",
        },
      },

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: isLocalhost() ? 'localhost' : 'bika.com.br',
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: false,
      },
    }
  },
  Storage: {
    S3: {
      bucket: 'cirgs-reports-bucket',
      region: 'us-east-1',
      identityPoolId: 'sa-east-1:1653053b-94ef-4ba9-bb66-925e3867c9d3',
    }
  },
};

export default cognitoConfig;
