import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import BrokeragesTable from '../../../components/Brokerages/BrokeragesTable/BrokeragesTable';
import BrokerageTabs from '../../../components/Brokerages/BrokerageTabs/BrokerageTabs';

const BrokeragesHome = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('brokerages.title')]} />

      <BrokerageTabs />

      <BrokeragesTable setLoading={setLoading} isLoading={loading} titleLabel={t('brokerages.title')} />
    </PageContainer>
  );
};

export default BrokeragesHome;
