 
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Grid, IconButton, MenuItem, TableCell, TableRow, TextField, Tooltip } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useFormFieldState } from 'form-field-state';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsInsurance from '../../../entities/CIRGS/CirgsInsurance';
import FieldValidators from '../../../validators/FieldValidators';
import NumberInput from '../../Form/NumberInput';
import DateMaskedInput from '../../Form/DateMaskedInput';
import useCirgsInsuranceTableStyles from './CirgsIsuraceTableStyles';
import { CirgsPolicies } from '../../../enum/CirgsPolicies';

interface PropTypes {
  insurance: CirgsInsurance
  editMode?: boolean
  selected?: boolean
  isNew?: boolean
  onSelect: (id: string, checked: boolean) => void
  onSubmit: (insurance: CirgsInsurance, isNew: boolean) => void;
  onCancel: (id: string, isNew: boolean) => void
}

const CirgsInsuranceTableRow = (props: PropTypes) => {
  const { insurance, editMode = false, selected = false, onSelect, isNew = false, onSubmit, onCancel } = props;
  const [t] = useTranslation();
  const styles = useCirgsInsuranceTableStyles();

  const integerAmountToString = (value: number | undefined) => (value ? (value / 100).toString() : '');
  const [isEditMode, setIsEditMode] = useState(editMode);

  const [policyTypeField, setPolicyTypeField] = useFormFieldState<string>(insurance?.policyName || '',
    FieldValidators.validateEmptyField);
  const [amountField, setAmountField] = useFormFieldState<string>(integerAmountToString(insurance.amount),
    FieldValidators.greaterThanZero);
  const [payAmountField, setPayAmountField] = useFormFieldState<string>(integerAmountToString(insurance.payAmount),
    FieldValidators.greaterThanZero);
  const [dateJoinedField, setDateJoined] = useFormFieldState<MaterialUiPickersDate>(insurance.dateJoined);
  const [startDateField, setStartDate] = useFormFieldState<MaterialUiPickersDate>(insurance.validityStartDate);

  useEffect(() => {
    setPolicyTypeField(insurance.policyName);
    setAmountField(integerAmountToString(insurance.amount));
    setPayAmountField(integerAmountToString(insurance.payAmount));
    setDateJoined(insurance.dateJoined);
    setStartDate(insurance.validityStartDate);
  }, [insurance]);

  useEffect(() => {
    setIsEditMode(editMode);
  }, [editMode]);

  const validateBeforeSubmit = () : boolean => {
    const allFields = [policyTypeField, amountField, payAmountField, dateJoinedField, startDateField];

    let hasErrors = false;
    allFields.forEach((field) => {
      const error = field.validate();
      hasErrors = hasErrors || error.hasErrors;
      field.refresh();
    });
    return hasErrors;
  };

  const handleSubmit = (): void => {
    if (validateBeforeSubmit()) {
      return;
    }

    const policyName = policyTypeField.value!;
    const amount = parseFloat(amountField.value!) * 100;
    const payAmount = parseFloat(payAmountField.value!) * 100;
    const dateJoined = dateJoinedField.value?.startOf('day');
    const startDate = startDateField.value?.startOf('day');

    const modifiedInsurance = new CirgsInsurance(policyName, amount, payAmount, dateJoined, startDate, insurance.id);

    onSubmit(modifiedInsurance, isNew);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    onCancel(insurance.id, isNew);
  };

  const renderEditMode = () => (
    <>
      <TableCell
        scope="cell"
      >
        <TextField
          fullWidth
          select
          label={t('cirgs.customers.details.insurance.policy_type')}
          value={policyTypeField.value}
          onChange={(event) => setPolicyTypeField(event.target.value)}
          error={policyTypeField.hasErrors}
          helperText={policyTypeField.errorMessage}
        >
          { CirgsPolicies.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          )) }
        </TextField>
      </TableCell>
      <TableCell
        scope="cell"
      >
        <TextField
          fullWidth
          label={t('cirgs.customers.details.insurance.amount')}
          value={amountField.value}
          onChange={(event) => setAmountField(event.target.value)}
          error={amountField.hasErrors}
          helperText={amountField.errorMessage}
          prefix="R$ "
          InputProps={{
            inputComponent: NumberInput,
          }}
        />
      </TableCell>
      <TableCell
        scope="cell"
      >
        <TextField
          fullWidth
          label={t('cirgs.customers.details.insurance.pay_amount')}
          value={payAmountField.value}
          onChange={(event) => setPayAmountField(event.target.value)}
          error={payAmountField.hasErrors}
          helperText={payAmountField.errorMessage}
          prefix="R$ "
          InputProps={{
            inputComponent: NumberInput,
          }}
        />
      </TableCell>
      <TableCell
        scope="cell"
      >
        <DateMaskedInput
          fullWidth
          label={t('cirgs.customers.details.insurance.date_joined')}
          value={dateJoinedField.value}
          onChange={(date) => setDateJoined(date)}
        />
      </TableCell>
      <TableCell
        scope="cell"
      >
        <DateMaskedInput
          fullWidth
          label={t('cirgs.customers.details.insurance.validity_start_date')}
          value={startDateField.value}
          onChange={(date) => setStartDate(date)}
        />
      </TableCell>
      <TableCell>
        <Grid container direction="column">
          <Tooltip title={t('cirgs.customers.details.insurance.cancel')!}>
            <IconButton
              aria-label="cancel"
              className={styles.toolbarIconRed}
              onClick={handleCancel}
            >
              <FontAwesomeIcon icon={faTimes} size="sm" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('cirgs.customers.details.insurance.save')!}>
            <IconButton
              aria-label="save"
              color="primary"
              onClick={handleSubmit}
            >
              <FontAwesomeIcon icon={faSave} size="sm" />
            </IconButton>
          </Tooltip>
        </Grid>
      </TableCell>
    </>
  );

  const renderViewMode = () => (
    <>
      <TableCell
        scope="cell"
      >
        {insurance.policyName}
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: insurance.amount / 100 })}
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: insurance.payAmount / 100 })}
      </TableCell>
      <TableCell
        scope="cell"
      >
        { insurance.dateJoined.isValid() ? t('date', { date: insurance.dateJoined }) : t('validations.no_date')}
      </TableCell>
      <TableCell
        scope="cell"
      >
        { insurance.validityStartDate.isValid() ? t('date', { date: insurance.validityStartDate }) : t('validations.no_date')}
      </TableCell>
      <TableCell />
    </>
  );

  return (
    <TableRow
      hover
      onClick={() => {}}
      tabIndex={-1}
      key={insurance.id}
    >
      <TableCell padding="checkbox">
        { !isNew && (
          <Checkbox
            onChange={(event) => onSelect(insurance.id, event.target.checked)}
            checked={selected}
          />
        )}
      </TableCell>
      {isEditMode ? renderEditMode() : renderViewMode()}
    </TableRow>
  );
};

export default CirgsInsuranceTableRow;
