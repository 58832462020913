import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import ptBR from './locales/pt-BR.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'pt-BR': { translation: ptBR },
    },
    fallbackLng: 'pt-BR',
    debug: false,
    interpolation: {
      escapeValue: false,
      format: (value, format, lang) => {
        if (format === 'number') return new Intl.NumberFormat(lang, { style: 'decimal' }).format(value);
        if (format === 'currency') return new Intl.NumberFormat(lang, { style: 'currency', currency: 'BRL' }).format(value);
        if (value instanceof Date) return moment(value).format(format);
        if (moment.isMoment(value)) return value.format(format);
        return value;
      },
    },
  });

export default i18n;
