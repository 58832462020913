enum AuditEventTypes {
  CREDIT_CARD_PUBLIC_INFO_READ = 'CREDIT_CARD_PUBLIC_INFO_READ',
  CREDIT_CARD_DETAILS_READ = 'CREDIT_CARD_DETAILS_READ',
  CUSTOMER_INFO_READ = 'CUSTOMER_INFO_READ',
  CUSTOMER_PASSCODE_GENERATED = 'CUSTOMER_PASSCODE_GENERATED',
  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CREDIT_CARD_ADDED = 'CREDIT_CARD_ADDED',
}

export default AuditEventTypes;
