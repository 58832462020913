import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsHomeStyles = makeStyles((theme: Theme) => createStyles({
  tabs: {
    marginBottom: theme.spacing(2),
  },
  homeCirgsContainer: {
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      width: '80%',
      marginBottom: theme.spacing(2),
    },
  },
  [theme.breakpoints.down('xs')]: {
    homeCirgsContainer: {
      '& > *': {
        width: '100%',
      },
    },
  },
  [theme.breakpoints.up('lg')]: {
    homeCirgsContainer: {
      '& > *': {
        width: '70%',
      },
    },
  },
}));

export default useCirgsHomeStyles;
