import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import FileUploader from '../../FileUploader/FileUploader';
import useCirgsReportFormStyles from './CirgsReportFormStyles';

interface PropTypes {
  onFileDrop: (acceptedFiles: File[]) => void,
}

const CirgsReportD8FormFields = (props: PropTypes) => {
  const { onFileDrop } = props;
  const styles = useCirgsReportFormStyles();

  return (
    <Grid xs={12} container>
      <Paper className={styles.paper}>
        <FileUploader noClick maxFiles={1} accept="text/plain" onDrop={onFileDrop} />
      </Paper>
    </Grid>
  );
};

export default CirgsReportD8FormFields;
