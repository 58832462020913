import { v4 as uuid } from 'uuid';
import UserInfo from '../../entities/User/UserInfo';
import * as Auth from 'aws-amplify/auth';

const signUpUser = async (name: string, email: string, password: string) : Promise<Auth.SignUpOutput> => Auth.signUp({
  username: email,
  password,
  options: {
    userAttributes: {
      'custom:id': uuid(),
      name,
      email,
    }
  }
})
  .then((signUpResult) => signUpResult)
  .catch((error) => Promise.reject(error));

const signIn = async () : Promise<void> => Auth.signInWithRedirect({ provider: { custom: 'GSuite'}});

const currentUser = async () : Promise<Auth.AuthUser> => Auth.getCurrentUser()

const signOut = async () : Promise<void> => {
  await Auth.signOut();
};

const getUserInfo = (callback: (info: UserInfo) => void) : void => {
  let userInfo = null;
  Auth.fetchUserAttributes().then((attributes) => {
    if (attributes) {
      userInfo = new UserInfo(attributes.sub, attributes.name, attributes.family_name, attributes.email);
      callback(userInfo);
    }
  });
};

const signInBasic = async (email: string, password: string) : Promise<Auth.SignInOutput> => Auth.signIn({username: email, password});

const solveNewPasswordChallenge = async (
  password: string,
  onSuccess: () => void,
  onFailure: (err: Error) => void,
) => {
  Auth.confirmSignIn({challengeResponse: password}).then(onSuccess).catch(onFailure)
};

const forgotPassword = async (email: string) : Promise<any> => Auth.resetPassword({username: email});

const changeForgotPassword = async (email: string, password: string, code: string) : Promise<void> => {
  return Auth.confirmResetPassword({username: email, confirmationCode: code, newPassword: password});
};

const currentJwtToken = async () => {
  const session = await Auth.fetchAuthSession();
  return session.tokens.idToken?.toString();
}

export default {
  signUpUser,
  signIn,
  currentUser,
  getUserInfo,
  signInBasic,
  solveNewPasswordChallenge,
  signOut,
  forgotPassword,
  changeForgotPassword,
  currentJwtToken,
};
