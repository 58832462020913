import React, { useCallback, useEffect, useState } from 'react';
import { faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, FormControlLabel, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper, Switch, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Insurer from '../../../entities/Insurers/Insurer';
import useListsStyles from '../../../styles/lists/ListsStyles';
import InsurerService from '../../../services/insurers/InsurerService';
import useInsurerListsStyles from './InsurersListStyles';
import { useNavigate } from 'react-router-dom';

interface InsurersListProps {
  setLoading: (value: boolean) => void,
}

const InsurersList = (props: InsurersListProps) => {
  const { setLoading } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const [onlyEnabled, setOnlyEnabled] = useState<boolean>(true);
  const [insurersList, setInsurersList] = useState<Insurer[]>([]);
  const listsStyles = useListsStyles();
  const styles = useInsurerListsStyles();

  const searchCustomer = useCallback(() => {
    setLoading(true);
    InsurerService.findInsurers(onlyEnabled, searchValue)
      .then((insurers) => {
        if (insurers) {
          setInsurersList(insurers);
        }
      })
      .finally(() => setLoading(false));
  }, [setLoading, searchValue, onlyEnabled]);

  useEffect(() => {
    searchCustomer();
  }, [searchCustomer]);

  return (
    <div id="insurers-list" className={listsStyles.listContainer}>
      <div className={listsStyles.filterBar}>
        <TextField
          fullWidth
          className={listsStyles.textFieldSearch}
          label={t('insurers.list.search')}
          variant="outlined"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          onKeyPress={(e) => { if (e.key === 'Enter') { searchCustomer(); } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={searchCustomer}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={(
            <Switch
              checked={onlyEnabled}
              onChange={() => setOnlyEnabled(!onlyEnabled)}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
        )}
          label={t('insurers.list.active')}
        />

      </div>

      <div>
        <Paper className={listsStyles.listPaperStyles}>
          <List className={listsStyles.list}>
            { insurersList.map((insurer) => {
              const labelId = `checkbox-list-secondary-label-${insurer.id}`;
              return (
                <ListItem
                  key={insurer.id}
                  button
                  onClick={() => navigate(`/insurers/${insurer.id}`)}
                >
                  <ListItemAvatar>
                    <Avatar className={clsx({ [styles.insurerAvatar]: insurer.active })}>
                      {insurer.name.substring(0, 2).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText id={labelId}>
                    <span>{insurer.name}</span>
                  </ListItemText>

                  <ListItemSecondaryAction
                    onClick={() => navigate(`/insurers/${insurer.id}`)}
                  >
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className={listsStyles.listActionIcon}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </div>
    </div>
  );
};

export default InsurersList;
