import * as Storage from 'aws-amplify/storage';
import { v4 as uuid } from 'uuid';
import CirgsReport from '../../entities/CIRGS/CirgsReport';
import CirgsReportCustomerInfo from '../../entities/CIRGS/CirgsReportCustomerInfo';
import CirgsReportFile from '../../entities/CIRGS/CirgsReportFile';
import { ReportCustomerStatus, FileTypes } from '../../enum/CirgsReportEnums';
import CirgsApiRequestService from '../ApiRequestService/CirgsApiRequestService';

const BUCKET_NAME = 'cirgs-reports-bucket';
const BUCKET_REGION = 'us-east-1';

const search = async (): Promise<CirgsReport[]> => {
  const url = '/reports';
  const response = await CirgsApiRequestService.request('GET', url);
  const responseBody = await response.json() as any[];
  if (responseBody && Array.isArray(responseBody)) {
    return Promise.resolve(responseBody.map((item) => CirgsReport.jsonToObject(item)));
  }

  return Promise.resolve([]);
};

const uploadFile = async (file: File, reportId: string): Promise<CirgsReportFile> => {
  const fileNameParts = file.name.split('.');
  const extension = fileNameParts.length >= 2 ? fileNameParts[fileNameParts.length - 1] : '';
  const fileName = `${uuid()}.${extension}`;
  const fileKey = `reports/${reportId}/${fileName}`;
  const newReportFile = new CirgsReportFile(BUCKET_NAME, fileKey, file.name, FileTypes.D8, file.type, reportId);

  Storage.uploadData({
    path: `public/${fileKey}`,
    data: file,
    options: {
      bucket: {
        bucketName: BUCKET_NAME,
        region: BUCKET_REGION,
      },
      contentType: file.type,
    }
  });
  return newReportFile;
};

const create = async (report: CirgsReport, initialFile?: File): Promise<CirgsReport> => {
  const url = '/reports';
  const reportId = uuid();
  if (initialFile) {
    const reportFile = await uploadFile(initialFile, reportId);
    report.files = [reportFile];
  }
  report.id = reportId;
  const body = report.objectToBody();
  const response = await CirgsApiRequestService.request('POST', url, body);
  if (response.ok) {
    const responseBody = await response.json();
    const newReport = CirgsReport.jsonToObject(responseBody);
    return Promise.resolve(newReport);
  }
  return Promise.reject(response.body);
};

const getById = async (reportId: string): Promise<CirgsReport> => {
  const url = `/reports/${reportId}`;
  const response = await CirgsApiRequestService.request('GET', url);
  if (response.ok) {
    const responseBody = await response.json() as any[];
    return Promise.resolve(CirgsReport.jsonToObject(responseBody));
  }

  return Promise.reject();
};

const findCustomers = async (reportID: string, status: ReportCustomerStatus[]): Promise<CirgsReportCustomerInfo[]> => {
  const url = `/reports/${reportID}/customers?status=${status.join(',')}`;
  const response = await CirgsApiRequestService.request('GET', url);
  const responseBody = await response.json() as any[];
  if (responseBody && Array.isArray(responseBody)) {
    return Promise.resolve(responseBody.map((item) => CirgsReportCustomerInfo.jsonToObject(item)));
  }

  return Promise.resolve([]);
};

export default {
  search, create, getById, findCustomers,
};
