 
import { Grid, TextField, MenuItem } from '@material-ui/core';
import { useFormFieldState } from 'form-field-state';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsReportAutoAdjustmentAttributes from '../../../entities/CIRGS/CirgsReportAutoAdjustmentAttributes';
import { CirgsPolicies } from '../../../enum/CirgsPolicies';
import FieldValidators from '../../../validators/FieldValidators';
import AutoReadjustmentRateLine from './ReportAutoReadjustment/AutoReadjustmentRateLine';

interface PropTypes {
  onAttributesChange: (newAttributes: Partial<CirgsReportAutoAdjustmentAttributes>) => void,
  setDefaultAttributes: (attributes: Partial<CirgsReportAutoAdjustmentAttributes>) => void
}

const CirgsReportAutoReadjustmentFormFields = (props: PropTypes) => {
  const { onAttributesChange, setDefaultAttributes } = props;
  const [t] = useTranslation();
  const [policyTypeField, setPolicyTypeField] = useFormFieldState<string>('',
    FieldValidators.validateEmptyField);

  useEffect(() => {
    if (setDefaultAttributes) {
      setDefaultAttributes({
        inflationInsuranceRate: 0,
        inflationPremiumRate: 0,
        insuranceValueRate: 0,
        insurancePremiumRate: 0,
      });
    }
  }, []);

  useEffect(() => {
    onAttributesChange({
      policyName: policyTypeField.value,
    });
  }, [policyTypeField.value]);

  const onChangeInsuranceValue = (inflationValue: number, insuranceValue: number | Record<number, number>) => {
    onAttributesChange({
      inflationInsuranceRate: inflationValue as number,
      insuranceValueRate: insuranceValue as number,
    });
  };

  const onChangePremiumValue = (inflationValue: number, insuranceValue: number | Record<number, number>) => {
    onAttributesChange({
      inflationPremiumRate: inflationValue,
      insurancePremiumRate: insuranceValue,
    });
  };

  return (
    <>
      <Grid xs={12} container>
        <TextField
          fullWidth
          select
          label={t('cirgs.reports.auto_readjustment.policy_type')}
          value={policyTypeField.value}
          onChange={(event) => setPolicyTypeField(event.target.value)}
          error={policyTypeField.hasErrors}
          helperText={policyTypeField.errorMessage}
        >
          { CirgsPolicies.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          )) }
        </TextField>
      </Grid>

      <AutoReadjustmentRateLine
        titleLabel="cirgs.reports.auto_readjustment.readjustment_rate_value"
        onChangeValue={onChangeInsuranceValue}
      />
      <AutoReadjustmentRateLine
        titleLabel="cirgs.reports.auto_readjustment.readjustment_rate_premium"
        allowMultipleValues
        policy={policyTypeField.value}
        onChangeValue={onChangePremiumValue}
      />
    </>
  );
};

export default CirgsReportAutoReadjustmentFormFields;
