import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, ThemeProvider, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useFormFieldState } from 'form-field-state';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsCustomer from '../../../entities/CIRGS/CirgsCusrtomer';
import { CirgsAgency } from '../../../enum/CirgsAgency';
import { Gender } from '../../../enum/Gender';
import { MaritalStatus } from '../../../enum/MaritalStatus';
import ThemeService from '../../../services/theme/ThemeService';
import FieldValidators from '../../../validators/FieldValidators';
import CPFMaskedInput from '../../Form/CPFMaskedInput';
import DateMaskedInput from '../../Form/DateMaskedInput';
import CirgsCustomerStatusChip from '../CirgsCustomerStatusChip';
import useCirgsFormsStyles from '../styles/CirgsFormsStyles';
import useCirgsCustomerFormStyles from './CirgsCustomerFormStyles';

interface PropTypes {
  titleLabel: string,
  isPartnerForm?: boolean,
  onSubmit: (customer: CirgsCustomer | null) => void,
  customerData?: CirgsCustomer,
}

const CirgsCustomerForm = (props: PropTypes) => {
  const { titleLabel, isPartnerForm = false, onSubmit, customerData } = props;
  const [t] = useTranslation();
  const formsStyles = useCirgsFormsStyles();
  const styles = useCirgsCustomerFormStyles();
  const [internalIdField, setInternalId] = useFormFieldState<string>(customerData?.internalId || '',
    FieldValidators.validateEmptyField);
  const [nameField, setName] = useFormFieldState<string>(customerData?.name || '', FieldValidators.validateEmptyField);
  const [cpfField, setCpf] = useFormFieldState<string>(customerData?.cpf || '', FieldValidators.cpfValidator);
  const [birthdayField, setBirthday] = useFormFieldState<MaterialUiPickersDate>(customerData?.birthday,
    FieldValidators.validateDateInPast);
  const [genderField, setGender] = useFormFieldState<Gender|string>(customerData?.gender || '', FieldValidators.validateGender);
  const [maritalStatusField, setMaritalStatus] = useFormFieldState<MaritalStatus|string>(customerData?.maritalStatus || '',
    FieldValidators.validateMaritalStatus);
  const [agencyField, setAgency] = useFormFieldState<CirgsAgency|string>(customerData?.agency || '', FieldValidators.validateAgency);
  const [contractField, setContract] = useFormFieldState<string>(customerData?.contractNumber || '');
  const [hasPassedField, setHasPassedAway] = useState<boolean>(customerData?.hasPassedAway || false);
  const [isSuspended, setSuspended] = useState<boolean>(!!customerData?.suspendedDate);

  useEffect(() => {
    if (customerData) {
      setInternalId(customerData.internalId!);
      setName(customerData.name);
      setCpf(customerData.cpf);
      setBirthday(customerData.birthday);
      setGender(customerData.gender);
      setAgency(customerData.agency!);
      setMaritalStatus(customerData.maritalStatus);
      setContract(customerData.contractNumber);
      setHasPassedAway(customerData.hasPassedAway);
      setSuspended(!!customerData.suspendedDate);
    }
   
  }, [customerData]);

  const validateBeforeSubmit = () : boolean => {
    const allFields = [nameField, cpfField, birthdayField, genderField, maritalStatusField, contractField];
    if (!isPartnerForm) {
      allFields.push(internalIdField, agencyField);
    }
    let hasErrors = false;
    allFields.forEach((field) => {
      const error = field.validate();
      hasErrors = hasErrors || error.hasErrors;
      field.refresh();
    });
    return hasErrors;
  };

  const handleSubmit = (isCancel: boolean = false): void => {
    if (validateBeforeSubmit()) {
      onSubmit(null);
      return;
    }

    const internalId = isPartnerForm ? undefined : internalIdField.value;
    const birthday = birthdayField.value!.startOf('day');
    const agency = agencyField.value && agencyField.value.length > 0 ? agencyField.value as CirgsAgency : undefined;

    const changedCustomer = new CirgsCustomer(nameField.value!, internalId, birthday,
      cpfField.value!, isPartnerForm, genderField!.value as Gender, maritalStatusField!.value as MaritalStatus,
      contractField!.value, hasPassedField, agency, customerData?.id || undefined);

    if (isCancel) {
      changedCustomer.canceledDate = new Date();
    }

    if (!customerData?.suspendedDate && isSuspended) {
      changedCustomer.suspendedDate = new Date();
    }

    onSubmit(changedCustomer);
  };

  const renderSubmitButtons = (customer?: CirgsCustomer) => {
    if (customer && customer.canceledDate) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(false)}
        >
          {t('cirgs.customers.details.customer.actions.reactivate')}
        </Button>
      );
    }
    return (
      <>
        <ThemeProvider theme={ThemeService.errorDangerTheme()}>
          <Button
            variant="contained"
            color="primary"
            className={styles.deleteButton}
            onClick={() => handleSubmit(true)}
          >
            {t('cirgs.customers.details.customer.actions.cancel_insurance')}
          </Button>
        </ThemeProvider>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(false)}
        >
          {t('cirgs.customers.details.customer.actions.save')}
        </Button>
      </>
    );
  };

  return (
    <section className={formsStyles.formSection} id="customer-form-section">
      <Typography
        variant="h5"
        component="h2"
        className={formsStyles.sectionTitle}
      >
        {t(titleLabel)}
        <CirgsCustomerStatusChip customer={customerData} className={styles.statusChip} />
      </Typography>
      <Grid container className={formsStyles.fieldsContainer}>
        { !isPartnerForm && (
          <Grid xs={12} md={4}>
            <TextField
              fullWidth
              label={t('cirgs.customers.details.customer.internal_id')}
              value={internalIdField.value}
              onChange={(event) => setInternalId(event.target.value)}
              error={internalIdField.hasErrors}
              helperText={internalIdField.errorMessage}
            />
          </Grid>
        )}
        <Grid xs={12} md={8}>
          <TextField
            fullWidth
            label={t('cirgs.customers.details.customer.name')}
            value={nameField.value}
            onChange={(event) => setName(event.target.value)}
            error={nameField.hasErrors}
            helperText={nameField.errorMessage}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            fullWidth
            label={t('cirgs.customers.details.customer.cpf')}
            value={cpfField.value}
            onChange={(event) => setCpf(event.target.value)}
            error={cpfField.hasErrors}
            helperText={cpfField.errorMessage}
            InputProps={{
              inputComponent: CPFMaskedInput,
            }}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <DateMaskedInput
            fullWidth
            label={t('cirgs.customers.details.customer.birthday')}
            onChange={setBirthday}
            value={birthdayField.value}
            disableFuture
          />
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            fullWidth
            select
            label={t('cirgs.customers.details.customer.gender')}
            value={genderField.value}
            onChange={(event) => setGender(Gender[event.target.value as keyof typeof Gender])}
            error={genderField.hasErrors}
            helperText={genderField.errorMessage}
          >
            { Object.values(Gender).map((value) => (
              <MenuItem key={value} value={value}>
                {t(`cirgs.customers.details.customer.gender_options.${value.toLocaleLowerCase()}`)}
              </MenuItem>
            )) }
          </TextField>
        </Grid>
        <Grid xs={12} md={4}>
          <TextField
            fullWidth
            select
            label={t('cirgs.customers.details.customer.marital_status')}
            value={maritalStatusField.value}
            onChange={(event) => setMaritalStatus(MaritalStatus[event.target.value as keyof typeof MaritalStatus])}
            error={maritalStatusField.hasErrors}
            helperText={maritalStatusField.errorMessage}
          >
            { Object.values(MaritalStatus).map((value) => (
              <MenuItem key={value} value={value}>
                {t(`cirgs.customers.details.customer.marital_options.${value.toLocaleLowerCase()}`)}
              </MenuItem>
            )) }
          </TextField>
        </Grid>
        { !isPartnerForm && (
          <Grid xs={12} md={4}>
            <TextField
              fullWidth
              select
              label={t('cirgs.customers.details.customer.agency')}
              value={agencyField.value}
              onChange={(event) => setAgency(event.target.value as CirgsAgency)}
              error={agencyField.hasErrors}
              helperText={agencyField.errorMessage}
            >
              { Object.values(CirgsAgency).map((value) => (
                <MenuItem key={value} value={value}>
                  {`${t(`cirgs.customers.details.customer.agencies.${value}`)} - ${value}`}
                </MenuItem>
              )) }
            </TextField>
          </Grid>
        )}
        { !isPartnerForm && (
          <Grid xs={12} md={4}>
            <TextField
              fullWidth
              label={t('cirgs.customers.details.customer.contract_number')}
              value={contractField.value}
              onChange={(event) => setContract(event.target.value)}
              error={contractField.hasErrors}
              helperText={contractField.errorMessage}
            />
          </Grid>
        )}
        <Grid xs={12} md={4} container direction="row" justifyContent="center">
          <FormControlLabel
            control={(
              <Checkbox
                checked={hasPassedField}
                onChange={(event, checked) => setHasPassedAway(checked)}
                name="has_passed_away"
                color="primary"
              />
            )}
            labelPlacement="end"
            label={t('cirgs.customers.details.customer.has_passed_away')}
          />
        </Grid>
        <Grid xs={12} md={4} container direction="row" justify="center">
          <FormControlLabel
            control={(
              <Checkbox
                checked={isSuspended}
                onChange={(event, checked) => setSuspended(checked)}
                name="customer_suspended"
                color="primary"
              />
            )}
            labelPlacement="end"
            label={t('cirgs.customers.details.customer.payment_suspended')}
          />
        </Grid>
        { customerData?.canceledDate && (
        <Grid xs={12} md={4}>
          <DateMaskedInput
            fullWidth
            label={t('cirgs.customers.details.customer.canceled_date')}
            value={customerData?.canceledDate || ''}
            disableFuture
            readOnly
            disabled
            onChange={() => {}}
          />
        </Grid>
        )}
        <Grid item lg={12} container direction="row" justifyContent="flex-end">
          { renderSubmitButtons(customerData) }
        </Grid>
      </Grid>
    </section>
  );
};

export default CirgsCustomerForm;
