import clsx from 'clsx';
import { faCalendarDay, faEye, faEyeSlash, faRedo } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Button, Typography, Grid, Tooltip, IconButton } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreditCardDetailsInfo from '../../../entities/CreditCards/CreditCardDetailsInfo';
import CreditCardPublicInfo from '../../../entities/CreditCards/CreditCardPublicInfo';
import Customer from '../../../entities/Customer/Customer';
import CustomerService from '../../../services/customer/CustomerService';
import CreditCardService from '../../../services/credit_card/CreditCardService';
import useCreditCardDetailsStyles from './CreditCardDetailsStyles';
import CreditCard from '../CreditCard';
import Passcode from '../../../entities/Customer/Passcode';

interface PropTypes {
  customer: Customer,
  setLoading: Dispatch<SetStateAction<boolean>>
}

const CreditCardDetails = (props: PropTypes) => {
  const [t] = useTranslation();
  const { customer, setLoading } = props;
  const styles = useCreditCardDetailsStyles();
  const [displayCCDetails, setDisplayDetails] = useState(false);
  const [ccInfo, setCCInfo] = useState<CreditCardPublicInfo | CreditCardDetailsInfo | null>(null);
  const [passcode, setPasscode] = useState<string | null>(null);

  const getCCInfoMemo = useMemo(() => CreditCardService.getCreditCardInfo(customer.id!, displayCCDetails),
    [customer, displayCCDetails]);

  const loadCCInfo = () => {
    setLoading(true);
    CreditCardService.getCreditCardInfo(customer.id!, displayCCDetails)
      .then((response: CreditCardPublicInfo) => {
        setCCInfo(response);
      })
      .catch(() => setCCInfo(null))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    if (customer) {
      getCCInfoMemo
        .then((response: CreditCardPublicInfo) => {
          setCCInfo(response);
        })
        .catch(() => setCCInfo(null))
        .finally(() => setLoading(false));
    }
  }, [customer, setLoading, getCCInfoMemo]);

  const toggleShowDetails = () => {
    setDisplayDetails(!displayCCDetails);
  };

  const shareCode = (type: string) => {
    if (!passcode) return;
    const text = t('credit_cards.card.code_share', { customerName: customer.name, passcode });

    switch (type) {
      case 'WHATSAPP':
        window.open(`https://api.whatsapp.com/send?text=${text}&lang=pt`, '_blank');
        break;
      default:
        navigator.clipboard.writeText(text);
    }
  };

  const generatePasscode = () => {
    setLoading(true);
    if (customer) {
      CustomerService.generatePasscode(customer.id!)
        .then((response: Passcode) => {
          setPasscode(response.passcode);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const renderCCInfo = () => {
    if (!ccInfo) {
      return null;
    }

    if (ccInfo instanceof CreditCardDetailsInfo) {
      return (
        <div>
          <CreditCard
            cardNumber={ccInfo.cardNumber}
            cardHolder={ccInfo.cardHolder}
            cardExpirationMonth={ccInfo.cardExpirationMonth}
            cardExpirationYear={ccInfo.cardExpirationYear}
          />
          <div className={styles.cardBillingDay}>
            <FontAwesomeIcon icon={faCalendarDay} size="lg" />
            <Typography component="p" variant="body2">
              {`${t('credit_cards.card.billing_date')} ${ccInfo.cardBillingDay}`}
            </Typography>
          </div>
        </div>
      );
    }

    return <CreditCard cardNumber={`${ccInfo?.cardFirstTwo}••••••••••${ccInfo?.cardLastFour}`} />;
  };

  const renderHideShowDetails = () => {
    let icon = faEye;
    let textLabel = 'credit_cards.card.show_details';
    if (displayCCDetails) {
      icon = faEyeSlash;
      textLabel = 'credit_cards.card.hide_details';
    }
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={toggleShowDetails}
        startIcon={<FontAwesomeIcon icon={icon} />}
        fullWidth
        className={styles.showHideDetailsButton}
      >
        {t(textLabel)}
      </Button>
    );
  };

  const renderCode = () => (
    <div className={styles.generatedCodeDisplay}>
      <Typography component="p" variant="body1">
        {t('credit_cards.card.code_send_explain')}
      </Typography>

      <Typography
        component="span"
        className={styles.generatedCodeText}
      >
        {passcode}
      </Typography>
      <div>
        <Tooltip title={t('credit_cards.card.code_share_whatsapp') as string}>
          <IconButton aria-label="share via WhatsApp" color="primary" onClick={() => shareCode('WHATSAPP')}>
            <FontAwesomeIcon icon={faWhatsapp} size="lg" className={styles.whatsAppIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('credit_cards.card.code_share_copy') as string}>
          <IconButton aria-label="copy" color="primary" onClick={() => shareCode('COPY')}>
            <FontAwesomeIcon icon={faCopy} size="lg" className={styles.copyIcon} />
          </IconButton>
        </Tooltip>
      </div>
      { !ccInfo && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<FontAwesomeIcon icon={faRedo} />}
          className={styles.generateCodeButton}
          onClick={loadCCInfo}
        >
          {t('credit_cards.card.update_cc_info')}
        </Button>
      )}
    </div>
  );

  return (
    <Grid id="credit_card_info" container alignItems="center" direction="column">
      { ccInfo && (
      <Grid item xs={12}>
        <div className={styles.reloadCreditCardDiv}>
          <IconButton color="primary" onClick={loadCCInfo}>
            <FontAwesomeIcon icon={faRedo} size="sm" className={styles.reloadCreditCardIcon} />
          </IconButton>
        </div>
        { renderCCInfo() }
        { renderHideShowDetails() }
      </Grid>
      ) }
      <Grid item xs={12}>
        <div className={clsx(styles.generateCodeSeparator, { [styles.hideNoCreditCard]: !ccInfo })}>
          <hr />
          <span>{t('credit_cards.card.or')}</span>
          <hr />
        </div>
        <Grid container direction="column" alignItems="center">
          <Typography component="p" variant="body1">
            {t('credit_cards.card.generate_code_explain')}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            startIcon={<FontAwesomeIcon icon={faRedo} />}
            className={styles.generateCodeButton}
            onClick={generatePasscode}
          >
            {t('credit_cards.card.generate_code')}
          </Button>
          { passcode && renderCode() }
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreditCardDetails;
