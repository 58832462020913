import { Permissions } from '../../entities/User/Permissions';
import UserInfo from '../../entities/User/UserInfo';

export enum AuthActionTypes {
  SIGN_IN_FAILED = 'SIGN_IN_FAILED',
  AUTHENTICATING = 'AUTHENTICATING',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  LOGGED_IN = 'LOGGED_IN',
  USER_CHALLENGE_FAILED = 'USER_CHALLENGE_FAILED',
  START_FORGOT_PASSWORD = 'START_FORGOT_PASSWORD',
  START_FORGOT_PASSWORD_FAILED = 'START_FORGOT_PASSWORD_FAILED',
  PASSWORD_RESET_SUCCESSFUL = 'PASSWORD_RESET_SUCCESSFUL',
  PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED',
  NONE = 'NONE',
}

export interface AuthStateUser{
  info: UserInfo | null
  permissions: Permissions
}

export interface AuthState{
  status: AuthActionTypes
  user: AuthStateUser | null
}

export interface AuthAction{
  type: AuthActionTypes
  user?: AuthStateUser | null
}
