import AuditEvent from '../../entities/Audit/AuditEvent';
import ApiRequestService from '../ApiRequestService/AdminApiRequestService';

const convertJsonToObject = (object: any): AuditEvent => ({
  eventType: object.event_type,
  timestamp: new Date(object.timestamp),
  user: object.user,
});

const getCustomerAudit = async (customerId: string): Promise<AuditEvent[]> => {
  const url = `/customers/${customerId}/audit`;
  const response = await ApiRequestService.request('GET', url);
  if (!response.ok) {
    return Promise.reject();
  }
  const responseBody = await response.json() as any[];
  return Promise.resolve(responseBody.map((item) => convertJsonToObject(item)));
};

export default { getCustomerAudit };
