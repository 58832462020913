import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PageContainer from '../../components/PageContainer';
import useHomePageStyles from './HomeStyles';
import StandardHomeTile from './StandardHomeTile';
import { RootState } from '../../redux/reducers';
import { menus } from '../../entities/Menu';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [t] = useTranslation();
  const styles = useHomePageStyles();
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();

  return (
    <PageContainer>
      <div id="home">
        <Grid container spacing={2}>
          {
            menus
              .filter((tile) => currentUser?.permissions[tile.resource])
              .map((tile) => (
                <Grid item xs={12} sm={6} lg={4} className={styles.homeTileGrid} key={tile.resource}>
                  <StandardHomeTile
                    text={
                      <Typography variant="h5" component="h2">{t(tile.text)}</Typography>
                    }
                    icon={
                      <FontAwesomeIcon icon={tile.icon} size="6x" />
                    }
                    onClick={() => tile.onClick(navigate)}
                    resource={tile.resource}
                    key={tile.resource}
                  />
                </Grid>
              ))
          }
        </Grid>
      </div>
    </PageContainer>
  );
};

export default Home;
