import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { useFormFieldState } from 'form-field-state';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsReport from '../../../entities/CIRGS/CirgsReport';
import CirgsReportExecutionAttributes from '../../../entities/CIRGS/CirgsReportExecutionAttributes';
import { ReportStatus, ReportTypes } from '../../../enum/CirgsReportEnums';
import FieldValidators from '../../../validators/FieldValidators';
import useCirgsFormsStyles from '../styles/CirgsFormsStyles';
import CirgsReportAutoReadjustmentFormFields from './CirgsReportAutoReadjustmentFormFields';
import CirgsReportD8FormFields from './CirgsReportD8FormFields';

interface PropTypes {
  onSubmit: (report: CirgsReport | null, initialFile?: File) => void,
}

const CirgsReportForm = (props: PropTypes) => {
  const { onSubmit } = props;
  const formsStyles = useCirgsFormsStyles();
  const [t] = useTranslation();
  const [isNameManualChanged, setIsNameManualChanged] = useState<boolean>(false);
  const [nameField, setName] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const [typeField, setType] = useFormFieldState<ReportTypes|string>('', FieldValidators.validateReportType);
  const [initialFile, setInitialFile] = useState<File|undefined>(undefined);
  const [executionAttributes, setExecutionAttributes] = useState<CirgsReportExecutionAttributes>({});
  const [newAttributes, setNewAttributes] = useState<CirgsReportExecutionAttributes>({});
  const [defaultAttributes, setDefaultAttributes] = useState<CirgsReportExecutionAttributes>({});

  useEffect(() => {
    setExecutionAttributes({ ...executionAttributes, ...newAttributes });
   
  }, [newAttributes]);

  useEffect(() => {
    setExecutionAttributes({ ...defaultAttributes, ...executionAttributes, ...newAttributes });
   
  }, [defaultAttributes]);

  const canSubmit = () : boolean => {
    const allFields = [nameField, typeField];
    let hasErrors = false;
    allFields.forEach((field) => {
      const error = field.validate();
      hasErrors = hasErrors || error.hasErrors;
      field.refresh();
    });
    return hasErrors;
  };

  const handleSubmit = (): void => {
    if (canSubmit()) {
      onSubmit(null);
      return;
    }

    const newReport = new CirgsReport(nameField.value!, typeField.value! as ReportTypes, ReportStatus.NEW);
    if (executionAttributes) {
      newReport.executionAttributes = executionAttributes;
    }

    onSubmit(newReport, initialFile);
  };

  const onFileDrop = (acceptedFiles: File[]) => {
    setInitialFile(acceptedFiles[0]);
  };

  const renderSpecificReportFields = () => {
    switch (typeField.value) {
      case ReportTypes.D8:
        return <CirgsReportD8FormFields onFileDrop={onFileDrop} />;
      case ReportTypes.AUTO_READJUSTMENT:
        return (
          <CirgsReportAutoReadjustmentFormFields
            onAttributesChange={setNewAttributes}
            setDefaultAttributes={setDefaultAttributes}
          />
        );
      default:
        return <></>;
    }
  };

  const onReportNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setIsNameManualChanged(true);
  };

  const adjustName = () => {
    if (!isNameManualChanged) {
      let name = '';
      switch (typeField.value) {
        case ReportTypes.D8:
          name = t(`cirgs.reports.new.default_name.${typeField.value.toLocaleLowerCase()}`, { date: new Date() });
          break;
        case ReportTypes.AUTO_READJUSTMENT:
          name = t(`cirgs.reports.new.default_name.${typeField.value.toLocaleLowerCase()}`, {
            date: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            number: executionAttributes?.policyName || '...',
          });
          break;
        case ReportTypes.SIAP:
          name = t(`cirgs.reports.new.default_name.${typeField.value.toLocaleLowerCase()}`, { date: new Date() });
          break;
        default:
          break;
      }
      setName(name);
    }
  };

   
  useEffect(() => adjustName(), [typeField.value, executionAttributes.policyName]);

  const onReportTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = ReportTypes[event.target.value as keyof typeof ReportTypes];
    setType(type);
    adjustName();
  };

  return (
    <section className={formsStyles.formSection} id="report-form-section">
      <Typography
        variant="h5"
        component="h2"
        className={formsStyles.sectionTitle}
      >
        {t('cirgs.reports.new.title')}
      </Typography>

      <Grid container className={formsStyles.fieldsContainer}>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            label={t('cirgs.reports.fields.name')}
            value={nameField.value}
            onChange={onReportNameChange}
            error={nameField.hasErrors}
            helperText={nameField.errorMessage}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            select
            label={t('cirgs.reports.fields.type')}
            value={typeField.value}
            onChange={onReportTypeChange}
            error={typeField.hasErrors}
            helperText={typeField.errorMessage}
          >
            { Object.values(ReportTypes).map((value) => (
              <MenuItem key={value} value={value}>
                {t(`cirgs.reports.report_types.${value.toLocaleLowerCase()}`)}
              </MenuItem>
            )) }
          </TextField>
        </Grid>
        { renderSpecificReportFields() }

        <Grid item lg={12} container direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            startIcon={<FontAwesomeIcon icon={faSave} />}
          >
            {t('cirgs.reports.new.save')}
          </Button>
        </Grid>
      </Grid>
    </section>
  );
};

export default CirgsReportForm;
