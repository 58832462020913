class CreditCardPublicInfo {
  cardFirstTwo: string;

  cardLastFour: string;

  cardAddedDate: Date;

  constructor(cardFirstTwo: string, cardLastFour: string, cardAddedDate: Date) {
    this.cardFirstTwo = cardFirstTwo;
    this.cardLastFour = cardLastFour;
    this.cardAddedDate = cardAddedDate;
  }
}

export default CreditCardPublicInfo;
