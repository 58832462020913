import React, { useEffect, useState } from 'react';
import { Button, Grid, LinearProgress, Paper, TextField, ThemeProvider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormFieldState } from 'form-field-state';
import { Alert } from '@material-ui/lab';
import BackgroundImage from '../../components/BackgroundImage';
import useSignInStyles from './SignInStyles';
import ThemeService from '../../services/theme/ThemeService';
import AuthActions from '../../redux/Auth/actions';
import FieldValidators from '../../validators/FieldValidators';
import { RootState } from '../../redux/reducers';
import SignInSetNewPassword from './SignInSetNewPassword';
import { AuthActionTypes } from '../../redux/Auth/types';
import SignInForgotPassword from './SignInForgotPassword';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const signInStyles = useSignInStyles();
  const [signInStep, setSignInStep] = useState<number>(0);
  const [emailField, setEmailField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const [passwordField, setPasswordField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const authStatus = useSelector((state: RootState) => state.auth.status);
  const navigate = useNavigate();

  useEffect(() => {
    switch (authStatus) {
      case AuthActionTypes.PASSWORD_RESET_SUCCESSFUL:
        setSignInStep(1);
        break;
      case AuthActionTypes.NEW_PASSWORD_REQUIRED:
        setSignInStep(2);
        break;
      case AuthActionTypes.LOGGED_IN:
        navigate('/');
        break;
      default:
        break;
    }
  }, [authStatus]);

  const renderFirstStep = () => (
    <div className={signInStyles.signInContentGrid}>
      <Button
        variant="contained"
        fullWidth
        onClick={() => dispatch(AuthActions.signIn())}
      >
        {t('signin.signin_button')}
      </Button>

      <Button
        variant="outlined"
        fullWidth
        onClick={() => setSignInStep(1)}
      >
        {t('signin.signin_basic')}
      </Button>
    </div>
  );

  const renderBasicAuthStep = () => (
    <Grid container className={signInStyles.signInContentGrid}>
      <Grid xs={12}>
        <TextField
          fullWidth
          label={t('signin.form.email')}
          value={emailField.value}
          onChange={(event) => setEmailField(event.target.value)}
          error={emailField.hasErrors}
          helperText={emailField.errorMessage}
        />
      </Grid>

      <Grid xs={12}>
        <TextField
          fullWidth
          type="password"
          label={t('signin.form.password')}
          value={passwordField.value}
          onChange={(event) => setPasswordField(event.target.value)}
          error={passwordField.hasErrors}
          helperText={passwordField.errorMessage}
        />
      </Grid>
      { authStatus === AuthActionTypes.SIGN_IN_FAILED
        && <Alert severity="error">{t('signin.form.wrong_user_password')}</Alert>}

      <Grid xs={6}>
        <Button
          variant="text"
          fullWidth
          onClick={() => setSignInStep(3)}
        >
          {t('signin.form.forgot_password')}
        </Button>
      </Grid>

      <Grid xs={6}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => dispatch(AuthActions.signInBasic(emailField.value!, passwordField.value!))}
        >
          {t('signin.form.signin')}
        </Button>
      </Grid>
    </Grid>
  );

  const renderSignInStep = () => {
    switch (signInStep) {
      case 0: return renderFirstStep();
      case 1: return renderBasicAuthStep();
      case 2: return <SignInSetNewPassword />;
      case 3: return <SignInForgotPassword />;
      default: return renderFirstStep();
    }
  };

  const renderLogo = () => (
    signInStep !== 2 && <img src="/assets/icons/logo-bika-full.svg" className={signInStyles.logo} />
  );

  const renderLoading = () => (
    authStatus === AuthActionTypes.AUTHENTICATING && <LinearProgress />
  );

  return (
    <>
      <BackgroundImage className={signInStyles.signInBackgroundImage} />
      <div id="signin-content" className={signInStyles.signInPage}>
        <Paper
          variant="elevation"
          elevation={5}
          className={signInStyles.modal}
        >
          <ThemeProvider theme={ThemeService.signInTheme()}>
            <div className={signInStyles.modalLoading}>
              { renderLoading() }
            </div>

            <div className={signInStyles.modelContent}>
              { renderLogo() }
              { renderSignInStep() }
            </div>
          </ThemeProvider>
        </Paper>
      </div>
    </>
  );
};

export default SignIn;
