import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const useStandardHomeTileStyles = makeStyles((theme: Theme) => createStyles({
  homeTileButtonBase: {
    height: '100%',
    width: '100%',
  },
  homeTilePaper: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  homeTileContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    color: colors.primaryDark,
    '& > svg': {
      color: colors.secondary,
    },
  },
  homeTileRipple: {
    color: colors.secondaryDark,
  },
}));

export default useStandardHomeTileStyles;
