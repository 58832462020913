 
import { InputAdornment, Table, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiAggregationTypes from '../../../../enum/APIAggregations';
import CirgsCustomerService from '../../../../services/cirgs/CirgsCustomerService';
import NumberInput from '../../../Form/NumberInput';

interface PropTypes {
  policy: string,
  onChangeRates: (increasePercent: Record<number, number>) => void,
}

const AutoReadjustmentMultipleValuesTable = (props: PropTypes) => {
  const { policy, onChangeRates } = props;
  const [t] = useTranslation();
  const currentYear = new Date().getFullYear();
  const [variableRatesPerYear, setVariableRates] = useState<{ [year: number]: number }>({});
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [isLoading, setLoading] = useState(false);

  const headCells = [
    { id: 'year', numeric: false, label: 'year' },
    { id: 'readjustment_variable', numeric: false, label: 'readjustment_variable' },
  ];

  const getAvailableYears = useCallback(() => {
    if (policy) {
      setLoading(true);
      CirgsCustomerService.searchCirgsCustomers(
        { aggregation: ApiAggregationTypes.CUSTOMER_BIRTH_YEARS, policy },
      ).then((years) => {
        setAvailableYears(years as number[]);
        setVariableRates({ ...(years as number[]).reduce((rates, year) => ({ [year]: 0, ...rates }), {}),
          ...variableRatesPerYear });
      })
        .finally(() => setLoading(false));
    }
  }, [policy]);

  useEffect(() => {
    getAvailableYears();
  }, [policy]);

  useEffect(() => {
    onChangeRates(variableRatesPerYear);
  }, [variableRatesPerYear]);

  const renderHeader = () => (
    <TableHead>
      <TableRow>
        {headCells.map((cell) => (
          <TableCell
            key={cell.id}
            align="center"
            padding="normal"
            component="th"
          >
            <strong>{t(`cirgs.reports.auto_readjustment.age_based_table.columns.${cell.label}`)}</strong>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderLines = () => (
    availableYears.map((year: number) => (
      <TableRow key={year}>
        <TableCell
          key={`${policy}-${year.toString()}-0`}
          align="center"
          padding="normal"
          component="th"
        >
          <strong>
            {t('cirgs.reports.auto_readjustment.age_based_table.rows.year', { year, age: currentYear - year })}
          </strong>
        </TableCell>
        <TableCell
          key={`${policy}-${year.toString()}-1`}
          align="center"
          padding="normal"
          component="td"
        >
          <TextField
            fullWidth
            label={t('cirgs.reports.auto_readjustment.age_based_table.columns.readjustment_variable')}
            value={variableRatesPerYear[year] ?? 0}
            onChange={(event) => (setVariableRates({ ...variableRatesPerYear, [year]: Number(event.target.value) }))}
            InputProps={{
              inputComponent: NumberInput,
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        </TableCell>
      </TableRow>
    ))
  );

  const renderLoading = () => (
    isLoading && (
      [...Array(10)].map((i) => (
        <TableRow key={i}>
          { [...Array(6)].map((j) => (
            <TableCell align="center" key={j}>
              <Skeleton animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      )))
  );

  return (
    <Table>
      { renderHeader() }
      { renderLoading() }
      { renderLines() }
    </Table>
  );
};

export default AutoReadjustmentMultipleValuesTable;
