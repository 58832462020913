import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import BrokerageTabs from '../../../components/Brokerages/BrokerageTabs/BrokerageTabs';
import BrokeragesReportTable from '../../../components/Brokerages/BrokeragesReportTable/BrokeragesReportTable';

const BrokeragesReports = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('brokerages.title'), t('brokerages.tabs.reports')]} />

      <BrokerageTabs />

      <BrokeragesReportTable setLoading={setLoading} isLoading={loading} titleLabel={t('brokerages.report.title')} />
    </PageContainer>
  );
};

export default BrokeragesReports;
