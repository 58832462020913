import * as Storage from 'aws-amplify/storage';
import CirgsAbstractFile from '../../entities/CIRGS/CirgsAbstractFile';

const DEFAULT_BUCKET_REGION = 'us-east-1';

const downloadFile = async (
  file: CirgsAbstractFile,
  bucketName?: string,
  bucketRegion: string = DEFAULT_BUCKET_REGION,
) => {
  return await Storage.downloadData({
    path: `public/${file.fileKey}`,
    options: {
      bucket: {
        bucketName: bucketName ?? file.bucketName,
        region: bucketRegion
      }
    }
  }).result
};

export default {
  downloadFile,
};
