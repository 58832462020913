import { faBriefcase, faCoins, faCreditCard, faHandHoldingHeart, faUsers, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Resources } from '../enum/Resources';
import { NavigateFunction } from 'react-router-dom';

export interface MenuItem {
  text: string,
  icon: IconDefinition,
  onClick: (callback: NavigateFunction) => void,
  resource: Resources,
}

export const menus: MenuItem[] = [
  {
    text: 'home.tiles.credit_cards',
    icon: faCreditCard,
    onClick: (callback) => callback('/creditcards'),
    resource: Resources.CREDIT_CARDS,
  },
  {
    text: 'home.tiles.cirgs',
    icon: faHandHoldingHeart,
    onClick: (callback) => callback('/cirgs'),
    resource: Resources.CIRGS,
  },
  {
    text: 'home.tiles.insurers',
    icon: faBriefcase,
    onClick: (callback) => callback('/insurers'),
    resource: Resources.INSURERS,
  },
  {
    text: 'home.tiles.brokerage',
    icon: faCoins,
    onClick: (callback) => callback('/brokerages'),
    resource: Resources.BROKERAGE,
  },
  {
    text: 'home.tiles.users',
    icon: faUsers,
    onClick: (callback) => callback('/users'),
    resource: Resources.USER_MANAGEMENT,
  },
];
