import { FormControlLabel, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CirgsCustomerAttachmentsSection from '../../../components/Cirgs/CirgsCustomerAttachmentsSection';
import CirgsCustomerForm from '../../../components/Cirgs/CirgsCustomerForm';
import CirgsInsuranceTable from '../../../components/Cirgs/CirgsInsuranceTable';
import CirgsCustomer from '../../../entities/CIRGS/CirgsCusrtomer';
import CirgsCustomerService from '../../../services/cirgs/CirgsCustomerService';
import useCirgsCustomerDetailsStyles from './CirgsCustomerDetailsStyles';

interface CustomerListProps {
  setLoading: (value: boolean) => void,
}

type ParamTypes = {
  customerId: string
}

const CirgsCustomerDetails = (props: CustomerListProps) => {
  const { setLoading } = props;
  const [t] = useTranslation();
  const { customerId } = useParams<ParamTypes>();
  const styles = useCirgsCustomerDetailsStyles();
  const [showValidationError, setValidationError] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [customerData, setCustomerData] = useState<CirgsCustomer | undefined>(undefined);
  const [partnerData, setPartnerData] = useState<CirgsCustomer | undefined>(undefined);
  const [hasPartner, setHasPartner] = useState(false);

  const loadCustomer = useCallback(() => {
    setLoading(true);
    if (customerId) {
      CirgsCustomerService.getById(customerId)
        .then((response: CirgsCustomer) => {
          setCustomerData(response);
        })
        .finally(() => setLoading(false));
    }
  }, [customerId, setLoading]);

  const loadPartner = useCallback(() => {
    setLoading(true);
    if (customerData && customerData.id) {
      CirgsCustomerService.getPartner(customerData?.id)
        .then((response: CirgsCustomer | null) => {
          if (response) {
            setPartnerData(response);
          }
          setHasPartner(!!response);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [customerData, setLoading]);

  useEffect(() => {
    loadCustomer();
  }, [loadCustomer]);

  useEffect(() => {
    loadPartner();
  }, [loadPartner]);

  const getErrorMessageLabel = () => {
    if (showSubmitError) {
      return 'cirgs.customers.details.errors.submit';
    }
    return 'cirgs.customers.details.errors.validation';
  };

  const handleCustomerUpdate = (customer: CirgsCustomer | null) => {
    setLoading(true);
    if (customer) {
      CirgsCustomerService.update(customer)
        .then(() => {
          loadCustomer();
          setValidationError(false);
          setShowSubmitError(false);
        })
        .catch(() => setShowSubmitError(true))
        .finally(() => setLoading(false));
    } else {
      setValidationError(true);
      setLoading(false);
    }
  };

  const handlePartnerSubmit = (partner: CirgsCustomer | null) => {
    setLoading(true);
    if (partner && customerData?.id) {
      let promise: Promise<void>;
      if (partner.id) {
        promise = CirgsCustomerService.updatePartner(customerData.id, partner);
      } else {
        promise = CirgsCustomerService.createPartner(customerData.id, partner);
      }

      promise
        .then(() => loadPartner())
        .catch(() => {
          setShowSubmitError(true);
        })
        .finally(() => {
          setShowSubmitError(false);
          setLoading(false);
        });
    } else {
      setValidationError(true);
      setLoading(false);
    }
  };

  return (
    <div id="cirgs-new-customer" className={styles.cirgsCustomerDetailsContainer}>

      { (showValidationError || showSubmitError) && (
      <Alert variant="filled" severity="error">
        {t(getErrorMessageLabel())}
      </Alert>
      )}

      <CirgsCustomerForm
        titleLabel="cirgs.customers.details.customer.title"
        onSubmit={handleCustomerUpdate}
        customerData={customerData}
      />

      <CirgsInsuranceTable
        titleLabel="cirgs.customers.details.insurance.title"
        policyHolderId={customerData?.id}
      />

      <FormControlLabel
        control={(
          <Switch
            checked={hasPartner}
            onChange={(event, checked: boolean) => setHasPartner(checked)}
            name="hasPartner"
            color="primary"
          />
        )}
        label={t('cirgs.customers.details.customer.has_partner')}
        className={styles.cirgsHasPartnerSwitch}
      />

      { hasPartner
        && (
        <>
          <CirgsCustomerForm
            titleLabel="cirgs.customers.details.customer.partner_title"
            onSubmit={handlePartnerSubmit}
            customerData={partnerData}
            isPartnerForm
          />

          { partnerData && (
            <CirgsInsuranceTable
              titleLabel="cirgs.customers.details.insurance.partner_title"
              policyHolderId={partnerData?.id}
              mainPartnerId={customerData?.id}
            />
          )}
        </>
        )}

      <CirgsCustomerAttachmentsSection setLoading={setLoading} customer={customerData} />
    </div>
  );
};

export default CirgsCustomerDetails;
