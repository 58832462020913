import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CirgsReportForm from '../../../components/Cirgs/CirgsReportForm';
import CirgsReport from '../../../entities/CIRGS/CirgsReport';
import CirgsReportService from '../../../services/cirgs/CirgsReportService';
import useCirgsNewCustomerStyles from './CirgsNewReportStyles';
import { useNavigate } from 'react-router-dom';

interface NewReportProps {
  setLoading: (value: boolean) => void,
}

const CirgsNewReport = (props: NewReportProps) => {
  const { setLoading } = props;
  const [t] = useTranslation();
  const styles = useCirgsNewCustomerStyles();
  const [showValidationError, setValidationError] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const navigate = useNavigate();

  const getErrorMessageLabel = () => {
    if (showSubmitError) {
      return 'cirgs.customers.details.errors.submit';
    }
    return 'cirgs.customers.details.errors.validation';
  };

  const handleSubmit = (report: CirgsReport | null, initialFile?: File) => {
    setLoading(true);
    if (report) {
      CirgsReportService.create(report, initialFile)
        .then((newReport) => {
          navigate(`/cirgs/reports/${newReport.id}`);
        })
        .catch(() => {
          setShowSubmitError(true);
        })
        .finally(() => setLoading(false));
    } else {
      setValidationError(true);
      setLoading(false);
    }
  };

  return (
    <div id="cirgs-new-report" className={styles.cirgsNewReportContainer}>

      { (showValidationError || showSubmitError) && (
      <Alert variant="filled" severity="error">
        {t(getErrorMessageLabel())}
      </Alert>
      )}

      <CirgsReportForm onSubmit={handleSubmit} />
    </div>
  );
};

export default CirgsNewReport;
