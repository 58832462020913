import * as Storage from 'aws-amplify/storage';
import { v4 as uuid } from 'uuid';
import CirgsCustomer from '../../entities/CIRGS/CirgsCusrtomer';
import CirgsCustomerAttachment from '../../entities/CIRGS/CirgsCustomerAttachment';
import { FileTypes } from '../../enum/CirgsReportEnums';
import CirgsApiRequestService from '../ApiRequestService/CirgsApiRequestService';

const ATTACHMENTS_BUCKET_NAME = 'cirgs-customer-attachments-bucket';
const ATTACHMENTS_BUCKET_REGION = 'us-east-1';
interface SearchQueryParams {
  search?: string,
  select?: string,
  aggregation?: string,
  policy?: string,
}

const searchCirgsCustomers = async (params: SearchQueryParams = {}): Promise<CirgsCustomer[] | any[]> => {
  const urlParams = Object.entries(params).flatMap(([key, value]) => (value ? [`${key}=${value}`] : [])).join('&');

  const url = `/customers?${urlParams}`;
  const response = await CirgsApiRequestService.request('GET', url);
  const responseBody = await response.json() as any[];
  if (responseBody && Array.isArray(responseBody)) {
    if (params.aggregation) {
      return Promise.resolve(responseBody as any[]);
    }

    return Promise.resolve(responseBody.map((item) => CirgsCustomer.jsonToObject(item)));
  }

  return Promise.resolve([]);
};

const create = async (customer: CirgsCustomer): Promise<CirgsCustomer> => {
  const url = '/customers';
  const body = customer.objectToBody();
  const response = await CirgsApiRequestService.request('POST', url, body);
  const responseBody = await response.json();
  return Promise.resolve(CirgsCustomer.jsonToObject(responseBody));
};

const getById = async (customerId: string): Promise<CirgsCustomer> => {
  const url = `/customers/${customerId}`;
  const response = await CirgsApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(CirgsCustomer.jsonToObject(responseBody));
};

const getPartner = async (customerId: string): Promise<CirgsCustomer | null> => {
  const url = `/customers/${customerId}/partner`;
  const response = await CirgsApiRequestService.request('GET', url);
  if (response.ok) {
    const responseBody = await response.json();
    return Promise.resolve(CirgsCustomer.jsonToObject(responseBody));
  }
  return null;
};

const update = async (customer: CirgsCustomer): Promise<CirgsCustomer> => {
  const url = `/customers/${customer.id}`;
  const body = customer.objectToBody();
  const response = await CirgsApiRequestService.request('PUT', url, body);
  const responseBody = await response.json();
  return Promise.resolve(CirgsCustomer.jsonToObject(responseBody));
};

const createPartner = async (customerId: string, customer: CirgsCustomer): Promise<void> => {
  const url = `/customers/${customerId}/partner`;
  const body = customer.objectToBody();
  const response = await CirgsApiRequestService.request('POST', url, body);
  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject();
};

const updatePartner = async (customerId: string, customer: CirgsCustomer): Promise<void> => {
  const url = `/customers/${customerId}/partner`;
  const body = customer.objectToBody();
  const response = await CirgsApiRequestService.request('PUT', url, body);
  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject();
};

const deletePartner = async (customerId: string): Promise<void> => {
  const url = `/customers/${customerId}/partner`;
  const response = await CirgsApiRequestService.request('DELETE', url);
  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject();
};

const getAttachments = async (customerId: string): Promise<CirgsCustomerAttachment[]> => {
  const url = `/customers/${customerId}/files`;
  const response = await CirgsApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(responseBody.map((item: any) => CirgsCustomerAttachment.jsonToObject(item)));
};

const uploadAttachment = async (file: File, customerId: string): Promise<CirgsCustomerAttachment> => {
  const fileNameParts = file.name.split('.');
  const extension = fileNameParts.length >= 2 ? fileNameParts[fileNameParts.length - 1] : '';
  const fileName = `${uuid()}.${extension}`;
  const fileKey = `attachments/${customerId}/${fileName}`;
  const newReportFile = new CirgsCustomerAttachment(ATTACHMENTS_BUCKET_NAME, fileKey,
    file.name, FileTypes.ATTACHMENT, file.type, customerId);

  Storage.uploadData({
    path: `public/${fileKey}`,
    data: file,
    options: {
      bucket: {
        bucketName: ATTACHMENTS_BUCKET_NAME,
        region: ATTACHMENTS_BUCKET_REGION,
      },
      contentType: file.type,
    }
  });
  return newReportFile;
};

const addAttachment = async (
  customerId: string,
  attachmentObject: CirgsCustomerAttachment,
): Promise<void> => {
  const url = `/customers/${customerId}/files`;
  const body = attachmentObject.objectToBody();
  const response = await CirgsApiRequestService.request('POST', url, body);
  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject(response.body);
};

const addAttachments = async (customerId: string, files: File[]): Promise<void[]> => (
  Promise.all(files.map(async (file) => {
    const attachment = await uploadAttachment(file, customerId);
    addAttachment(customerId, attachment);
  })));

const deleteAttachment = async (attachment: CirgsCustomerAttachment) => {
  const fileId = attachment.id!;
  const { customerId } = attachment;

  const url = `/customers/${customerId}/files/${fileId}`;
  const response = await CirgsApiRequestService.request('DELETE', url);
  if (response.ok) {
    return Promise.resolve();
  }

  return Promise.reject(response.body);
};

export default {
  searchCirgsCustomers,
  create,
  getById,
  update,
  getPartner,
  createPartner,
  updatePartner,
  deletePartner,
  getAttachments,
  addAttachments,
  deleteAttachment,
};
