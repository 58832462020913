import { ButtonBase, Paper } from '@material-ui/core';
import React from 'react';
import { Resources } from '../../enum/Resources';
import ThemeService from '../../services/theme/ThemeService';
import useStandardHomeTileStyles from './StandardHomeTileStyles';

interface StandardHomeTileProps {
  text?: JSX.Element,
  icon?: JSX.Element,
  onClick?: () => void,
  resource: Resources,
}

const StandardHomeTile = (props: StandardHomeTileProps) => {
  const { text, icon, onClick, resource } = props;
  const styles = useStandardHomeTileStyles(ThemeService.currentUserTheme);

  return (
    <ButtonBase
      className={styles.homeTileButtonBase}
      TouchRippleProps={{ className: styles.homeTileRipple }}
      onClick={onClick}
      key={resource}
    >
      <Paper className={styles.homeTilePaper}>
        <div className={styles.homeTileContent}>
          {icon}
          {text}
        </div>
      </Paper>
    </ButtonBase>
  );
};
export type { StandardHomeTileProps };
export default StandardHomeTile;
