import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsCustomerListStyles = makeStyles((theme: Theme) => createStyles({
  customerListContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      width: '80%',
    },
  },
  customerSearch: {
    marginBottom: theme.spacing(4),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
  customerList: {
    width: '100%',
  },
  customerStatusChip: {
    marginLeft: theme.spacing(2),
    backgroundColor: `${theme.palette.grey[400]} !important`,
    color: theme.palette.getContrastText(theme.palette.grey[400]),
  },
  loadingContainer: {
    width: '100%',
    height: '100%',
    minHeight: '112px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  customerListPaper: {
    position: 'relative',
  },
  [theme.breakpoints.down('xs')]: {
    customerListContainer: {
      '& > *': {
        width: '100%',
      },
    },
    customerSearch: {
      marginBottom: theme.spacing(2),
    },
  },
  [theme.breakpoints.up('lg')]: {
    customerListContainer: {
      '& > *': {
        width: '70%',
      },
    },
  },
}));

export default useCirgsCustomerListStyles;
