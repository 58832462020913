import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useFileUploaderStyles = makeStyles((theme: Theme) => createStyles({
  uploadFileArea: {
    width: '100%',
  },
  uploadFileDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(1),
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.dark,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  uploadIcon: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
  },
  uploadText: {
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[800],
  },
  fileIcon: {
    color: theme.palette.primary.light,
    marginRight: theme.spacing(1),
  },
  fileText: {
    color: theme.palette.grey[700],
  },
  fileEntryDiv: {
    margin: theme.spacing(1),
  },
  fileRemoveButton: {
    color: theme.palette.error.dark,
  },
  errorMessageAlert: {
    margin: theme.spacing(2),
  },
}));

export default useFileUploaderStyles;
