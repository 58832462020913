import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../styles/colors';

const useCreditCardDetailsStyles = makeStyles((theme: Theme) => createStyles({
  creditCardDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  hideNoCreditCard: {
    display: 'none !important',
  },
  cardBillingDay: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    '& > p': {
      marginLeft: theme.spacing(2),
    },
  },
  reloadCreditCardDiv: {
    display: 'flex',
    flexFlow: 'row-reverse',
  },
  reloadCreditCardIcon: {
    color: colors.primary,
  },
  generateCodeSeparator: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > span': {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    '& > hr': {
      width: '100%',
      height: '1px',
      border: 'none',
      backgroundColor: colors.darkGray,
    },
  },
  showHideDetailsButton: {
    marginTop: theme.spacing(2),
  },
  generateCodeButton: {
    maxWidth: '390px',
    marginTop: theme.spacing(2),
  },
  generatedCodeText: {
    fontWeight: 'bolder',
    fontSize: '2rem',
    letterSpacing: '0.5rem',
  },
  generatedCodeDisplay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
  },
  whatsAppIcon: {
    color: '#128C7E',
  },
  emailIcon: {
    color: colors.primary,
  },
  copyIcon: {
    color: colors.darkGray,
  },
}));

export default useCreditCardDetailsStyles;
