import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, IconButton, ThemeProvider, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import ThemeService from '../../services/theme/ThemeService';
import useHeaderStyles from './HeaderStyles';
import UserAvatar from './UserAvatar';

interface PropTypes{
  onToggleMenu: () => void;
  showMenuToggle: boolean
}

const Header = (props: PropTypes) => {
  const { onToggleMenu, showMenuToggle = true } = props;
  const [t] = useTranslation();
  const styles = useHeaderStyles(ThemeService.currentUserTheme());
  const currentUser = useSelector((state: RootState) => state.auth.user);

  return (
    <ThemeProvider theme={ThemeService.currentHeaderTheme}>
      <AppBar
        position="fixed"
        className={styles.appBar}
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <Toolbar>
          {
            showMenuToggle
            && (
              <IconButton onClick={onToggleMenu} className={styles.menuButton}>
                <FontAwesomeIcon
                  icon={faBars}
                />
              </IconButton>
            )
        }
          <Typography
            className={styles.title}
            variant="h5"
            noWrap
          >
            {t('app_title')}
          </Typography>

          {
            currentUser
            && (
              <UserAvatar user={currentUser} />
            )
          }
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
