import { faCalendarDay, faCalendarWeek, faCreditCard, faEye, faGlasses, faKey, faUserPlus, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuditEvent from '../../../../entities/Audit/AuditEvent';
import AuditEventTypes from '../../../../entities/Audit/AuditEventTypes';
import Customer from '../../../../entities/Customer/Customer';
import AuditService from '../../../../services/Audit/AuditService';
import useAuditStyles from './CustomerAuditStyles';

interface PropTypes {
  customer: Customer,
  setLoading: Dispatch<SetStateAction<boolean>>
}

const CustomerAudit = (props: PropTypes) => {
  const [t] = useTranslation();
  const { customer, setLoading } = props;
  const styles = useAuditStyles();
  const [events, setEvents] = useState<AuditEvent[]>([]);

  const getEvents = useMemo(() => AuditService.getCustomerAudit(customer.id!),
    [customer]);

  useEffect(() => {
    setLoading(true);
    if (customer) {
      getEvents
        .then((response: AuditEvent[]) => {
          setEvents(response);
        })
        .catch(() => setEvents([]))
        .finally(() => setLoading(false));
    }
  }, [customer, setLoading, getEvents]);

  const getEventIcon = (event: AuditEvent) => {
    switch (event.eventType) {
      case AuditEventTypes.CREDIT_CARD_ADDED:
        return faCreditCard;
      case AuditEventTypes.CREDIT_CARD_DETAILS_READ:
        return faGlasses;
      case AuditEventTypes.CREDIT_CARD_PUBLIC_INFO_READ:
        return faEye;
      case AuditEventTypes.CUSTOMER_CREATE:
        return faUserPlus;
      case AuditEventTypes.CUSTOMER_PASSCODE_GENERATED:
        return faKey;
      default:
        return faCalendarDay;
    }
  };

  return (
    <Grid id="credit_card_info" container alignItems="center" direction="column">
      <Grid item xs={12} className={styles.timelineDiv}>
        <Typography component="h3" variant="h5">
          {t('audit.title')}
        </Typography>
        <Timeline align="alternate">
          {
            events.map((event) => (
              <TimelineItem key={event.timestamp.toString()}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {}
                    { event.timestamp ? t('datetime', { datetime: event.timestamp }) : ''}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" className={styles.timelineDot}>
                    <FontAwesomeIcon icon={getEventIcon(event)} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={styles.eventPaper}>
                    <div className={styles.eventEntry}>
                      <FontAwesomeIcon icon={faCalendarWeek} size="lg" />
                      <Typography variant="body1" component="h5">
                        {t(`audit.events.${event.eventType}`)}
                      </Typography>
                    </div>
                    <div className={styles.eventEntry}>
                      <FontAwesomeIcon icon={faUserTie} size="lg" />
                      <Typography variant="body2">{event.user}</Typography>
                    </div>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))
          }
        </Timeline>
      </Grid>
    </Grid>
  );
};

export default CustomerAudit;
