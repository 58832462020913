import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsCustomerDetailsStyles = makeStyles((theme: Theme) => createStyles({
  cirgsCustomerDetailsContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      width: '80%',
    },
  },
  cirgsHasPartnerSwitch: {
    marginBottom: theme.spacing(3),
  },
  [theme.breakpoints.down('xs')]: {
    cirgsNewCustomerContainer: {
      '& > *': {
        width: '100%',
      },
    },
  },
  [theme.breakpoints.up('lg')]: {
    cirgsNewCustomerContainer: {
      '& > *': {
        width: '70%',
      },
    },
  },
}));

export default useCirgsCustomerDetailsStyles;
