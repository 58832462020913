import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { Resources } from '../../enum/Resources';
import { AuthActionTypes } from '../../redux/Auth/types';
import { RootState } from '../../redux/reducers';
import LoadingPage from '../LoadingPage';

export interface PrivateRouteProps {
  authenticated?: boolean,
  component?: any,
  resource?: Resources,
  [key: string]: any
}

const PrivateRoute = ({
  resource,
}: PrivateRouteProps) => {
  const authState = useSelector((state: RootState) => state.auth.status);
  const currentUser = useSelector((state: RootState) => state.auth.user);

  if (authState === AuthActionTypes.LOGGED_IN) {
    if (!resource) {
      return <Outlet />;
    } else if (currentUser?.permissions[resource]) {
      return <Outlet />;
    }
    return <div>Forbidden</div>;
  } if (authState === AuthActionTypes.AUTHENTICATING) {
    return <LoadingPage />;
  }

  return <Navigate to="/signin" />;
};

export default PrivateRoute;
