import moment, { Moment } from 'moment';
import { FileTypes } from '../../enum/CirgsReportEnums';
import CirgsAbstractFile from './CirgsAbstractFile';

class CirgsCustomerAttachment extends CirgsAbstractFile {
  customerId?: string;

  constructor(bucketName: string, fileKey: string, fileName: string,
    fileType: FileTypes, fileApplicationType: string, customerId?: string, id?: string,
    createdAt?: Moment, updatedAt?: Moment) {
    super(bucketName, fileKey, fileName, fileType, fileApplicationType, id, createdAt, updatedAt);
    this.customerId = customerId;
  }

  public objectToBody() {
    return {
      ...super.objectToBody(),
      customer_id: this.customerId,
    };
  }

  public static jsonToObject(object: any): CirgsCustomerAttachment {
    return new CirgsCustomerAttachment(
      object.bucket_name,
      object.file_key,
      object.file_name,
      object.file_type as FileTypes,
      object.file_application_type,
      object.customer_id,
      object.id,
      moment(object.created_at),
      moment(object.updated_at),
    );
  }
}

export default CirgsCustomerAttachment;
