import { instanceToPlain, plainToInstance } from 'class-transformer';
import Brokerage from '../../entities/Brokerage/Brokerage';
import BrokerageFilter from '../../entities/Brokerage/BrokerageFilter';
import PaginatedResultSet from '../../entities/util/PaginatedResultSet';
import InsurersApiRequestService from '../ApiRequestService/InsurersApiRequestService';
import BrokerageReport from '../../entities/Brokerage/BrokerageReport';

const findBrokerages = async (filters: BrokerageFilter = {}, pageSize = 10, page = 0):
Promise<PaginatedResultSet<Brokerage>> => {
  let url = `/brokerages?page=${page}&size=${pageSize}`;

  Object.entries(filters).filter(([,value]) => value !== undefined)
    .forEach(([param, value]) => { url = `${url}&${param}=${value}`; });

  const response = await InsurersApiRequestService.request('GET', url);
  const responseBody = await response.json();
  const paginated = plainToInstance(PaginatedResultSet, responseBody) as PaginatedResultSet<Brokerage>;
  paginated.values = paginated.values.map((object: any) => plainToInstance(Brokerage, object));
  return Promise.resolve(paginated);
};

const getById = async (id: string): Promise<Brokerage> => {
  const url = `/brokerages/${id}`;

  const response = await InsurersApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(plainToInstance(Brokerage, responseBody));
};

const create = async (newBrokerage: Brokerage): Promise<Brokerage> => {
  const url = '/brokerages';

  const response = await InsurersApiRequestService.request('POST', url, instanceToPlain(newBrokerage));
  const responseBody = await response.json();
  return Promise.resolve(plainToInstance(Brokerage, responseBody));
};

const update = async (id: string, brokerage: Brokerage): Promise<Brokerage> => {
  const url = `/brokerages/${id}`;

  const response = await InsurersApiRequestService.request('PUT', url, instanceToPlain(brokerage));
  const responseBody = await response.json();
  return Promise.resolve(plainToInstance(Brokerage, responseBody));
};

const remove = async (id: string): Promise<void> => {
  const url = `/brokerages/${id}`;

  await InsurersApiRequestService.request('DELETE', url);
  return Promise.resolve();
};

const availableYears = async (): Promise<number[]> => {
  const url = '/brokerages/years';

  const response = await InsurersApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return responseBody as number[];
};

const report = async (year: number, month?: number): Promise<BrokerageReport> => {
  let url = `/brokerages/reports?year=${year}`;
  if (month) {
    url = `${url}&month=${month}`;
  }

  const response = await InsurersApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(plainToInstance(BrokerageReport, responseBody));
};

export default { findBrokerages, create, getById, update, availableYears, remove, report };
