import moment, { Moment } from 'moment';
import { v4 as uuid } from 'uuid';

class CirgsInsurance {
  id: string;

  policyName: string;

  amount: number;

  payAmount: number;

  dateJoined?: Moment;

  validityStartDate?: Moment;

  createdAt?: Date;

  updatedAt?: Date;

  constructor(policyName: string, amount: number, payAmount: number, dateJoined: Moment, validityStartDate: Moment,
    id: string = uuid(), createdAt?: Date, updatedAt?: Date) {
    this.id = id;
    this.policyName = policyName;
    this.amount = amount;
    this.payAmount = payAmount;
    this.dateJoined = dateJoined;
    this.validityStartDate = validityStartDate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  public objectToBody() {
    return {
      id: this.id,
      policy_name: this.policyName,
      amount: this.amount,
      pay_amount: this.payAmount,
      date_joined: this.dateJoined,
      validity_start_date: this.validityStartDate,
    };
  }

  public static jsonToObject(object: any): CirgsInsurance {
    return new CirgsInsurance(
      object.policy_name,
      object.amount,
      object.pay_amount,
      moment(object.date_joined, 'YYYY-MM-DD'),
      moment(object.validity_start_date, 'YYYY-MM-DD'),
      object.id,
      object.createdAt,
      object.updatedAt,
    );
  }

  public static getDefault() {
    return CirgsInsurance.jsonToObject({
      policy_name: '',
      date_joined: moment().format('YYYY-MM-DD'),
      validity_start_date: moment().format('YYYY-MM-DD'),
    });
  }
}

export default CirgsInsurance;
