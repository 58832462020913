import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import InsurerForm from '../../../components/Insurers/InsurerForm/InsurerForm';
import Insurer from '../../../entities/Insurers/Insurer';
import InsurerService from '../../../services/insurers/InsurerService';

type ParamTypes = {
  id: string
}

const InsurerDetails = () => {
  const [t] = useTranslation();
  const { id } = useParams<ParamTypes>();
  const [loading, setLoading] = useState(false);
  const [showValidationError, setValidationError] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [currentInsurer, setCurrentInsurer] = useState<Insurer|undefined>(undefined);
  const navigate = useNavigate();

  const loadInsurer = useCallback(() => {
    setLoading(true);
    if (id) {
      InsurerService.getById(id)
        .then((response: Insurer) => {
          setCurrentInsurer(response);
        })
        .finally(() => setLoading(false));
    }
  }, [id, setLoading]);

  useEffect(() => {
    loadInsurer();
  }, [loadInsurer]);

  const getErrorMessageLabel = () => {
    if (showSubmitError) {
      return 'insurers.errors.submit';
    }
    return 'insurers.errors.validation';
  };

  const handleSubmit = (insurer: Insurer | null) => {
    setLoading(true);
    if (insurer) {
      InsurerService.update(id, insurer)
        .then((newInsurer) => {
          navigate(`/insurers/${newInsurer.id}`);
        })
        .catch(() => {
          setShowSubmitError(true);
        })
        .finally(() => setLoading(false));
    } else {
      setValidationError(true);
      setLoading(false);
    }
  };

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[
        t('insurers.title'),
        currentInsurer?.name?.split(' ')?.[0] ?? t('insurers.breadcrumb.details'),
      ]}
      />

      { (showValidationError || showSubmitError) && (
        <Alert variant="filled" severity="error">
          {t(getErrorMessageLabel())}
        </Alert>
      )}

      <InsurerForm
        titleLabel="insurers.form.title_edit"
        onSubmit={handleSubmit}
        insurerData={currentInsurer}
      />
    </PageContainer>
  );
};

export default InsurerDetails;
