import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import PageBreadcrumb from '../../components/PageBreadcrumb';
import PageContainer from '../../components/PageContainer';
import useUsersStyles from './UsersStyles';
import UserDetailsForm from '../../components/Users/UserDetailsForm';
import User from '../../entities/User/User';
import UsersService from '../../services/user/UsersService';
import { useNavigate } from 'react-router-dom';

const UsersNew = () => {
  const [t] = useTranslation();
  const styles = useUsersStyles();
  const [loading, setLoading] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const navigate = useNavigate();

  const onSave = (user: User | null) => {
    if (!user) return;
    setLoading(true);
    UsersService.create(user)
      .then((userResponse: User) => {
        if (userResponse.id) {
          navigate(`/users/${userResponse.id}`);
        }
        setSubmitFailed(false);
      })
      .catch(() => setSubmitFailed(true))
      .finally(() => setLoading(false));
  };

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('users.title')]} />
      <div id="users-new" className={styles.usersContainer}>
        <UserDetailsForm onSubmit={onSave} />

        { submitFailed && <Alert variant="filled" severity="error">{t('users.new.error')}</Alert> }
      </div>
    </PageContainer>
  );
};

export default UsersNew;
