import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useSignInStyles from './SignInStyles';
import AuthActions from '../../redux/Auth/actions';
import UserSetNewPassword from '../../components/Users/UserSetNewPassword';

const SignInSetNewPassword = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const signInStyles = useSignInStyles();

  const submitResetPassword = (passwordValue: string) => {
    dispatch(AuthActions.solvePasswordChallenge(passwordValue));
  };

  return (
    <Grid container className={signInStyles.signInContentGrid}>
      <Grid xs={12} className={signInStyles.changePasswordTitle}>
        <Typography variant="h6" component="h2">
          {t('signin.form.mandatory_password_change')}
        </Typography>
      </Grid>

      <UserSetNewPassword onSubmit={submitResetPassword} />
    </Grid>
  );
};

export default SignInSetNewPassword;
