import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, Typography } from '@material-ui/core';
import React from 'react';
import ThemeService from '../../services/theme/ThemeService';
import usePageBreadcrumbStyles from './PageBreadcrumbStyles';

interface PageBreadcrumbProps {
  items: string[]
}

const PageBreadcrumb = (props: PageBreadcrumbProps) => {
  const { items } = props;
  const styles = usePageBreadcrumbStyles(ThemeService.currentUserTheme);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className={styles.breadcrumb}
      separator={<FontAwesomeIcon icon={faChevronRight} className={styles.breadcrumbItem} />}
    >
      <FontAwesomeIcon icon={faHome} className={styles.breadcrumbItem} />
      {items.map((item) => (
        <Typography
          component="h2"
          variant="button"
          className={styles.breadcrumbItem}
          key={item}
        >
          {item}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export default PageBreadcrumb;
