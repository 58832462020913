 
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Brokerage from '../../../entities/Brokerage/Brokerage';
import Insurer from '../../../entities/Insurers/Insurer';
import BrokerageService from '../../../services/brokerage/BrokerageService';
import InsurerService from '../../../services/insurers/InsurerService';
import useFormsStyles from '../../../styles/forms/FormStyles';
import useTableStyles from '../../../styles/tables/TableStyles';
import BrokerageTableRow from './BrokerageTableRow';
import { useNavigate } from 'react-router-dom';

interface PropTypes {
  titleLabel: string;
  setLoading: (value: boolean) => void,
  isLoading: boolean,
}

const BrokeragesTable = (props: PropTypes) => {
  const { titleLabel, setLoading, isLoading } = props;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [brokerageCount, setBrokerageCount] = useState<number>(0);
  const [brokerages, setBrokerages] = useState<Brokerage[]>([]);
  const [insurers, setInsurers] = useState<Record<string, Insurer>>({});
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [filterInsurerId, setFilterInsurerId] = useState<string>(undefined);
  const [month, setMonth] = useState<number>(undefined);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [selectedMap, setSelectedMap] = useState<Record<string, boolean>>({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const formsStyles = useFormsStyles();
  const tableStyles = useTableStyles();

  const findBrokerages = useCallback(() => {
    setLoading(true);
    BrokerageService.findBrokerages({
      insurerId: filterInsurerId,
      month,
      year,
    }, pageSize, page)
      .then((paginatedResult) => {
        setBrokerages(paginatedResult.values);
        setBrokerageCount(paginatedResult.count);
      })
      .finally(() => setLoading(false));
  }, [pageSize, page, filterInsurerId, month, year]);

  const loadInsurers = useCallback(() => {
    setLoading(true);
    InsurerService.findInsurers(true)
      .then((data) => setInsurers(data.reduce((map, cur) => {
        map[cur.id] = cur;
        return map;
      }, {})))
      .finally(() => setLoading(false));
  }, []);

  const loadAvailableYears = useCallback(() => {
    setLoading(true);
    BrokerageService.availableYears()
      .then((data) => setAvailableYears(data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    findBrokerages();
    loadInsurers();
    loadAvailableYears();
  }, []);

  useEffect(() => {
    findBrokerages();
  }, [findBrokerages]);

  const handleSelect = (index: string, checked: boolean) => {
    if (checked) {
      setSelectedCount(selectedCount + 1);
    } else {
      setSelectedCount(selectedCount - 1);
    }
    setSelectedMap({ ...selectedMap, [index]: checked });
  };

  const insurerHeadCell = { id: 'insurer', numeric: false, label: 'brokerages.form.insurer' };
  const headCells = [
    { id: 'date', numeric: false, label: 'brokerages.form.date' },
    { id: 'description', numeric: false, label: 'brokerages.form.description' },
    { id: 'grossBrokerage', numeric: false, label: 'brokerages.form.gross_brokerage' },
    { id: 'issqn', numeric: false, label: 'brokerages.form.issqn' },
    { id: 'netBrokerage', numeric: false, label: 'brokerages.form.net_brokerage' },
    { id: 'netPremium', numeric: false, label: 'brokerages.form.net_premium' },
  ];

  const headerCells = () => {
    if (!filterInsurerId) {
      return [insurerHeadCell, ...headCells];
    }
    return headCells;
  };

  const renderLoadingTable = () => (
    <TableRow>
      { [...Array(6)].map((i: number) => (
        <TableCell key={i} align="center">
          <Skeleton animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  );

  const renderEmptyTableMessage = () => (
    <TableRow>
      <TableCell colSpan={7} align="center">
        {t('brokerages.table.no_data')}
      </TableCell>
    </TableRow>
  );

  const renderTableRows = () => brokerages.map((brokerage) => (
    <BrokerageTableRow
      brokerage={brokerage}
      onSelect={handleSelect}
      selected={selectedMap[brokerage.id]}
      showInsurerColumn={!filterInsurerId}
      insurerName={insurers[brokerage.insurerId]?.name}
      key={brokerage.id}
    />
  ));

  const renderTableBody = () => {
    if (isLoading) {
      return renderLoadingTable();
    } if (brokerages.length === 0) {
      return renderEmptyTableMessage();
    }

    return renderTableRows();
  };

  const handleEditClick = () => {
    if (selectedCount === 1) {
      const [id] = Object.entries(selectedMap).filter(([, value]) => value)[0];
      Object.entries(selectedMap).filter(([, value]) => value);
      navigate(`/brokerages/${id}`);
    }
  };

  const handleDeleteClick = () => {
    if (selectedCount > 0) {
      setLoading(true);
      const promises = Object.entries(selectedMap).filter(([, value]) => value)
        .map(([id]) => BrokerageService.remove(id));
      Promise.all(promises).then(() => {
        findBrokerages();
      });
    }
  };

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" className={tableStyles.tableTitleDiv}>
        <Typography
          variant="h5"
          component="h2"
          className={formsStyles.sectionTitle}
        >
          {t(titleLabel)}
        </Typography>
      </Grid>

      <Paper>
        <Toolbar>
          <Grid container justifyContent="space-around">
            <Grid item xs={3}>
              <TextField
                fullWidth
                select
                label={t('brokerages.form.filters.insurers')}
                value={filterInsurerId}
                onChange={(event) => setFilterInsurerId(event.target.value as string)}
              >
                { [
                  { id: undefined, name: t('brokerages.form.filters.all_insurers') } as Partial<Insurer>,
                  ...Object.values(insurers),
                ].map((insurer) => (
                  <MenuItem key={insurer.id} value={insurer.id}>
                    {insurer.name}
                  </MenuItem>
                )) }
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth
                select
                label={t('brokerages.form.filters.month')}
                value={month}
                onChange={(event) => {
                  const selected = event.target.value;
                  setMonth(selected ? Number(selected) : undefined);
                }}
              >
                { [NaN, ...Array.from({ length: 12 }, (_, i) => i + 1)].map((value) => (
                  <MenuItem key={value} value={value}>
                    {`${!Number.isNaN(value) ? value : t('months.all')} - ${t(`months.${value}`)}`}
                  </MenuItem>
                )) }
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                fullWidth
                select
                label={t('brokerages.form.filters.year')}
                value={year}
                onChange={(event) => {
                  const selected = event.target.value;
                  setYear(Number.isNaN(selected) ? Number(selected) : undefined);
                }}
              >
                { [NaN, ...availableYears].map((value) => (
                  <MenuItem key={value} value={Number.isNaN(value) ? undefined : value}>
                    {`${!Number.isNaN(value) ? value : t('years.all')}`}
                  </MenuItem>
                )) }
              </TextField>
            </Grid>
          </Grid>

          <div className={tableStyles.toolbarGrow} />
          <Tooltip title={t('brokerages.table.toolbar.new')!}>
            <IconButton
              aria-label="new"
              className={tableStyles.toolbarIcon}
              onClick={() => navigate('/brokerages/new')}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>

          {
            selectedCount !== 0 && (
              <>
                <Tooltip title={t('brokerages.table.toolbar.edit')!}>
                  <IconButton
                    aria-label="edit"
                    className={tableStyles.toolbarIcon}
                    disabled={selectedCount !== 1}
                    onClick={handleEditClick}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('brokerages.table.toolbar.delete')!}>
                  <IconButton
                    aria-label="delete"
                    className={tableStyles.toolbarIconRed}
                    onClick={handleDeleteClick}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
        </Toolbar>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />

                {headerCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    align={cell.numeric ? 'right' : 'left'}
                    padding="normal"
                    component="th"
                  >
                    <strong>{t(cell.label)}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              { renderTableBody() }
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={brokerageCount ?? 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setPageSize(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </TableContainer>
      </Paper>
    </div>
  );
};

export default BrokeragesTable;
