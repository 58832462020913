import moment, { Moment } from 'moment';
import { ReportTypes, ReportStatus } from '../../enum/CirgsReportEnums';
import CirgsReportExecutionAttributes from './CirgsReportExecutionAttributes';
import CirgsReportFile from './CirgsReportFile';

class CirgsReport {
  id?: string;

  name: string;

  type: ReportTypes;

  status: ReportStatus;

  d8TotalAmount?: number;

  d8TotalPremiumAmount?: number;

  files: CirgsReportFile[];

  createdAt?: Moment;

  completedAt?: Moment;

  updatedAt?: Moment;

  executionAttributes?: CirgsReportExecutionAttributes;

  constructor(name: string, type: ReportTypes, status: ReportStatus,
    d8TotalAmount?: number, d8TotalPremiumAmount?: number, id?: string,
    createdAt?: Moment, updatedAt?: Moment, completedAt?: Moment,
    files: CirgsReportFile[] = [],
    executionAttributes: CirgsReportExecutionAttributes = {}) {
    this.name = name;
    this.id = id;
    this.type = type;
    this.status = status;
    this.d8TotalAmount = d8TotalAmount;
    this.d8TotalPremiumAmount = d8TotalPremiumAmount;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.completedAt = completedAt;
    this.files = files;
    this.executionAttributes = executionAttributes;
  }

  public objectToBody() {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      d8_total_amount: this.d8TotalAmount,
      d8_total_premium_amount: this.d8TotalPremiumAmount,
      files: this.files ? this.files.map((file) => file.objectToBody()) : [],
      execution_attributes: this.executionAttributes,
    };
  }

  public static jsonToObject(object: any): CirgsReport {
    return new CirgsReport(
      object.name,
      object.type as ReportTypes,
      object.status as ReportStatus,
      object.d8_total_amount,
      object.d8_total_premium_amount,
      object.id,
      moment(object.created_at),
      moment(object.updated_at),
      object.completed_at ? moment(object.updated_at) : undefined,
      object.files ? object.files.map((file: any) => CirgsReportFile.jsonToObject(file)) : [],
      object.execution_attributes,
    );
  }
}

export default CirgsReport;
