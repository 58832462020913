import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsCustomerFormStyles = makeStyles((theme: Theme) => createStyles({
  deleteButton: {
    marginRight: theme.spacing(1),
  },
  statusChip: {
    marginLeft: theme.spacing(2),
  },
}));

export default useCirgsCustomerFormStyles;
