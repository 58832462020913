import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsCustomerAttachmentsSectionStyles = makeStyles((theme: Theme) => createStyles({
  uploadPaper: {
    padding: theme.spacing(1),
  },
  cancelUploadButton: {
    marginRight: theme.spacing(1),
  },
}));

export default useCirgsCustomerAttachmentsSectionStyles;
