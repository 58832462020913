import { v4 as uuid } from 'uuid';
import CirgsInsurance from '../../entities/CIRGS/CirgsInsurance';
import CirgsApiRequestService from '../ApiRequestService/CirgsApiRequestService';

const getByHolderId = async (customerId: string): Promise<CirgsInsurance[]> => {
  const url = `/customers/${customerId}/insurances`;
  const response = await CirgsApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(responseBody.map((object: any) => CirgsInsurance.jsonToObject(object)));
};

const create = async (customerId: string, insurance: CirgsInsurance): Promise<void> => {
  const url = `/customers/${customerId}/insurances`;
  const body = insurance.objectToBody();
  const response = await CirgsApiRequestService.request('POST', url, body);
  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject(await response.json());
};

const update = async (customerId: string, insurance: CirgsInsurance): Promise<void> => {
  const url = `/customers/${customerId}/insurances/${insurance.id}`;
  const body = insurance.objectToBody();
  const response = await CirgsApiRequestService.request('PUT', url, body);
  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject(await response.json());
};

const deleteInsurance = async (customerId: string, insuranceId: string): Promise<void> => {
  const url = `/customers/${customerId}/insurances/${insuranceId}`;
  const response = await CirgsApiRequestService.request('DELETE', url);
  if (response.ok) {
    return Promise.resolve();
  }
  return Promise.reject(await response.json());
};

const copyFromCustomerToPartner = async (mainPartnerId: string, partnerId: string): Promise<void> => {
  const insurances = await getByHolderId(mainPartnerId);
  Promise.all(insurances.map(async (insurance) => {
    const newInsurance = new CirgsInsurance(
      insurance.policyName,
      insurance.amount,
      insurance.payAmount,
      insurance.dateJoined,
      insurance.validityStartDate,
      uuid(),
    );

    return create(partnerId, newInsurance);
  }));
};

export default { getByHolderId, create, update, deleteInsurance, copyFromCustomerToPartner };
