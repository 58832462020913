import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AuthActionTypes, AuthAction, AuthState, AuthStateUser } from './types';
import AuthService from '../../services/user/AuthService';
import UserInfo from '../../entities/User/UserInfo';
import PermissionsService from '../../services/user/PermissionsService';
import { AuthUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

type ThunkResult<T> = ThunkAction<T, AuthState, undefined, AuthAction>;

const signedInUser = (user: AuthStateUser | null, type = AuthActionTypes.LOGGED_IN) => ({
  type,
  user,
});

const changeAuthStatus = (status: AuthActionTypes) => ({
  type: status,
});

const loadCurrentUser = (dispatch: Dispatch<AuthAction>) => {
  dispatch(changeAuthStatus(AuthActionTypes.AUTHENTICATING));

  AuthService.currentUser()
    .then(async (user: AuthUser | null) => {
      if (user) {
        AuthService.getUserInfo(async (info: UserInfo) => {
          const permissions = await PermissionsService.getCurrentUserPermissions();
          const authUser: AuthStateUser = { info, permissions };
          dispatch(signedInUser(authUser));
        });
      } else {
        dispatch(changeAuthStatus(AuthActionTypes.NONE));
      }
    })
    .catch(() => {
      dispatch(changeAuthStatus(AuthActionTypes.NONE));
    });
};

const signIn = ()
: ThunkResult<void> => async (dispatch: Dispatch<AuthAction>) => {
  dispatch(changeAuthStatus(AuthActionTypes.AUTHENTICATING));

  AuthService.signIn()
    .then(() => {
      loadCurrentUser(dispatch);
    })
    .catch(() => dispatch(changeAuthStatus(AuthActionTypes.SIGN_IN_FAILED)));
};

const signInBasic = (email: string, password: string)
: ThunkResult<void> => async (dispatch: Dispatch<AuthAction>) => {
  dispatch(changeAuthStatus(AuthActionTypes.AUTHENTICATING));

  AuthService.signInBasic(email, password)
    .then((userResponse) => {
      if ((userResponse as any)?.challengeName === AuthActionTypes.NEW_PASSWORD_REQUIRED) {
        const user = { cognito: userResponse, info: null, permissions: {} };
        dispatch(signedInUser(user, AuthActionTypes.NEW_PASSWORD_REQUIRED));
      } else if (!(userResponse as any)?.challengeName) {
        AuthService.getUserInfo(async (info: UserInfo) => {
          const permissions = await PermissionsService.getCurrentUserPermissions();
          const authUser: AuthStateUser = { info, permissions };
          dispatch(signedInUser(authUser));
        });
      }
    })
    .catch(() => dispatch(changeAuthStatus(AuthActionTypes.SIGN_IN_FAILED)));
};

const solvePasswordChallenge = (password: string)
: ThunkResult<void> => async (dispatch: Dispatch<AuthAction>) => {
  dispatch(changeAuthStatus(AuthActionTypes.AUTHENTICATING));

  AuthService.solveNewPasswordChallenge(password,
    async () => loadCurrentUser(dispatch),
    async () => dispatch(changeAuthStatus(AuthActionTypes.USER_CHALLENGE_FAILED)));
};

const signOut = (): ThunkResult<void> => async (dispatch) => {
  const navigate = useNavigate();
  await AuthService.signOut();
  dispatch(changeAuthStatus(AuthActionTypes.NONE));
  navigate('/signin');
};

const forgotPassword = (email: string): ThunkResult<void> => async (dispatch) => {
  dispatch(changeAuthStatus(AuthActionTypes.AUTHENTICATING));
  AuthService.forgotPassword(email)
    .then(() => dispatch(changeAuthStatus(AuthActionTypes.START_FORGOT_PASSWORD)))
    .catch(() => dispatch(changeAuthStatus(AuthActionTypes.START_FORGOT_PASSWORD_FAILED)));
};

const changePasswordViaCode = (
  email: string,
  password: string,
  code: string,
): ThunkResult<void> => async (dispatch) => {
  dispatch(changeAuthStatus(AuthActionTypes.AUTHENTICATING));
  AuthService.changeForgotPassword(email, password, code)
    .then(() => dispatch(changeAuthStatus(AuthActionTypes.PASSWORD_RESET_SUCCESSFUL)))
    .catch(() => dispatch(changeAuthStatus(AuthActionTypes.PASSWORD_RESET_FAILED)));
};

export default {
  signIn,
  loadCurrentUser,
  signInBasic,
  solvePasswordChallenge,
  signOut,
  forgotPassword,
  changePasswordViaCode,
};
