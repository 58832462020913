import { faChevronRight, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, IconButton, InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCirgsCustomerListStyles from './CustomerListStyles';
import CirgsCustomerService from '../../../services/cirgs/CirgsCustomerService';
import CirgsCustomerStatusChip from '../../../components/Cirgs/CirgsCustomerStatusChip';
import CirgsCustomer from '../../../entities/CIRGS/CirgsCusrtomer';
import { useNavigate } from 'react-router-dom';

interface CustomerListProps {
  setLoading: (value: boolean) => void,
}

const CirgsCustomerList = (props: CustomerListProps) => {
  const { setLoading } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [searchCustomerValue, setSearchCustomerValue] = useState<string>('');
  const [customersList, setCustomersList] = useState<CirgsCustomer[]>([]);
  const styles = useCirgsCustomerListStyles();

  const searchCustomer = useCallback(() => {
    setLoading(true);
    CirgsCustomerService.searchCirgsCustomers({ search: searchCustomerValue })
      .then((customers) => {
        if (customers) {
          setCustomersList(customers);
        }
      })
      .finally(() => setLoading(false));
  }, [setLoading, searchCustomerValue]);

  useEffect(() => {
    searchCustomer();
  }, [searchCustomer]);

  return (
    <div id="cirgs-customer-list" className={styles.customerListContainer}>
      <TextField
        fullWidth
        className={styles.customerSearch}
        label={t('cirgs.customers.list.search')}
        variant="outlined"
        value={searchCustomerValue}
        onChange={(event) => setSearchCustomerValue(event.target.value)}
        onKeyPress={(e) => { if (e.key === 'Enter') { searchCustomer(); } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={searchCustomer}
              >
                <FontAwesomeIcon icon={faSearch} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <div>
        <Fab
          variant="extended"
          color="secondary"
          className={styles.fab}
          onClick={() => navigate('/cirgs/customers/new')}
        >
          <FontAwesomeIcon
            icon={faPlus}
            size="lg"
            className={styles.fabIcon}
          />
          {t('cirgs.customers.list.add_customer')}
        </Fab>
      </div>

      <div>
        <Paper className={styles.customerListPaper}>
          <List className={styles.customerList}>
            { customersList.map((customer) => {
              const labelId = `checkbox-list-secondary-label-${customer.id}`;
              return (
                <ListItem
                  key={customer.id}
                  button
                  onClick={() => navigate(`/cirgs/customers/${customer.id}`)}
                >
                  <ListItemText id={labelId}>
                    <span>{customer.name}</span>
                    <CirgsCustomerStatusChip customer={customer} size="small" className={styles.customerStatusChip} />
                  </ListItemText>

                  <ListItemSecondaryAction
                    onClick={() => navigate(`/cirgs/customers/${customer.id}`)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </div>
    </div>
  );
};

export default CirgsCustomerList;
