import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import EmptyDropzone from './EmptyDropzone';
import FileListDropzone from './FileListDropzone';
import useFileUploaderStyles from './FileUploaderStyles';

type PropTypes = DropzoneOptions;

const FileUploader = (props: PropTypes) => {
  const { onDrop, ...other } = props;
  const styles = useFileUploaderStyles();
  const [t] = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string|undefined>();
  const handleDrop = (acceptedFiles: File[], rejections: FileRejection[], event: DropEvent) => {
    if (other.maxFiles && rejections.length > other.maxFiles) {
      setErrorMessage(t('file_uploader.too_many_files', { fileNum: other.maxFiles }));
    } else if (other.accept && rejections.length > 0) {
      setErrorMessage(t('file_uploader.invalid_format', { fileTypes: other.accept }));
    } else {
      setFiles([...files, ...acceptedFiles]);
      setErrorMessage(undefined);
      if (onDrop) {
        onDrop(acceptedFiles, rejections, event);
      }
    }
  };
  const { getRootProps, getInputProps, open: openFileSelector } = useDropzone({ ...other, onDrop: handleDrop });

  return (
    <div className={styles.uploadFileArea}>
      <div {...getRootProps()} className={styles.uploadFileDiv}>
        <input {...getInputProps()} />
        {
          files.length === 0
            ? <EmptyDropzone openFileSelector={openFileSelector} />
            : <FileListDropzone files={files} onUpdateFiles={setFiles} />
        }
      </div>
      {
        errorMessage && (
          <Alert variant="filled" severity="error" className={styles.errorMessageAlert}>
            {errorMessage}
          </Alert>
        )
      }
    </div>
  );
};

export default FileUploader;
