import { ReportCustomerStatus } from '../../enum/CirgsReportEnums';

class CirgsReportCustomerInfo {
  reportID: string;

  customerID: string;

  customerName: string;

  status: ReportCustomerStatus;

  errorType?: string;

  errorCurrentValue?: number;

  errorExpectedValue?: number;

  constructor(reportID: string, customerID: string, customerName: string,
    status: ReportCustomerStatus, errorType?: string, errorCurrentValue?: number,
    errorExpectedValue?: number) {
    this.reportID = reportID;
    this.customerID = customerID;
    this.customerName = customerName;
    this.status = status;
    this.errorType = errorType;
    this.errorCurrentValue = errorCurrentValue;
    this.errorExpectedValue = errorExpectedValue;
  }

  public static jsonToObject(object: any): CirgsReportCustomerInfo {
    return new CirgsReportCustomerInfo(
      object.report_id,
      object.customer_id,
      object.customer_name,
      object.status as ReportCustomerStatus,
      object.error_type,
      object.error_current_value,
      object.error_expected_value,
    );
  }
}

export default CirgsReportCustomerInfo;
