import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const drawerWidth = 240;

const useSideMenuStyles = makeStyles((theme: Theme) => createStyles({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: colors.primaryDark,
    color: colors.white,
  },
  drawerContainer: {
    overflow: 'auto',
    '& a': {
      textDecoration: 'none',
      color: colors.white,
    },
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuListIcon: {
    color: colors.white,
  },
}));

export default useSideMenuStyles;
