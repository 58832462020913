 
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { useFormFieldState } from 'form-field-state';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FieldValidators from '../../../../validators/FieldValidators';
import NumberInput from '../../../Form/NumberInput';
import AutoReadjustmentMultipleValuesTable from './AutoReadjustmentMultipleValuesTable';

interface PropTypes {
  policy: string,
  onChangeValue: (inflationPercent: number, increasePercent: Record<number, number>) => void,
}
const AutoReadjustmentMultipleValues = (props: PropTypes) => {
  const { policy, onChangeValue } = props;
  const [t] = useTranslation();
  const [fixedRate, setFixedRate] = useFormFieldState<number>(0, FieldValidators.validateNumber);
  const [variableRates, setVariableRates] = useState<Record<number, number>>({});

  useEffect(() => {
    onChangeValue(fixedRate.value ?? 0, variableRates ?? {});
  }, [fixedRate.value, variableRates]);

  return (
    <Grid xs={12} container direction="column">

      <Grid item>
        <TextField
          fullWidth
          label={t('cirgs.reports.auto_readjustment.readjustment_inflation')}
          value={fixedRate.value}
          onChange={(event) => setFixedRate(Number(event.target.value))}
          error={fixedRate.hasErrors}
          helperText={fixedRate.errorMessage}
          InputProps={{
            inputComponent: NumberInput,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Grid>

      <AutoReadjustmentMultipleValuesTable
        onChangeRates={setVariableRates}
        policy={policy}
      />
    </Grid>
  );
};

export default AutoReadjustmentMultipleValues;
