 
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { useFormFieldState } from 'form-field-state';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FieldValidators from '../../../../validators/FieldValidators';
import NumberInput from '../../../Form/NumberInput';

interface PropTypes {
  onChangeValue: (inflationPercent: number, increasePercent: number) => void,
}

const AutoReadjustmentSingleValue = (props: PropTypes) => {
  const { onChangeValue } = props;
  const [t] = useTranslation();
  const [fixedRate, setFixedRate] = useFormFieldState<number>(0, FieldValidators.validateNumber);
  const [variableRate, setVariableRate] = useFormFieldState<number>(0, FieldValidators.validateNumber);

  useEffect(() => {
    const inflationPercent = fixedRate.value ?? 0;
    const increasePercent = variableRate.value ?? 0;
    onChangeValue(inflationPercent, increasePercent);
  }, [fixedRate.value, variableRate.value]);

  return (
    <Grid xs={12} container alignItems="center" justifyContent="space-between">
      <Grid xs={5} item direction="row">
        <TextField
          fullWidth
          label={t('cirgs.reports.auto_readjustment.readjustment_inflation')}
          InputProps={{
            inputComponent: NumberInput,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          value={fixedRate.value}
          onChange={(event) => setFixedRate(Number(event.target.value))}
          error={fixedRate.hasErrors}
          helperText={fixedRate.errorMessage}
        />
      </Grid>

      <Grid xs={5} item>
        <TextField
          fullWidth
          label={t('cirgs.reports.auto_readjustment.readjustment_fixed')}
          InputProps={{
            inputComponent: NumberInput,
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          value={variableRate.value}
          onChange={(event) => setVariableRate(Number(event.target.value))}
          error={variableRate.hasErrors}
          helperText={variableRate.errorMessage}
        />
      </Grid>
    </Grid>
  );
};

export default AutoReadjustmentSingleValue;
