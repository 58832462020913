import moment, { Moment } from 'moment';
import { CirgsAgency } from '../../enum/CirgsAgency';
import { Gender } from '../../enum/Gender';
import { MaritalStatus } from '../../enum/MaritalStatus';

class CirgsCustomer {
  id?: string;

  internalId?: string;

  name: string;

  birthday: Moment;

  cpf: string;

  gender: Gender;

  agency?: CirgsAgency;

  maritalStatus: MaritalStatus;

  contractNumber: string;

  isPartner: boolean;

  hasPassedAway: boolean;

  canceledDate?: Date;

  suspendedDate?: Date;

  createdAt?: Date;

  updatedAt?: Date;

  constructor(name: string, internalId: string | undefined, birthday: Moment, cpf: string, isPartner: boolean,
    gender: Gender, maritalStatus: MaritalStatus, contractNumber: string, hasPassedAway: boolean, agency?: CirgsAgency,
    id?: string, canceledDate?: Date, suspendedDate?: Date, createdAt?: Date, updatedAt?: Date) {
    this.name = name;
    this.id = id;
    this.internalId = internalId;
    this.birthday = birthday;
    this.cpf = cpf;
    this.gender = gender;
    this.agency = agency;
    this.maritalStatus = maritalStatus;
    this.contractNumber = contractNumber;
    this.isPartner = isPartner;
    this.hasPassedAway = hasPassedAway;
    this.canceledDate = canceledDate;
    this.suspendedDate = suspendedDate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  public objectToBody() {
    return {
      id: this.id,
      name: this.name,
      internal_id: this.internalId,
      birthday: this.birthday,
      cpf: this.cpf,
      is_partner: this.isPartner,
      has_passed_away: this.hasPassedAway,
      gender: this.gender,
      agency: this.agency,
      marital_status: this.maritalStatus,
      contract_number: this.contractNumber,
      canceled_date: this.canceledDate,
      suspended_date: this.suspendedDate,
    };
  }

  public static jsonToObject(object: any): CirgsCustomer {
    return new CirgsCustomer(
      object.name,
      object.internal_id,
      moment(object.birthday, 'YYYY-MM-DD'),
      object.cpf,
      object.is_partner,
      Gender[object.gender as keyof typeof Gender],
      MaritalStatus[object.marital_status as keyof typeof MaritalStatus],
      object.contract_number,
      object.has_passed_away,
      object.agency as CirgsAgency,
      object.id,
      object.canceled_date,
      object.suspended_date,
      object.created_at,
      object.updated_at,
    );
  }
}

export default CirgsCustomer;
