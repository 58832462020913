import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const usePageBreadcrumbStyles = makeStyles((theme: Theme) => createStyles({
  breadcrumb: {
    marginBottom: theme.spacing(1),
  },
  breadcrumbItem: {
    color: theme.palette.primary.dark,
  },
}));

export default usePageBreadcrumbStyles;
