import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Fab } from '@material-ui/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import InsurersList from '../../../components/Insurers/InsurersList/InsurersList';
import useInsurersHomeStyles from './InsurerHomeStyles';
import { useNavigate } from 'react-router-dom';


const InsurersHome = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const styles = useInsurersHomeStyles();
  const navigate = useNavigate();

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('insurers.title')]} />

      <div>
        <Fab
          variant="extended"
          color="secondary"
          className={styles.fab}
          onClick={() => navigate('/insurers/new')}
        >
          <FontAwesomeIcon
            icon={faPlus}
            size="lg"
            className={styles.fabIcon}
          />
          {t('insurers.list.new')}
        </Fab>
      </div>

      <InsurersList setLoading={setLoading} />
    </PageContainer>
  );
};

export default InsurersHome;
