 
import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import BrokerageReportLine from '../../../entities/Brokerage/BrokerageReportLine';

interface PropTypes {
  line: BrokerageReportLine,
}

const BrokerageReportTableRow = (props: PropTypes) => {
  const { line } = props;
  const [t] = useTranslation();

  const render = () => (
    <>
      <TableCell
        scope="cell"
      >
        { line.insurerName ?? '' }
      </TableCell>
      <TableCell
        scope="cell"
      >
        { line.insurerCnpj
          ? (
            <NumberFormat
              value={line.insurerCnpj}
              isNumericString
              format="##.###.###/####-##"
              displayType="text"
            />
          )
          : '' }
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: Number(line.netPremium) / 100 })}
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: Number(line.grossBrokerage) / 100 })}
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: Number(line.valueIssqn) / 100 })}
      </TableCell>
      <TableCell
        scope="cell"
      >
        {t('currency', { amount: Number(line.netBrokerage) / 100 })}
      </TableCell>
    </>
  );

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={line.insurerCnpj}
    >
      {render()}
    </TableRow>
  );
};

export default BrokerageReportTableRow;
