import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../styles/colors';

const useCirgsReportCustomersListStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    marginTop: theme.spacing(2),
  },
  filterIcon: {
    color: theme.palette.grey[700],
    marginRight: theme.spacing(3),
  },
  chip: {
    marginRight: theme.spacing(3),
  },
  chipIcon: {
    marginLeft: theme.spacing(1),
  },
  chipIconSelected: {
    color: colors.white,
  },
  chipIconUnselected: {
    color: colors.black,
  },
  chipOk: {
    backgroundColor: `${theme.palette.success.dark} !important`,
    color: colors.white,
  },
  chipWarning: {
    backgroundColor: `${theme.palette.warning.dark} !important`,
    color: colors.white,
  },
  chipError: {
    backgroundColor: `${theme.palette.error.dark} !important`,
    color: colors.white,
  },
  customerIconOk: {
    color: theme.palette.success.dark,
  },
  customerIconWarning: {
    color: theme.palette.warning.dark,
  },
  customerIconError: {
    color: theme.palette.error.dark,
  },
  paperDivLine: {
    '& > *': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  fileIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.light,
  },
  dateIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[700],
  },
  dateText: {
    color: theme.palette.grey[700],
  },
}));

export default useCirgsReportCustomersListStyles;
