import { Moment } from 'moment';
import { FileTypes } from '../../enum/CirgsReportEnums';

abstract class CirgsAbstractFile {
  id?: string;

  bucketName: string;

  fileKey: string;

  fileName: string;

  fileApplicationType: string;

  fileType: FileTypes;

  createdAt?: Moment;

  updatedAt?: Moment;

  constructor(bucketName: string, fileKey: string, fileName: string,
    fileType: FileTypes, fileApplicationType: string, id?: string,
    createdAt?: Moment, updatedAt?: Moment) {
    this.id = id;
    this.bucketName = bucketName;
    this.fileKey = fileKey;
    this.fileName = fileName;
    this.fileType = fileType;
    this.fileApplicationType = fileApplicationType;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }

  public objectToBody(): { [key: string]: any } {
    return {
      id: this.id,
      bucket_name: this.bucketName,
      file_key: this.fileKey,
      file_name: this.fileName,
      file_type: this.fileType,
      file_application_type: this.fileApplicationType,
    };
  }
}

export default CirgsAbstractFile;
