import { faCreativeCommonsNc } from '@fortawesome/free-brands-svg-icons';
import { faBan, faCross } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CirgsCustomer from '../../../entities/CIRGS/CirgsCusrtomer';
import useCirgsCustomerStatusChipStyles from './CirgsCustomerStatusChipStyles';

interface PropTypes {
  customer?: CirgsCustomer
  className?: string
  size?: 'small' | 'medium'
}

const CirgsReportIcon = (props: PropTypes) => {
  const { customer, className, size = 'medium' } = props;
  const [t] = useTranslation();
  const styles = useCirgsCustomerStatusChipStyles();

  let label: string | null = null;
  let icon;
  if (customer?.canceledDate) {
    label = 'cirgs.customers.status.canceled';
    icon = faBan;
  } else if (customer?.suspendedDate) {
    label = 'cirgs.customers.status.suspended';
    icon = faCreativeCommonsNc;
  } else if (customer?.hasPassedAway) {
    label = 'cirgs.customers.status.passed_away';
    icon = faCross;
  }

  if (label && icon) {
    return (
      <Chip
        size={size}
        label={t(label)}
        className={clsx(styles.statusChip, className)}
        icon={<FontAwesomeIcon icon={icon} size="lg" className={styles.statusChipIcon} />}
      />
    );
  }
  return null;
};

export default CirgsReportIcon;
