import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCirgsReportFormStyles from '../CirgsReportFormStyles';
import AutoReadjustmentMultipleValues from './AutoReadjustmentMultipleValues';
import AutoReadjustmentSingleValue from './AutoReadjustmentSingleValue';

interface PropTypes {
  titleLabel: string,
  allowMultipleValues?: boolean,
  policy?: string,
  onChangeValue: (inflationPercent: number, increasePercent: number | Record<number, number>) => void,
}

const AutoReadjustmentRateLine = (props: PropTypes) => {
  const { titleLabel, allowMultipleValues = false, policy, onChangeValue } = props;
  const [t] = useTranslation();
  const styles = useCirgsReportFormStyles();
  const [showMultipleValues, setShowMultipleValues] = useState<boolean>(false);

  const renderFields = () => {
    if (allowMultipleValues && showMultipleValues && policy) {
      return (
        <AutoReadjustmentMultipleValues
          onChangeValue={onChangeValue}
          policy={policy}
        />
      );
    }

    return (
      <AutoReadjustmentSingleValue
        onChangeValue={onChangeValue}
      />
    );
  };

  return (
    <>
      <Grid container direction="column">
        <Typography variant="subtitle1" className={styles.subsectionHeader}>
          {t(titleLabel)}
        </Typography>

        { allowMultipleValues
        && (
        <FormControlLabel
          control={(
            <Checkbox
              checked={showMultipleValues}
              onChange={(event, checked) => setShowMultipleValues(checked && allowMultipleValues)}
              name="age_based_readjustment"
              color="primary"
            />
            )}
          labelPlacement="end"
          label={t('cirgs.reports.auto_readjustment.age_based_readjustment')}
        />
        )}
      </Grid>

      { renderFields() }
    </>
  );
};

export default AutoReadjustmentRateLine;
