import { colors } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useCirgsReportIconStyles = makeStyles(() => createStyles({
  statusNew: {
    backgroundColor: colors.teal[500],
  },
  statusRunning: {
    backgroundColor: colors.blue[700],
  },
  statusComplete: {
    backgroundColor: colors.green[700],
  },
  statusWarning: {
    backgroundColor: colors.orange[700],
  },
  statusFailed: {
    backgroundColor: colors.red[700],
  },
  statusUnknown: {
    backgroundColor: colors.grey[500],
  },
}));

export default useCirgsReportIconStyles;
