import { faFile, faFileAlt, faFileCode, faFileCsv, faFileImage, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import useFileUploaderStyles from './FileUploaderStyles';

interface PropTypes {
  files: File[],
  onUpdateFiles: (files: File[]) => void,
}
const FileListDropzone = (props: PropTypes) => {
  const { files, onUpdateFiles } = props;
  const styles = useFileUploaderStyles();

  const fileIconFromExtension = (file: File) => {
    switch (file.type) {
      case 'text/csv': return faFileCsv;
      case 'text/plain': return faFileAlt;
      case 'application/json': return faFileCode;
      case 'application/pdf': return faFilePdf;
      case file.type.match(/^image\/.*/)?.input:
        return faFileImage;
      default: return faFile;
    }
  };

  const handleFileDelete = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    onUpdateFiles(updatedFiles);
  };

  return (
    <Grid container>
      { files.map((file: File, index: number) => (
        <Grid
          container
          xs={12}
          direction="row"
          justifyContent="space-between"
          className={styles.fileEntryDiv}
          key={file.name}
        >
          <Grid item>
            <Grid container direction="row">
              <FontAwesomeIcon
                icon={fileIconFromExtension(file)}
                size="3x"
                className={styles.fileIcon}
              />
              <Typography
                className={styles.fileText}
              >
                {file.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => handleFileDelete(index)}
              className={styles.fileRemoveButton}
            >
              <FontAwesomeIcon size="sm" icon={faTrash} />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default FileListDropzone;
