import { makeStyles, createStyles } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const useSignInStyles = makeStyles(() => createStyles({
  signInPage: {
    minHeight: '100vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backdropFilter: 'blur(3px)',
  },
  signInBackgroundImage: {
    background: `url("assets/signin_bg${Math.floor(Math.random() * (2 - 1 + 1)) + 1}.webp") center center fixed`,
  },
  modal: {
    height: '55vmin',
    width: '55vmin',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(70,77,89,0.7)',
  },
  modalLoading: {
    width: '100%',
  },
  modelContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4vh 4vw 4vh 4vw',
    '& > *': {
      width: '100%',
      marginBottom: '2vh',
    },
  },
  logo: {
    height: '9rem',
  },
  signInContentGrid: {
    width: '100%',
    '& > *': {
      width: '100%',
      marginBottom: '2vh',
    },
  },
  changePasswordTitle: {
    textAlign: 'center',
    color: colors.white,
  },
}));

export default useSignInStyles;
