import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, InputAdornment, TextField, Button } from '@material-ui/core';
import { useFormFieldState } from 'form-field-state';
import { plainToInstance } from 'class-transformer';
import { Alert } from '@material-ui/lab';
import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import useFormsStyles from '../../../styles/forms/FormStyles';
import NumberInput from '../../../components/Form/NumberInput';
import FieldValidators from '../../../validators/FieldValidators';
import BrokerageSettings from '../../../entities/Brokerage/BrokerageSettings';
import BrokerageSettingsService from '../../../services/brokerage/BrokerageSettingsService';
import BrokerageTabs from '../../../components/Brokerages/BrokerageTabs/BrokerageTabs';

const BrokeragesSettings = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const formStyles = useFormsStyles();
  const [settings, setSettings] = useState<BrokerageSettings>({});
  const [showValidationError, setValidationError] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [showFetchError, setShowFetchError] = useState(false);
  const [issqnField, setIssqn] = useFormFieldState<number | undefined>(Number(settings.issqnPercentage ?? 0) / 100,
    FieldValidators.validateNumber);

  useEffect(() => {
    setLoading(true);
    BrokerageSettingsService.getAll()
      .then((data) => {
        setSettings(data);
      })
      .catch(() => setShowFetchError(true))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setIssqn(Number(settings.issqnPercentage ?? 0) / 100);
   
  }, [settings]);

  const validateBeforeSubmit = () : boolean => {
    const allFields = [issqnField];

    let hasErrors = false;
    allFields.forEach((field) => {
      const error = field.validate();
      hasErrors = hasErrors || error.hasErrors;
      field.refresh();
    });
    return hasErrors;
  };

  const handleSubmit = () => {
    if (validateBeforeSubmit()) {
      setValidationError(true);
      return;
    }

    const changedSettings: BrokerageSettings = {
      issqnPercentage: Math.floor(issqnField.value * 100),
    };

    setLoading(true);
    BrokerageSettingsService.upsert(plainToInstance(BrokerageSettings, changedSettings))
      .catch(() => setShowSubmitError(true))
      .finally(() => setLoading(false));
  };

  const renderSubmitButtons = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleSubmit()}
    >
      {t('cirgs.customers.details.customer.actions.save')}
    </Button>
  );

  const getErrorMessageLabel = () => {
    if (showSubmitError) {
      return 'brokerages.errors.submit';
    } if (showFetchError) {
      return 'brokerages.errors.fetch_settings';
    }
    return 'brokerages.errors.validation';
  };

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('brokerages.title'), t('brokerages.breadcrumb.settings')]} />

      <BrokerageTabs />

      { (showValidationError || showSubmitError || showFetchError) && (
        <Alert variant="filled" severity="error">
          {t(getErrorMessageLabel())}
        </Alert>
      )}

      <Grid container className={formStyles.fieldsContainer}>
        <Grid xs={12}>
          <TextField
            fullWidth
            label={t('brokerages.form.issqn')}
            value={issqnField.value?.toFixed(2)}
            error={issqnField.hasErrors}
            helperText={issqnField.errorMessage}
            onChange={(event) => setIssqn(Number(event.target.value))}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputComponent: NumberInput,
            }}
          />
        </Grid>

        <Grid item lg={12} container direction="row" justifyContent="flex-end">
          { renderSubmitButtons() }
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default BrokeragesSettings;
