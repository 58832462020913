import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faExclamation, faHourglassHalf, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Tooltip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportStatus } from '../../../enum/CirgsReportEnums';
import useCirgsReportIconStyles from './CirgsReportIconStyles';

interface PropTypes {
  status?: ReportStatus
}

const CirgsReportIcon = (props: PropTypes) => {
  const { status } = props;
  const styles = useCirgsReportIconStyles();
  const [t] = useTranslation();

  const selectIcon = () => {
    switch (status) {
      case ReportStatus.NEW:
        return faDotCircle;
      case ReportStatus.RUNNING:
        return faHourglassHalf;
      case ReportStatus.COMPLETED:
        return faCheck;
      case ReportStatus.FAILED:
        return faTimes;
      case ReportStatus.WARNING:
        return faExclamation;
      default:
        return faQuestion;
    }
  };

  const selectIconColor = () => {
    switch (status) {
      case ReportStatus.NEW:
        return styles.statusNew;
      case ReportStatus.RUNNING:
        return styles.statusRunning;
      case ReportStatus.COMPLETED:
        return styles.statusComplete;
      case ReportStatus.FAILED:
        return styles.statusFailed;
      case ReportStatus.WARNING:
        return styles.statusWarning;
      default:
        return styles.statusUnknown;
    }
  };

  const getValueText = () => {
    if (status) {
      return t(`cirgs.reports.report_status.${status.toLocaleLowerCase()}`);
    }
    return t('cirgs.reports.report_status.unknown');
  };

  return (
    <Tooltip title={getValueText()} arrow placement="left">
      <Avatar className={selectIconColor()}>
        <FontAwesomeIcon icon={selectIcon()} />
      </Avatar>
    </Tooltip>
  );
};

export default CirgsReportIcon;
