import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsCustomerStatusChipStyles = makeStyles((theme: Theme) => createStyles({
  statusChip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.getContrastText(theme.palette.warning.main),
  },
  statusChipIcon: {
    color: theme.palette.getContrastText(theme.palette.warning.main),
  },
}));

export default useCirgsCustomerStatusChipStyles;
