import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useFormsStyles = makeStyles((theme: Theme) => createStyles({
  formSection: {
    width: '80%',
    margin: 'auto',
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  fieldsContainer: {
    '& > *': {
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
  },
  [theme.breakpoints.down('md')]: {
    formSection: {
      width: '100%',
      margin: 'auto',
    },
  },
  [theme.breakpoints.down('xs')]: {
    fieldsContainer: {
      '& > *': {
        paddingRight: 0,
      },
    },
  },
}));

export default useFormsStyles;
