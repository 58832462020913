import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsReportDetailsStyles = makeStyles((theme: Theme) => createStyles({
  section: {
    marginBottom: theme.spacing(6),
  },
  sectionTitle: {
    color: theme.palette.primary.main,
  },
  fieldText: {
    marginBottom: theme.spacing(1),
  },
  fieldTextLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export default useCirgsReportDetailsStyles;
