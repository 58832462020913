import { instanceToPlain, plainToInstance } from 'class-transformer';
import Insurer from '../../entities/Insurers/Insurer';
import InsurersApiRequestService from '../ApiRequestService/InsurersApiRequestService';

const findInsurers = async (active: boolean = true, name?: string): Promise<Insurer[]> => {
  let url = `/insurers?active=${active}`;
  if (name) {
    url = `${url}&name=${name}`;
  }
  const response = await InsurersApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(responseBody.map((object: any) => plainToInstance(Insurer, object)));
};

const getById = async (id: string): Promise<Insurer> => {
  const url = `/insurers/${id}`;

  const response = await InsurersApiRequestService.request('GET', url);
  const responseBody = await response.json();
  return Promise.resolve(plainToInstance(Insurer, responseBody));
};

const create = async (newInsurer: Insurer): Promise<Insurer> => {
  const url = '/insurers';

  const response = await InsurersApiRequestService.request('POST', url, instanceToPlain(newInsurer));
  if (response.ok) {
    const responseBody = await response.json();
    return Promise.resolve(plainToInstance(Insurer, responseBody));
  }
  return Promise.reject(new Error(await response.json()));
};

const update = async (id: string, insurer: Insurer): Promise<Insurer> => {
  const url = `/insurers/${id}`;

  const response = await InsurersApiRequestService.request('PUT', url, instanceToPlain(insurer));
  const responseBody = await response.json();
  return Promise.resolve(plainToInstance(Insurer, responseBody));
};

export default { findInsurers, create, getById, update };
