import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from '@material-ui/lab/Alert';
import { Button, TextField } from '@material-ui/core';
import { useFormFieldState } from 'form-field-state';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import Customer from '../../../entities/Customer/Customer';
import CustomerService from '../../../services/customer/CustomerService';
import FieldValidators from '../../../validators/FieldValidators';
import useNewCustomerStyles from './NewCustomerStyles';
import { useNavigate } from 'react-router-dom';

const NewCustomer = () => {
  const [t] = useTranslation();
  const styles = useNewCustomerStyles();
  const [nameField, setNameField] = useFormFieldState<string>('', FieldValidators.validateEmptyField);
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const navigate = useNavigate();

  const formHasErrors = () => nameField.validate().hasErrors;

  const onSave = () => {
    if (!formHasErrors()) {
      setSubmitting(true);
      const customer = new Customer(nameField.value?.trim()!);
      CustomerService.createCustomer(customer)
        .then((newCustomer: Customer) => {
          if (newCustomer.id) {
            navigate(`/customers/${newCustomer.id}`);
          }
          setSubmitFailed(false);
        }).catch(() => setSubmitFailed(true))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <PageContainer loading={submitting}>
      <PageBreadcrumb items={[t('customers.list.title'), t('customers.new.title')]} />
      <div id="new_customer" className={styles.newCustomerContainer}>
        <TextField
          required
          fullWidth
          label={t('customers.new.name_field')}
          onBlur={(e) => setNameField(e.target.value)}
          onChange={(e) => setName(e.target.value)}
          error={nameField.hasErrors}
          helperText={nameField.errorMessage}
          value={name}
        />

        { submitFailed && <Alert variant="filled" severity="error">{t('customers.new.error')}</Alert> }

        <div className={styles.newCustomerActionBar}>
          <Button
            color="secondary"
            variant="contained"
            onClick={onSave}
          >
            <FontAwesomeIcon icon={faSave} size="lg" className={styles.buttonIcon} />
            {t('customers.new.create')}
          </Button>
        </div>
      </div>
    </PageContainer>
  );
};

export default NewCustomer;
