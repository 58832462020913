import { faCreditCard, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CreditCardDetails from '../../../components/CreditCards/CreditCardDetails';
import PageBreadcrumb from '../../../components/PageBreadcrumb';
import PageContainer from '../../../components/PageContainer';
import Customer from '../../../entities/Customer/Customer';
import CustomerService from '../../../services/customer/CustomerService';
import CustomerAudit from '../Tabs/CustomerAudit';
import useCustomerHomeStyles from './CustomerHomeStyles';

type ParamTypes = {
  customerId: string
}

const CustomerHome = () => {
  const [t] = useTranslation();
  const { customerId } = useParams<ParamTypes>();
  const styles = useCustomerHomeStyles();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (customerId) {
      CustomerService.getCustomer(customerId)
        .then((response: Customer) => {
          setCustomer(response);
        })
        .finally(() => setLoading(false));
    }
  }, [customerId]);

  const renderCurrentTab = () => {
    switch (currentTab) {
      case 0:
        if (customer) {
          return <CreditCardDetails customer={customer} setLoading={setLoading} />;
        }
        break;
      case 1:
        if (customer) {
          return <CustomerAudit customer={customer} setLoading={setLoading} />;
        }
        break;
      default:
        return (<span>-1</span>);
    }
    return null;
  };

  return (
    <PageContainer loading={loading}>
      <PageBreadcrumb items={[t('customers.list.title'), (customer?.name || '')]} />
      <Tabs
        value={currentTab}
        onChange={(_, newTab) => setCurrentTab(newTab)}
        indicatorColor="secondary"
        textColor="primary"
        aria-label="scrollable auto tabs example"
        className={styles.tabs}
      >
        <Tab icon={<FontAwesomeIcon icon={faCreditCard} />} label={t('customers.home.tabs.credit_cards')} />
        <Tab icon={<FontAwesomeIcon icon={faInfoCircle} />} label={t('customers.home.tabs.audit')} />
      </Tabs>

      <div id="customer" className={styles.homeCustomerContainer}>
        {renderCurrentTab()}
      </div>
    </PageContainer>
  );
};

export default CustomerHome;
