import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useListsStyles = makeStyles((theme: Theme) => createStyles({
  filterBar: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    '& > *': {
      paddingRight: theme.spacing(2),
    },
  },
  listContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      width: '80%',
    },
  },
  listActionIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  textFieldSearch: {

  },
  list: {
    width: '100%',
  },
  listPaperStyles: {
    position: 'relative',
  },
  [theme.breakpoints.down('xs')]: {
    listContainer: {
      '& > *': {
        width: '100%',
      },
    },
    textFieldSearch: {
      marginBottom: theme.spacing(2),
    },
  },
  [theme.breakpoints.up('lg')]: {
    listContainer: {
      '& > *': {
        width: '70%',
      },
    },
  },
}));

export default useListsStyles;
