import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Paper, Switch, Typography } from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Policy from '../../entities/User/Policy';
import { Resources } from '../../enum/Resources';
import useUsersStyles from '../../Pages/Users/UsersStyles';
import PermissionsService from '../../services/user/PermissionsService';

interface PropTypes {
  userId: string,
}

interface Permissions {
  [key: string]: boolean;
}

const UserPermissionsForm = (props: PropTypes) => {
  const { userId } = props;
  const [loading, setLoading] = useState(false);
  const [submitError, setError] = useState(false);
  const [permissions, setPermissions] = useState<Permissions>({
    [Resources.CREDIT_CARDS]: false,
    [Resources.CIRGS]: false,
    [Resources.BROKERAGE]: false,
    [Resources.INSURERS]: false,
    [Resources.USER_MANAGEMENT]: false,
  });
  const [t] = useTranslation();
  const styles = useUsersStyles();

  const loadPermissions = () => {
    setLoading(true);
    PermissionsService.getUserPermissions(userId)
      .then((response) => {
        const newPermissions = PermissionsService.convertPolicyToPermissions(response);
        setPermissions({
          ...permissions,
          ...newPermissions,
        });
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const onSubmit = () => {
    setLoading(true);
    const policies = Object.entries(permissions)
      .filter(([, value]) => value)
      .map(([resource]) => new Policy('write', resource));

    PermissionsService.updateUserPermissions(userId, policies)
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const handleSwitchChange = (resource: string, value: boolean) => {
    setPermissions({
      ...permissions,
      [resource]: value,
    });
  };

  const renderPermissionItem = (resource: string, value: boolean) => {
    if (loading) {
      return (
        <ListItemText id="loading">
          <Skeleton variant="text" />
        </ListItemText>
      );
    }

    return (
      <>
        <ListItemText id="resource" primary={t(`resources.${resource.toLowerCase()}`)} />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            inputProps={{ 'aria-labelledby': 'resource' }}
            checked={value}
            onChange={(event) => handleSwitchChange(resource, event.target.checked)}
          />
        </ListItemSecondaryAction>

      </>
    );
  };

   
  useEffect(loadPermissions, [userId]);

  return (
    <section className={styles.usersContainer} id="user-form-section">
      <Typography
        variant="h5"
        component="h2"
      >
        {t('users.permissions.title')}
      </Typography>
      <Grid container className={styles.fieldsContainer}>
        <Paper className={styles.usersList}>
          <List className={styles.usersList}>
            <ListSubheader>
              <Grid container justifyContent="space-between">
                <Typography
                  variant="body1"
                  component="span"
                >
                  {t('users.permissions.title')}
                </Typography>
                <IconButton
                  onClick={loadPermissions}
                  color="primary"
                >
                  <FontAwesomeIcon icon={faSync} size="sm" />
                </IconButton>
              </Grid>
            </ListSubheader>
            {
            Object.entries(permissions).map(([resource, value]) => (
              <ListItem key={resource}>
                { renderPermissionItem(resource, value) }
              </ListItem>
            ))
          }
          </List>
        </Paper>
        { submitError && (
        <Alert
          severity="error"
          variant="filled"
          className={styles.usersList}
        >
          {t('users.permissions.save_error')}
        </Alert>
        ) }
        <Grid item lg={12} container direction="row" justifyContent="flex-end" className={styles.actionButtonsContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onSubmit}
          >
            {t('users.permissions.save')}
          </Button>
        </Grid>
      </Grid>
    </section>
  );
};

export default UserPermissionsForm;
