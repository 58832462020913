import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsReportDetailsStyles = makeStyles((theme: Theme) => createStyles({
  reportTitle: {
    marginBottom: theme.spacing(2),
  },
  reportTitleText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  section: {
    marginBottom: theme.spacing(6),
  },
  sectionTitle: {
    color: theme.palette.primary.main,
  },
  fieldText: {
    marginBottom: theme.spacing(1),
  },
  hideSection: {
    display: 'none',
  },
  titleDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cirgsReportDetailsContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      width: '80%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    cirgsReportDetailsContainer: {
      '& > *': {
        width: '100%',
      },
    },
  },
  [theme.breakpoints.up('lg')]: {
    cirgsReportDetailsContainer: {
      '& > *': {
        width: '70%',
      },
    },
  },
}));

export default useCirgsReportDetailsStyles;
