import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFileUploaderStyles from './FileUploaderStyles';

interface PropTypes {
  openFileSelector: () => void
}
const EmptyDropzone = (props: PropTypes) => {
  const { openFileSelector } = props;
  const styles = useFileUploaderStyles();
  const [t] = useTranslation();

  return (
    <>
      <FontAwesomeIcon
        icon={faUpload}
        className={styles.uploadIcon}
        size="4x"
      />
      <Typography
        component="p"
        className={styles.uploadText}
      >
        {t('cirgs.reports.new.drop_d8_file')}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={openFileSelector}
      >
        {t('cirgs.reports.new.browse_file')}
      </Button>
    </>
  );
};

export default EmptyDropzone;
