import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CirgsReport from '../../../entities/CIRGS/CirgsReport';
import useCirgsReportDetailsStyles from './CirgsReportDetailsStyles';

interface PropTypes {
  report?: CirgsReport
}

const CirgsD8ReportDetailsSection = (props: PropTypes) => {
  const { report } = props;
  const styles = useCirgsReportDetailsStyles();
  const [t] = useTranslation();

  return (
    <>
      <Grid item xs={12} md={6}>
        <Typography
          variant="caption"
        >
          {`${t('cirgs.reports.fields.d8_total_premium')}:`}
        </Typography>
        <Typography
          variant="body1"
          className={styles.fieldText}
        >
          {
                report?.d8TotalPremiumAmount
                  ? t('currency', { amount: report?.d8TotalPremiumAmount / 100 })
                  : ''
              }
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          variant="caption"
        >
          {`${t('cirgs.reports.fields.d8_total_amount')}:`}
        </Typography>
        <Typography
          variant="body1"
          className={styles.fieldText}
        >
          {
                report?.d8TotalAmount
                  ? t('currency', { amount: report?.d8TotalAmount / 100 })
                  : ''
              }
        </Typography>
      </Grid>
    </>
  );
};

export default CirgsD8ReportDetailsSection;
