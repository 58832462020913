import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useCirgsNewCustomerStyles = makeStyles((theme: Theme) => createStyles({
  cirgsNewReportContainer: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      width: '80%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    cirgsNewReportContainer: {
      '& > *': {
        width: '100%',
      },
    },
  },
  [theme.breakpoints.up('lg')]: {
    cirgsNewReportContainer: {
      '& > *': {
        width: '70%',
      },
    },
  },
}));

export default useCirgsNewCustomerStyles;
