import React from 'react';
import NumberFormat from 'react-number-format';

const NumberInput = (props: any) => {
  const { inputRef, onChange, decimalScale = 2, ...other } = props;
  return (

    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalSeparator=","
      thousandSeparator="."
      isNumericString
      decimalScale={decimalScale}
      allowLeadingZeros={false}
      fixedDecimalScale
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
};

export default NumberInput;
