import CreditCardPublicInfo from './CreditCardPublicInfo';

class CreditCardDetailsInfo extends CreditCardPublicInfo {
  cardNumber: string;

  cardHolder: string;

  cardExpirationMonth: number;

  cardExpirationYear: number;

  cardBillingDay: number;

  constructor(cardNumber: string, cardFirstTwo: string, cardLastFour: string, cardHolder: string,
    cardExpirationMonth: number, cardExpirationYear:number, cardBillingDay: number, cardAddedDate: Date) {
    super(cardFirstTwo, cardLastFour, cardAddedDate);
    this.cardNumber = cardNumber;
    this.cardHolder = cardHolder;
    this.cardExpirationMonth = cardExpirationMonth;
    this.cardExpirationYear = cardExpirationYear;
    this.cardBillingDay = cardBillingDay;
  }
}

export default CreditCardDetailsInfo;
