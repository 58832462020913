class Policy {
  public action: string;

  public resource: string;

  constructor(action: string, resource: string) {
    this.action = action;
    this.resource = resource;
  }

  public objectToBody() {
    return {
      action: this.action,
      resource: this.resource,
    };
  }

  public static jsonToObject(object: any): Policy {
    return new Policy(
      object.action,
      object.resource,
    );
  }
}

export default Policy;
