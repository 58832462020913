import { Permissions } from '../../entities/User/Permissions';
import Policy from '../../entities/User/Policy';
import { Resources } from '../../enum/Resources';
import AdminApiRequestService from '../ApiRequestService/AdminApiRequestService';

const convertPolicyToPermissions = (policies: Policy[]): Permissions => {
  let newPermissions: Permissions = {};
  policies.forEach((policy: Policy) => {
    newPermissions = {
      ...newPermissions,
      [policy.resource as keyof typeof Resources]: true,
    };
  });
  return newPermissions;
};

const getUserPermissions = async (userId: string): Promise<Policy[]> => {
  const url = `/users/${userId}/permissions`;
  const response = await AdminApiRequestService.request('GET', url);
  const responseBody = await response.json() as any[];
  if (responseBody && Array.isArray(responseBody)) {
    return Promise.resolve(responseBody.map((item) => Policy.jsonToObject(item)));
  }

  return Promise.resolve([]);
};

const updateUserPermissions = async (userId: string, policies: Policy[]): Promise<Response> => {
  const url = `/users/${userId}/permissions`;
  const body = policies.map((policy) => policy.objectToBody());

  return AdminApiRequestService.request('PUT', url, body);
};

const getCurrentUserPermissions = async (): Promise<Permissions> => {
  const url = '/current_user/permissions';
  const response = await AdminApiRequestService.request('GET', url);
  const responseBody = await response.json() as any[];
  if (responseBody && Array.isArray(responseBody)) {
    const policies = responseBody.map((item) => Policy.jsonToObject(item));
    return convertPolicyToPermissions(policies);
  }

  return Promise.resolve({});
};

export default {
  getUserPermissions,
  updateUserPermissions,
  convertPolicyToPermissions,
  getCurrentUserPermissions,
};
