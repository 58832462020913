import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../styles/colors';

const useHeaderStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    color: colors.primary,
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: colors.primary,
    color: colors.white,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  avatarMenu: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: colors.primaryDark,
    minWidth: '15vw',
    color: colors.white,
    '& svg': {
      color: colors.lightGray,
    },
  },
}));

export default useHeaderStyles;
