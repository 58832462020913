import AuthService from '../user/AuthService';

type HttpMethods = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS';

export default abstract class BaseApiRequestService {
  protected abstract readonly serviceURL: string;

  public getServiceUrl() {
    return this.serviceURL;
  }

  public async request(
    method: HttpMethods,
    url: string,
    body = {},
    headers: { [key: string]: string } = {},
  ) : Promise<Response> {
    const defaultHeaders: { [key: string]: string } = {
      'Content-Type': 'application/json',
      'Access-Control-Request-Method': `${method},OPTIONS`,
      'Access-Control-Request-Headers': 'Content-Type,x-requested-with,Access-Control-Allow-Origin,Access-Control-Allow-Headers,Access-Control-Allow-Methods',
    };

    const authToken = (await AuthService.currentJwtToken()).toString();
    if (authToken) {
      defaultHeaders.Authorization = authToken;
    }

    const requestOptions = {
      method,
      headers: { ...defaultHeaders, ...headers },
      body: method === 'GET' ? null : JSON.stringify(body),
    };

    return fetch(`${this.getServiceUrl()}${url}`, requestOptions);
  }
}
