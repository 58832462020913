export enum ReportTypes {
  D8 = 'D8',
  AUTO_READJUSTMENT = 'AUTO_READJUSTMENT',
  SIAP = 'SIAP',
}

export enum ReportStatus {
  NEW = 'NEW',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  WARNING = 'WARNING',
  FAILED = 'FAILED',
}

export enum FileTypes {
  D8 = 'D8',
  POLICY_INVOICE = 'POLICY_INVOICE',
  SIAP_CONSIG = 'SIAP_CONSIG',
  ATTACHMENT = 'ATTACHMENT',
}

export enum ReportCustomerStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum ReportCustomerErrors {
  AMOUNT_MISMATCH = 'AMOUNT_MISMATCH',
  MISSING_CUSTOMER = 'MISSING_CUSTOMER',
  SUSPENDED_FOUND = 'SUSPENDED_FOUND',
  AUTO_INCREASE = 'AUTO_INCREASE',
  AUTO_INCREASE_DIFF = 'AUTO_INCREASE_DIFF',
}
