import Customer from '../../entities/Customer/Customer';
import Passcode from '../../entities/Customer/Passcode';
import ApiRequestService from '../ApiRequestService/AdminApiRequestService';

const convertObjectToBody = (customer: Customer) => ({
  id: customer.id,
  name: customer.name,
});

const convertJsonToObject = (object: any) => ({
  id: object.id,
  name: object.name,
});

const createCustomer = async (customer: Customer): Promise<Customer> => {
  const url = '/customers';
  const body = convertObjectToBody(customer);
  const response = await ApiRequestService.request('POST', url, body);
  const responseBody = await response.json();
  return Promise.resolve(convertJsonToObject(responseBody));
};

const getAllCustomers = async (): Promise<Customer[]> => {
  const url = '/customers';
  const response = await ApiRequestService.request('GET', url);
  const responseBody = await response.json() as any[];
  return Promise.resolve(responseBody.map((item) => convertJsonToObject(item)));
};

const getCustomer = async (customerId: string): Promise<Customer> => {
  const url = `/customers/${customerId}`;
  const response = await ApiRequestService.request('GET', url);
  const responseBody = await response.json() as any;
  return Promise.resolve(convertJsonToObject(responseBody));
};

const generatePasscode = async (customerId: string): Promise<Passcode> => {
  const url = `/customers/${customerId}/passcode`;
  const response = await ApiRequestService.request('PUT', url);
  const responseBody = await response.json() as any;
  const passcode = new Passcode(responseBody.passcode);
  return Promise.resolve(passcode);
};

export default { createCustomer, getAllCustomers, getCustomer, generatePasscode };
