import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useCreditCardStyles from './CreditCardStyles';

interface PropTypes {
  cardNumber: string,
  cardHolder?: string,
  cardExpirationMonth?: number,
  cardExpirationYear?: number,
}

const CreditCard = (props: PropTypes) => {
  const [t] = useTranslation();
  const { cardNumber, cardHolder, cardExpirationMonth, cardExpirationYear } = props;
  const styles = useCreditCardStyles();

  const renderCCLogo = () => {
    const firstTwo = parseInt(cardNumber.substring(0, 2), 10);
    if ((firstTwo >= 51 && firstTwo <= 55) || (firstTwo >= 22 && firstTwo <= 27)) {
      return <img src="/assets/icons/mc-logo.svg" />;
    } if (firstTwo >= 40 && firstTwo <= 49) {
      return <img src="/assets/icons/visa-logo.svg" />;
    }

    return null;
  };

  return (
    <div id="cc" className={styles.card}>
      <div className={styles.cardCompanyRow}>
        <div className={styles.chip}>
          <div className={styles.chipHorizontalLine} />
          <div className={styles.chipVerticalLine} />
        </div>
        { renderCCLogo() }
      </div>

      <div className={styles.cardNumberRow}>
        {cardNumber.match(/.{4}/g)?.map((segment) => (
          <Typography key={segment} component="p" variant="h5" className={styles.ccFont}>{segment}</Typography>
        ))}
      </div>

      <div className={clsx(styles.cardHolderRow, { [styles.hideCardRow]: !cardHolder })}>
        <Typography component="p" variant="h6" className={styles.ccFont}>{cardHolder?.toUpperCase()}</Typography>
      </div>

      <div className={clsx(styles.cardValidRow, { [styles.hideCardRow]: !cardExpirationMonth && !cardExpirationYear })}>
        <Typography component="p" variant="body2">{t('credit_cards.card.valid')}</Typography>
        <Typography component="p" variant="h6" className={styles.ccFont}>
          {`${String(cardExpirationMonth).padStart(2, '0')}/${String(cardExpirationYear).padStart(2, '0')}`}
        </Typography>
      </div>
    </div>
  );
};

export default CreditCard;
