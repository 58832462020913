import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { useFormFieldState } from 'form-field-state';
import { useTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import Insurer from '../../../entities/Insurers/Insurer';
import FieldValidators from '../../../validators/FieldValidators';
import useFormsStyles from '../../../styles/forms/FormStyles';
import Brokerage from '../../../entities/Brokerage/Brokerage';
import InsurerService from '../../../services/insurers/InsurerService';
import DateMaskedInput from '../../Form/DateMaskedInput';
import NumberInput from '../../Form/NumberInput';
import BrokerageSettingsService from '../../../services/brokerage/BrokerageSettingsService';

interface PropTypes {
  onSubmit: (customer: Brokerage | null) => void,
  brokerageData?: Brokerage,
  setLoading: (loading: boolean) => void,
}

const BrokerageForm = (props: PropTypes) => {
  const { onSubmit, brokerageData, setLoading } = props;
  const [t] = useTranslation();
  const formsStyles = useFormsStyles();
  const [insurers, setInsurers] = useState<Record<string, Insurer>>({});
  const [insurerField, setInsurer] = useFormFieldState<string>(brokerageData?.insurerId || '', FieldValidators.validateEmptyField);
  const [dateField, setDate] = useFormFieldState<MaterialUiPickersDate>(moment(brokerageData?.date) || moment());
  const [descriptionField, setDescription] = useFormFieldState<string>(brokerageData?.description || '', FieldValidators.validateEmptyField);
  const [grossBrokerageField, setGrossBrokerage] = useFormFieldState<number | undefined>(
    brokerageData?.grossBrokerage / 100 || undefined, FieldValidators.validateNumber,
  );
  const [issqnField, setIssqn] = useFormFieldState<number | undefined>(brokerageData?.valueIssqn / 100 || undefined,
    FieldValidators.validateNumber);
  const [netPremiumField, setNetPremium] = useFormFieldState<number | undefined>(
    brokerageData?.netPremium / 100 || undefined, FieldValidators.validateNumber,
  );
  const [currentIssqn, setCurrentIssqn] = useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dateToConfirm, setDateToConfirm] = useState<MaterialUiPickersDate>(undefined);

  useEffect(() => {
    if (brokerageData) {
      setInsurer(brokerageData.insurerId);
      setDate(moment(brokerageData.date));
      setDescription(brokerageData.description);
      setGrossBrokerage(brokerageData.grossBrokerage / 100);
      setIssqn(brokerageData.valueIssqn / 100);
      setNetPremium(brokerageData.netPremium / 100);
    }
   
  }, [brokerageData]);

  const loadInsurers = async () => {
    const data = await InsurerService.findInsurers(true);
    setInsurers(data.reduce((map, cur) => {
      map[cur.id] = cur;
      return map;
    }, {}));
  };

  const loadIssqn = async () => {
    BrokerageSettingsService.getOne('issqnPercentage')
      .then((data) => setCurrentIssqn(data.issqnPercentage ? Number(data.issqnPercentage / 100) : undefined));
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([loadInsurers(), loadIssqn()]).finally(() => setLoading(false));
     
  }, []);

  const validateBeforeSubmit = () : boolean => {
    const allFields = [insurerField, dateField, descriptionField, grossBrokerageField,
      issqnField, netPremiumField];

    let hasErrors = false;
    allFields.forEach((field) => {
      const error = field.validate();
      hasErrors = hasErrors || error.hasErrors;
      field.refresh();
    });
    return hasErrors;
  };

  const handleSubmit = (): void => {
    if (validateBeforeSubmit()) {
      onSubmit(null);
      return;
    }

    const changedBrokerage: Brokerage = {
      ...brokerageData,
      insurerId: insurerField.value,
      date: dateField.value.startOf('day').toDate(),
      description: descriptionField.value,
      grossBrokerage: Math.floor(grossBrokerageField.value * 100),
      valueIssqn: Math.floor(issqnField.value * 100),
      netPremium: Math.floor(netPremiumField.value * 100),
    };

    onSubmit(changedBrokerage);
  };

  const renderSubmitButtons = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleSubmit()}
    >
      {t('cirgs.customers.details.customer.actions.save')}
    </Button>
  );

  const onGrossBrokerageChange = (value: number) => {
    setGrossBrokerage(value);
    if (currentIssqn) {
      setIssqn(value * (currentIssqn / 100));
    }
  };

  const onChangeDate = (value: MaterialUiPickersDate) => {
    const currentDate = moment();
    if (value && value.isValid() && (value.year() !== currentDate.year() || currentDate.diff(value, 'months') > 1)) {
      setConfirmDialogOpen(true);
      setDateToConfirm(value);
    } else {
      setDate(value);
    }
  };

  const onConfirmDate = (confirmed: boolean) => {
    setConfirmDialogOpen(false);
    if (confirmed) {
      setDate(dateToConfirm);
      setDateToConfirm(undefined);
    }
  };

  return (
    <section className={formsStyles.formSection} id="brokerage-form-section">
      <Dialog
        open={confirmDialogOpen}
        onClose={() => onConfirmDate(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('brokerages.form.confirm_date.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('brokerages.form.confirm_date.text', { date: dateToConfirm })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onConfirmDate(false)} color="primary">
            {t('brokerages.form.confirm_date.cancel')}
          </Button>
          <Button onClick={() => onConfirmDate(true)} color="primary" autoFocus>
            {t('brokerages.form.confirm_date.agree')}
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container className={formsStyles.fieldsContainer}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            label={t('brokerages.form.filters.insurers')}
            value={insurerField.value}
            onChange={(event) => setInsurer(event.target.value as string)}
          >
            { Object.values(insurers).map((insurer) => (
              <MenuItem key={insurer.id} value={insurer.id}>
                {insurer.name}
              </MenuItem>
            )) }
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            label={t('brokerages.form.description')}
            value={descriptionField.value}
            onChange={(event) => setDescription(event.target.value)}
            error={descriptionField.hasErrors}
            helperText={descriptionField.errorMessage}
          >
            {['brokerage', 'aditional'].map((type) => (
              <MenuItem key={type} value={type}>
                {t(`brokerages.types.${type}`)}
              </MenuItem>
            )) }
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <DateMaskedInput
            fullWidth
            label={t('brokerages.form.date')}
            onChange={onChangeDate}
            value={dateField.value}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('brokerages.form.gross_brokerage')}
            value={grossBrokerageField.value}
            onChange={(event) => onGrossBrokerageChange(Number(event.target.value))}
            error={grossBrokerageField.hasErrors}
            helperText={grossBrokerageField.errorMessage}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$ </InputAdornment>,
              inputComponent: NumberInput,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('brokerages.form.issqn')}
            value={issqnField.value?.toFixed(2)}
            error={issqnField.hasErrors}
            onChange={(event) => setIssqn(Number(event.target.value))}
            helperText={issqnField.errorMessage}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$ </InputAdornment>,
              inputComponent: NumberInput,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('brokerages.form.net_premium')}
            value={netPremiumField.value}
            onChange={(event) => setNetPremium(Number(event.target.value))}
            error={netPremiumField.hasErrors}
            helperText={netPremiumField.errorMessage}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$ </InputAdornment>,
              inputComponent: NumberInput,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12} container direction="row" justifyContent="flex-end">
          { renderSubmitButtons() }
        </Grid>
      </Grid>
    </section>
  );
};

export default BrokerageForm;
