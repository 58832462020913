const colors = {
  lightGray: '#EBEEF0',
  gray: '#CCC',
  darkGray: '#666',
  primary: '#0C60A6',
  primaryDark: '#031B4D',
  primaryLight: '#3DA8FF',
  secondary: '#F2A122',
  secondaryDark: '#A66500',
  white: '#FFFFFF',
  black: '#000000',
  red: '#D32F2F',
  success: '#74D27B',
};

export default colors;
