import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { Amplify } from 'aws-amplify';
import clsx from 'clsx';
import ThemeService from './services/theme/ThemeService';
import './App.css';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import useAppStyles from './AppStyles';
import LoadingPage from './components/LoadingPage';
import AppRouter from './components/AppRouter/AppRouter';
import cognitoConfig from './services/cognito/CognitoConfig';
import AuthActions from './redux/Auth/actions';
import { RootState } from './redux/reducers';
import { AuthActionTypes } from './redux/Auth/types';
import { BrowserRouter } from 'react-router-dom';

Amplify.configure(cognitoConfig);

const App = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);
  const authState = useSelector((state: RootState) => state.auth.status);
  const styles = useAppStyles(ThemeService.currentUserTheme);
  const Router = useMemo(() => <AppRouter />, []);

  useEffect(() => {
    if (authState !== AuthActionTypes.LOGGED_IN || isMobile) {
      setMenuOpen(false);
    } else {
      setMenuOpen(!isMobile);
    }
  }, [authState, isMobile]);

  useEffect(() => {
    AuthActions.loadCurrentUser(dispatch);
  }, [dispatch]);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <ThemeProvider theme={ThemeService.currentUserTheme()}>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <CssBaseline />
          <Header onToggleMenu={handleToggleMenu} showMenuToggle={authState === AuthActionTypes.LOGGED_IN} />
          <SideMenu open={menuOpen} />
          <div
            className={clsx(styles.content, { [styles.contentShift]: menuOpen })}
          >
            <Toolbar />
            {Router}
          </div>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
